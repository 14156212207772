import styled from 'styled-components';

export const H1 = styled.h1`
  font-weight: 600;
  font-family: 'Mono', sans-serif;
  font-size: 3em;
  line-height: 1.25em;
  letter-spacing: 0.1em;
  color: var(--ui-control-color);
  user-select: text;
  margin: 3rem 0;
  @media only screen and (max-width: 700px) {
    font-size: 2.5em;
  }
`;
