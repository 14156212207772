import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Link, ButtonPrimary, HR, A } from '../../components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3em 0 2em;
  gap: 3em;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export type HorizProps = {};

const Btns = styled.div`
  display: flex;
  gap: 1em;

  @media screen and (max-width: 900px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`;

const BwBtn = styled(ButtonPrimary)`
  background: #3d3d3d;
  color: white;
`;

const FlexRow = styled(Container)`
  align-items: flex-start;
`;

export const Horiz: FC<HorizProps> = ({}) => {
  const seedPath = useMemo(
    () => `/seed/${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)}`,
    [],
  );
  const hue = useMemo(() => Math.random() * 360, []);
  return (
    <div>
      <HR style={{ margin: 0 }} />
      <FlexRow>
        <p>
          Minting closed. Find{' '}
          <A href="https://opensea.io/collection/synthopia">
            Synthopia on OpenSea
          </A>
          .
        </p>
        <Btns>
          <div>
            <Link plain href="/gallery">
              <ButtonPrimary baseHue={hue}>Gallery</ButtonPrimary>
            </Link>
          </div>
          <div>
            <Link plain href={seedPath}>
              <BwBtn>Voyager</BwBtn>
            </Link>
          </div>
        </Btns>
      </FlexRow>
    </div>
  );
};
