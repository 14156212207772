import React, { FC } from 'react';
import styled from 'styled-components';
import { repeat } from '../../utils';

export type VertProps = {
  numLines: number;
  topOpacity: number;
  bottomOpacity: number;
};

const Container = styled.div`
  width: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export const Vert: FC<VertProps> = ({
  numLines,
  topOpacity,
  bottomOpacity,
}) => {
  return (
    <Container>
      {repeat(numLines, i => (
        <div
          key={i}
          style={{
            borderBottom: `solid 1px rgba(255,255,255,${
              topOpacity + (i / numLines) * (bottomOpacity - topOpacity)
            })`,
          }}
        />
      ))}
    </Container>
  );
};
