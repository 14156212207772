import {
  Group,
  BoxGeometry,
  Mesh,
  MeshStandardMaterial,
  Color,
  AnimationMixer,
} from 'three';
import { animate } from './animate';
import { Entity } from './types';

export const buildFreighter = (
  color: Color,
  dist: number,
  depth: number,
  time: number,
): Entity => {
  const numFreighters = 70;

  const group = new Group();

  const animations: AnimationMixer[] = [];
  const yMax = Math.tan(Math.PI / 18) * (dist + depth);
  const xMax = yMax * 1.25;

  for (let i = 0; i < numFreighters; i++) {
    const scale = 1 + Math.random() * 2;
    const t = (i / numFreighters) * time;
    const x = (Math.random() * 2 - 1) * xMax;
    const y = Math.random() * (yMax + 8.5) - 8.5;

    const material = new MeshStandardMaterial({
      color,
      dithering: true,
      transparent: true,
      opacity: 0,
    });

    const g1 = new Group();
    const geometry = new BoxGeometry(3 * scale, 2 * scale, 7 * scale);
    const b1 = new Mesh(geometry, material);
    b1.castShadow = true;
    b1.receiveShadow = true;
    g1.position.set(x, y, 0);
    g1.add(b1);
    group.add(g1);

    animations.push(
      animate(
        b1,
        [
          [
            '.position',
            [0, 30],
            [
              0,
              0,
              ((dist + depth) * (i - 1)) / numFreighters,
              0,
              0,
              ((dist + depth) * (i + numFreighters)) / numFreighters,
            ],
          ],
          ['.material.opacity', [0, 1, 30, 33], [0, 1, 1, 0]],
        ],
        t,
        true,
      ),
    );
  }

  return {
    group,
    children: {},
    dispose() {},
    animations,
  };
};
