import React, { FC, useRef, useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { randomGraph } from '../../randomGraph';
import {
  FullscreenButton,
  Illustration,
  Layout,
  Link,
  PlayButton,
  P,
} from '../../components';
import { GraphThumbnail } from './GraphThumbnail';
import { CurrentBeat } from './CurrentBeat';
import { AudioGraph } from '../../audioGraph';
import { Stats } from './Stats';
import { Positions } from './Positions';
import { useAPIData } from '../../hooks';
import { Metadata } from '../../types';
import { translateSeed } from '../../utils';

export type PlayerProps = {
  path: string[];
};

const Main = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 3fr 0.5fr;
  position: relative;
  gap: 3em;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 3fr 1fr;
    align-items: stretch;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    align-items: stretch;
  }
`;

const Unrevealed = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  position: relative;
  gap: 3em;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    align-items: stretch;
  }
`;

const Controls = styled.div`
  height: 2.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const PlayControls = styled.div`
  display: flex;
  gap: 1em;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(256px + 2em);
  gap: 2em;
`;

const LeftInfo = styled(Info)`
  & a,
  & a:visited {
    color: white;
  }
  @media screen and (max-width: 1000px) {
    order: 2;
  }
`;

const RightInfo = styled(Info)`
  @media screen and (max-width: 600px) {
    // order: 1;
    // flex-direction: row;
    // justify-content: space-between;
    // max-width: none;
    display: none;
  }
`;

const ThumbnailContainer = styled.div`
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RelativeContainer = styled.div`
  position: relative;
  aspect-ratio: 1;
  flex: 1;
  padding-bottom: 2.5em;
  box-sizing: content-box;
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Player: FC<PlayerProps> = ({ path: [, tokenIdString] }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [ready, setReady] = useState(false);

  const tokenId = parseInt(tokenIdString);

  const [loading, error, data] = useAPIData<Metadata>(`metadata/${tokenId}`, [
    tokenId,
  ]);

  const seed = data && data.seed ? translateSeed(data.seed) : null;
  const graph = useMemo(() => (seed ? randomGraph(seed, tokenId) : null), [
    seed,
    tokenId,
  ]);
  const audioGraph = useMemo(
    () => (graph ? new AudioGraph(graph, undefined) : null),
    [graph],
  );

  useEffect(() => {
    if (fullscreen) {
      const exitHandler = () => {
        if (document.fullscreenElement === null) setFullscreen(false);
      };
      document.addEventListener('fullscreenchange', exitHandler);
      return () => {
        document.removeEventListener('fullscreenchange', exitHandler);
      };
    }
    return undefined;
  }, [fullscreen]);

  const context = audioGraph?.context as AudioContext;
  const play = () => {
    context?.resume();
    setPlaying(true);
  };
  const pause = () => {
    context?.suspend();
    setPlaying(false);
  };

  // play / pause when spacebar is pressed
  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        e.stopPropagation();
        e.preventDefault();
        playing ? pause() : play();
      }
    };
    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  }, []);

  // track when graph is ready, clean up when page is left
  useEffect(() => {
    audioGraph?.ready.then(() => {
      if (graph) {
        audioGraph.update(graph);
      }
      setReady(true);
    });
    return () => {
      audioGraph?.close();
    };
  }, [audioGraph]);

  if (loading || error || !data || !ready) {
    return (
      <Layout
        title="..."
        breadcrumbs={[
          ['GALLERY', '/gallery'],
          ['', null],
        ]}
        loading={loading || !ready}
        error={!!error}
      />
    );
  } else if (data && (data.seed as any) === '') {
    return (
      <Layout
        title="Unrevealed"
        breadcrumbs={[
          ['GALLERY', '/gallery'],
          [`#${tokenId} Unrevealed`, `/gallery/${tokenId}`],
        ]}
        loading={false}
        error={!!error}
      >
        <Unrevealed>
          <Center ref={ref}>
            <RelativeContainer style={{ width: fullscreen ? 'auto' : '100%' }}>
              <AbsoluteContainer>
                <img
                  src="/pre-reveal.png"
                  style={{ width: '100%', aspectRatio: '1' }}
                />
              </AbsoluteContainer>
              <Controls>
                <PlayControls>
                  <div>#{tokenId} Unrevealed</div>
                </PlayControls>
              </Controls>
            </RelativeContainer>
          </Center>
          <LeftInfo>
            <P>Synthopia tokens will be revealed on 12/14</P>
            <Link
              href={`https://opensea.io/assets/0x709ea840f66fb32a762908afae673610e169bf5a/${tokenId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              OpenSea
            </Link>
          </LeftInfo>
        </Unrevealed>
      </Layout>
    );
  }

  return (
    <Layout
      title={graph?.name ?? ''}
      breadcrumbs={[
        ['GALLERY', '/gallery'],
        [`#${tokenId} ${graph?.name}`, `/gallery/${tokenId}`],
      ]}
      loading={loading || !ready}
      error={!!error}
    >
      {ready && audioGraph && graph && (
        <>
          <Main>
            <LeftInfo>
              <Positions
                audioGraph={audioGraph}
                playing={playing}
                baseHue={graph.baseHue}
              />
              <Stats traits={graph.traits} baseHue={graph.baseHue} />
              <Link href={`/gallery/${tokenId}/download`}>Download</Link>
              <Link href={data.video} target="_blank" rel="noopener noreferrer">
                Download video
              </Link>
              <Link href={data.image} target="_blank" rel="noopener noreferrer">
                Download image
              </Link>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="twitter-share-button"
                href={`https://twitter.com/intent/tweet?text=Check out ${graph.name} from the Synthopia NFT collection by @gramatik, @luxasmusix, and @audioglyphs.&url=${window.location.href}`}
                data-size="large"
              >
                Tweet
              </a>
              <Link href={`/gallery/${tokenId}/embed`}>Embed</Link>
              <Link
                href={`https://opensea.io/assets/0x709ea840f66fb32a762908afae673610e169bf5a/${tokenId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                OpenSea
              </Link>
              {tokenId > 1 && <a href={`/gallery/${tokenId - 1}`}>Previous</a>}
              {tokenId < 1323 && <a href={`/gallery/${tokenId + 1}`}>Next</a>}
            </LeftInfo>
            <Center ref={ref}>
              <RelativeContainer
                style={{ width: fullscreen ? 'auto' : '100%' }}
              >
                <AbsoluteContainer>
                  <Illustration
                    graph={graph}
                    audioGraph={audioGraph}
                    onClick={playing ? pause : play}
                  />
                </AbsoluteContainer>
                <Controls>
                  <PlayControls>
                    <PlayButton
                      playing={playing}
                      toggle={playing ? pause : play}
                      baseHue={graph.baseHue}
                    />
                    <div>{graph.name}</div>
                  </PlayControls>
                  <FullscreenButton
                    fullscreen={fullscreen}
                    toggle={() => {
                      if (fullscreen) {
                        document.exitFullscreen();
                        setFullscreen(false);
                      } else {
                        ref.current?.requestFullscreen();
                        setFullscreen(true);
                      }
                    }}
                  />{' '}
                </Controls>
              </RelativeContainer>
              <div style={{ width: '100%', margin: '2em 0 0' }}>
                <div style={{ maxWidth: '500px' }}>
                  <p style={{ color: '#666' }}>
                    Audio issues?{' '}
                    <Link href={`/gallery/${tokenId}/prerendered`}>
                      play the pre-rendered version
                    </Link>
                    .
                  </p>
                  <p style={{ color: '#666' }}>
                    Synthopia synthesizes music live in your browser using
                    processor intensive DSP algorithms. If you are experiencing
                    issues with audio playback, you can listen to a version with
                    pre-rendered audio{' '}
                  </p>
                </div>
              </div>
            </Center>
            <RightInfo>
              <ThumbnailContainer>
                <GraphThumbnail
                  audioGraph={audioGraph}
                  graph={graph}
                  width={128}
                />
              </ThumbnailContainer>
              <CurrentBeat audioGraph={audioGraph} />
            </RightInfo>
          </Main>
        </>
      )}
    </Layout>
  );
};
