import React, { FC } from 'react';
import styled from 'styled-components';
import { P, Link, Footer, GridLayout, HR } from '../../components';
import { SideCity } from './SideCity';
import { Header } from '../../components/Header';
import { Image } from '../gallery/Image';
import { Vert } from './Vert';
import { Horiz } from './Horiz';
import { randomGraph } from '../../randomGraph';
import { translateSeed } from '../../utils';
import { Helmet } from 'react-helmet';

export type HomeProps = {};

const HeroContainer = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  min-width: 320px;
`;

const HeroAbsoluteContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 3em;
  display: flex;
  justify-content: center;
  z-index: 3;
`;

const AbsoluteContentInner = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

const Titles = styled.div`
  text-align: center;
  font-size: 7em;
  line-height: 1.3em;
  flex: 1;

  @media screen and (max-width: 1100px) {
    font-size: 6em;
  }
  @media screen and (max-width: 820px) {
    font-size: 5em;
  }
  @media screen and (max-width: 550px) {
    font-size: 4em;
  }
  @media screen and (max-width: 460px) {
    font-size: 3em;
  }
  @media screen and (max-width: 360px) {
    font-size: 2.5em;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-flow: column;
  padding: 6em 0 0;
  max-width: 900px;
  margin: 0 auto;
  align-itmes: stretch;
  z-index: 2;
  opacity: 1;
  transition: opacity 1s;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  box-sizing: border-box;
  padding: 0 3em 6em;
  margin: 0 auto;
`;

const IllustrationContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  color: #999;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 12em;
  margin: 2em 0 6em;
  @media screen and (max-width: 1100px) {
    gap: 6em;
  }
`;

const Block = styled.div`
  margin-bottom: 6em;
  padding: 0 3em;
  h2 {
    font-size: 2.5em;
    margin-top: 0;
  }
`;

const IllustratedSection = styled(Block)`
  display: grid;
  grid-template-columns: ${({ reverse }: { reverse?: boolean }) =>
    reverse ? '1fr 2fr' : '2fr 1fr'};
  align-items: center;
  gap: 3em;
  img {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 3em;
    margin-bottom: 3em;
  }
`;
const Content = styled.div`
  @media screen and (max-width: 600px) {
    order: 1;
  }
`;
const Illustration = styled.div`
  @media screen and (max-width: 600px) {
    max-width: 120px;
    margin: 0 auto;
  }
`;

const Name = styled.div`
  text-decoration: underline;
  margin: 0.5em 0;
`;
const Role = styled.div`
  text-decoration: none;
  color: #999;
`;

const graphs = [
  randomGraph(
    translateSeed(
      '66030837585680146314498943091608193231517181158233698294500944701967847111899',
    ),
    3,
  ),
  randomGraph(
    translateSeed(
      '25140544220145061422181151615407754943141910703752789229111862841350326077333',
    ),
    2,
  ),
  randomGraph(
    translateSeed(
      '100711163775645308545470475408645741900677986773409282385795735060443502367429',
    ),
    1,
  ),
  randomGraph(
    translateSeed(
      '59671507763428625654794603720063267996909105324943206749246646603386427793498',
    ),
    4,
  ),
  randomGraph(
    translateSeed(
      '30256756873403955302768847263766488953604529133145134277579323706234130995684',
    ),
    6,
  ),
  randomGraph(
    translateSeed(
      '8850712997075146895042729216527330265407002480421946061996282938522578954738',
    ),
    5,
  ),
];

export const Home: FC<HomeProps> = () => {
  return (
    <>
      <Helmet>
        <meta name="twitter:title" content="Synthopia" />
        <meta name="twitter:url" content="https://synthopia.io/" />
        <meta
          name="twitter:description"
          content="A fully generative music NFT experience by Gramatik, Luxas and Audioglyphs. Whitelist Mint: Dec. 8th / Public Mint: Dec. 11th"
        />
        <meta
          name="twitter:image"
          content="https://synthopia.io/Synthopia-header.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <HeroContainer>
        <HeroAbsoluteContent>
          <AbsoluteContentInner>
            <Header />
            <Row>
              <Vert numLines={18} topOpacity={0.4} bottomOpacity={0.1} />
              <Titles>
                <div style={{ textAlign: 'left' }}>A generative</div>
                <div style={{ textAlign: 'right' }}>music NFT</div>
                <div style={{ textAlign: 'center' }}>experience.</div>
              </Titles>
              <Vert numLines={18} topOpacity={0.4} bottomOpacity={0.1} />
            </Row>
            <Horiz />
          </AbsoluteContentInner>
        </HeroAbsoluteContent>
        <SideCity />
      </HeroContainer>
      <MainContent>
        <IllustratedSection>
          <Content>
            <h3>Gramatik + Luxas + Audioglyphs</h3>
            <h2>Welcome to Synthopia</h2>
            <P>
              Synthopia represents the future of music. A collaboration between
              Gramatik, Luxas and Audioglyphs, each Synthopia NFT is unique and
              synthesized directly in your browser at lossless quality. The
              Audioglyph DAW (digital audio workstation) makes this possible and
              opens the door for a new way to make music for the Metaverse.
            </P>
          </Content>
          <Illustration>
            <img src="/synthopia-artwork.png" />
          </Illustration>
        </IllustratedSection>

        {/* <IllustratedSection reverse>
          <Illustration>
            <img src="/dao-token.svg" />
          </Illustration>
          <Content>
            <h2>Audioglyphs DAO and Token</h2>
            <P>
              The mission of the upcoming Audioglyph platform and DAO is to make
              generative music have an impact on society similar to the
              development of recorded music, and to create a new business /
              distribution model for music that pays artists while sharing
              culture with everyone.
            </P>
            <P>
              Synthopia holders will be able to claim the Audioglyph DAO token
              to participate in the curation of the platform.{' '}
            </P>
            <P>
              <Link href="/faq#what-is-the-audioglyphs-dao">
                More about the DAO
              </Link>
            </P>
          </Content>
        </IllustratedSection> */}
        <IllustratedSection>
          <Content>
            <h2>Gramatik Live Shows</h2>
            <P>
              Synthopia holders will have access to some of Gramatik’s most
              intimate live concerts.
            </P>
            <P>
              <Link href="/faq#how-do-synthopia-holders-get-access-to-gramatik-live-shows">
                More about the Gramatik live shows
              </Link>
            </P>
          </Content>
          <Illustration>
            <img src="/live-shows.svg" />
          </Illustration>
        </IllustratedSection>
        <IllustratedSection reverse>
          <Illustration>
            <img src="/tech.svg" />
          </Illustration>
          <Content>
            <h2>Technology</h2>
            <P>
              The synthopia DSP graph is comprised of a 208 nodes including
              sequencing and modulation, audio synthesis, sample playback, and
              both convolution and algorithmic reverb. Each token includes a
              seed which is used to generate unique values for 111 randomized
              traits which set parameters of the audio generation. Most values
              are continuous and none introduce artificial rarity.
            </P>
            <P>
              <Link href="/faq#how-does-the-generative-music-work">
                More about the technology behind Synthopia
              </Link>
            </P>
          </Content>
        </IllustratedSection>
        <IllustratedSection>
          <Content>
            <h2>Holder Owned Music Rights</h2>
            <P>
              Synthopia owners are granted full rights to the music generated by
              their token with stems available for editing and remixing.
            </P>
            <P>
              <Link href="/faq#what-music-rights-do-synthopia-token-holders-have">
                More about music rights
              </Link>
            </P>
          </Content>
          <Illustration>
            <img src="/music-rights.png" />
          </Illustration>
        </IllustratedSection>
        <Block>
          <h2>Listen</h2>
          <GridLayout minSize={15}>
            {graphs.map(graph => (
              <IllustrationContainer
                plain
                href={`/gallery/${graph.tokenId}`}
                key={graph.tokenId}
              >
                <Image graph={graph} />
                {`#${graph.tokenId}`} {graph.name}
              </IllustrationContainer>
            ))}
          </GridLayout>
          <P>
            Continue to the gallery to explore the 1323 unique versions of
            Synthopia
          </P>
          <P>
            <Link href="/gallery">More</Link>
          </P>
        </Block>
        <Block>
          <h2>The team</h2>
          <GridLayout minSize={8}>
            <Link
              href="https://twitter.com/gramatik"
              target="_blank"
              rel="noopener noreferrer"
              plain
            >
              <img src="/gramatik.jpg" style={{ width: '100%' }} />
              <Name>Gramatik</Name>
              <Role>Artist</Role>
            </Link>
            <Link
              href="https://twitter.com/LuxasMusix"
              target="_blank"
              rel="noopener noreferrer"
              plain
            >
              <img src="/luxas.jpg" style={{ width: '100%' }} />
              <Name>Luxas</Name>
              <Role>Artist</Role>
            </Link>
            <Link
              href="https://twitter.com/realpinkflozd"
              target="_blank"
              rel="noopener noreferrer"
              plain
            >
              <img src="/pinkflozd.jpg" style={{ width: '100%' }} />
              <Name>Pinkflozd</Name>
              <Role>Dev + Relationships</Role>
            </Link>
            <Link
              href="https://twitter.com/0xdeployer"
              target="_blank"
              rel="noopener noreferrer"
              plain
            >
              <img src="/dep.jpeg" style={{ width: '100%' }} />
              <Name>deployer</Name>
              <Role>Audioglyphs Dev</Role>
            </Link>
            <Link
              href="https://twitter.com/tr666eth"
              target="_blank"
              rel="noopener noreferrer"
              plain
            >
              <img src="/tr666.png" style={{ width: '100%' }} />
              <Name>TR666</Name>
              <Role>Audioglyphs Dev</Role>
            </Link>
            <Link
              href="https://twitter.com/mmmkingedward"
              target="_blank"
              rel="noopener noreferrer"
              plain
            >
              <img src="/kingedward.jpg" style={{ width: '100%' }} />
              <Name>King Edward</Name>
              <Role>Design</Role>
            </Link>
          </GridLayout>
        </Block>
      </MainContent>
      <FooterContent>
        <HR />
        <Footer />
      </FooterContent>
    </>
  );
};
