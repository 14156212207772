import React, { FC, useRef, useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { randomGraph } from '../../randomGraph';
import {
  FullscreenButton,
  Illustration,
  Layout,
  Link,
  PlayButton,
} from '../../components';
import { GraphThumbnail } from '../player/GraphThumbnail';
import { Arrangement } from '../player/Arrangement';

export type PrerenderProps = {};

const Main = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 3fr 0.5fr;
  position: relative;
  gap: 3em;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 3fr 1fr;
    align-items: stretch;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    align-items: stretch;
  }
`;

const Controls = styled.div`
  height: 2.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const PlayControls = styled.div`
  display: flex;
  gap: 1em;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(256px + 2em);
  gap: 2em;
`;

const LeftInfo = styled(Info)`
  @media screen and (max-width: 1000px) {
    order: 2;
  }
`;

const RightInfo = styled(Info)`
  @media screen and (max-width: 600px) {
    order: 1;
    flex-direction: row;
    justify-content: space-between;
    max-width: none;
  }
`;

const ThumbnailContainer = styled.div`
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RelativeContainer = styled.div`
  position: relative;
  aspect-ratio: 1;
  flex: 1;
  padding-bottom: 2.5em;
  box-sizing: content-box;
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Prerender: FC<PrerenderProps> = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [playing, setPlaying] = useState(false);

  const seed = 7995740518333129;
  const tokenId = (seed % 9000) + 1;
  const graph = useMemo(() => randomGraph(seed, tokenId), [seed, tokenId]);

  // track fullscreen status
  useEffect(() => {
    if (fullscreen) {
      const exitHandler = () => {
        if (document.fullscreenElement === null) setFullscreen(false);
      };
      document.addEventListener('fullscreenchange', exitHandler);
      return () => {
        document.removeEventListener('fullscreenchange', exitHandler);
      };
    }
    return undefined;
  }, [fullscreen]);

  // load and control fallback audio element
  const audioElement = useMemo(() => {
    const audio = new Audio();
    audio.src = `/prerender.m4a`;
    audio.loop = true;
    audio.preload = 'auto';
    return audio;
  }, []);
  useEffect(() => () => audioElement.pause(), []);

  // set up play and pause
  const play = () => {
    audioElement?.play();
    setPlaying(true);
  };
  const pause = () => {
    audioElement?.pause();
    setPlaying(false);
  };

  // play / pause when spacebar is pressed
  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        e.stopPropagation();
        e.preventDefault();
        playing ? pause() : play();
      }
    };
    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  }, []);

  return (
    <Layout
      title="Demo"
      breadcrumbs={[
        ['DEMO', null],
        ['PRERENDER', '/prerender'],
      ]}
    >
      <Main>
        <LeftInfo>
          <div style={{ color: '#666' }}>
            <p>
              This is a preview of Synthopia using a seed that can never be
              minted.
            </p>
            <p>
              This version uses prerendered audio, if you are using chrome or
              safari on a powerful computer, you can find a live version{' '}
              <Link href="/seed/2914182757804241">here</Link>.
            </p>
          </div>
        </LeftInfo>
        <Center ref={ref}>
          <RelativeContainer style={{ width: fullscreen ? 'auto' : '100%' }}>
            <AbsoluteContainer>
              <Illustration
                graph={graph}
                audioGraph={null}
                audioElement={audioElement}
                onClick={playing ? pause : play}
              />
            </AbsoluteContainer>
            <Controls>
              <PlayControls>
                <PlayButton
                  playing={playing}
                  toggle={playing ? pause : play}
                  baseHue={graph.baseHue}
                />
                <div>{graph.name}</div>
              </PlayControls>
              <FullscreenButton
                fullscreen={fullscreen}
                toggle={() => {
                  if (fullscreen) {
                    document.exitFullscreen();
                    setFullscreen(false);
                  } else {
                    ref.current?.requestFullscreen();
                    setFullscreen(true);
                  }
                }}
              />
            </Controls>
          </RelativeContainer>
        </Center>
        <RightInfo>
          <ThumbnailContainer>
            <GraphThumbnail graph={graph} width={128} />
          </ThumbnailContainer>
          <Arrangement graph={graph} />
        </RightInfo>
      </Main>
    </Layout>
  );
};
