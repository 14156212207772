import { Trait } from './Override';
import { AudioNodeConfig, AudioNodeType } from './AudioNodes';
import { Vector } from './Vector';
import { RhythmStep } from '.';

export type ID = string;

export enum IOType {
  Input,
  Output,
}

export type IO = {
  readonly type: IOType;
  readonly node: ID;
  readonly index: number;
};

export type NodeData = {
  readonly position: Vector;
  readonly name: string;
};

export type Node = {
  readonly audioNode: AudioNodeConfig;
  readonly data: NodeData;
  readonly id: ID;
};

export type Edge = {
  readonly id: ID;
  readonly from: IO;
  readonly to: IO;
};

export type ArrangementStep = { voices: RhythmStep[]; duration: number };

export type Graph = {
  readonly seed: number;
  readonly tokenId: number;
  readonly name: string;
  readonly traits: Trait[];
  readonly arrangement: ArrangementStep[];
  readonly baseHue: number;
  readonly cycleDuration: number;
  readonly nodes: { [id: string]: Node };
  readonly edges: { [id: string]: Edge };
  readonly outgoingEdges: { [nodeId: string]: ID[] };
  readonly incomingEdges: { [nodeId: string]: ID[] };
};

export type GraphForStorage = {
  readonly seed: number;
  readonly name: string;
  readonly nodes: { [id: string]: Node };
  readonly edges: { [id: string]: Edge };
};

export type GraphThumbnail = {
  id: string;
  name: string;
  createdAt: number;
  data: GraphThumbnailData;
};

export type GraphThumbnailData = {
  nodes: [AudioNodeType, Vector][];
  edges: [[number, number], [number, number]][];
};
