import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { A } from './A';
import { Layout } from './Layout';

const Columns = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 6em;
  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  position: sticky;
  margin-top: -3em;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
`;

const Dummy = styled.div``;

export type SectionsLayoutProps = {
  title: string;
  breadcrumbs?: [string, string | null][];
  sections: {
    id: string;
    title: string;
    content: ReactNode;
  }[];
  before?: ReactNode;
  after?: ReactNode;
};

export const SectionsLayout: FC<SectionsLayoutProps> = ({
  title,
  breadcrumbs,
  sections,
  before,
  after,
}) => {
  return (
    <Layout title={title} breadcrumbs={breadcrumbs}>
      {before}
      <Columns>
        <Links>
          {sections.map(({ id, title }) => (
            <A block href={`#${id}`} key={id}>
              {title}
            </A>
          ))}
        </Links>
        <Sections>
          {sections.map(({ id, content }) => (
            <div id={id} key={id} style={{ padding: '3em 0' }}>
              {content}
            </div>
          ))}
        </Sections>
        <Dummy />
      </Columns>
      {after}
    </Layout>
  );
};
