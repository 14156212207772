import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body, #app {
    margin: 0;
    padding: 0;
    user-select: none;
    display: block;
    overscroll-behavior: none;
    width: 100vw;
    minWidth: 375px;
    height: 100vh;
    font-weight: 200;
    font-size: 13px;
    background-color: #000;
    color: #fff;
  }

  * {
    box-sizing: border-box;
    font-family: "Mono", monospace;
    vertical-align: top;
  }
  *:focus {
    z-index: 1;
    outline: solid var(--outline-color) 2px;
    outline-offset: 2px;
  }
  ::selection {
    background: #309;
  }
`;
