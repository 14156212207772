import {
  Scene,
  PerspectiveCamera,
  Mesh,
  MeshStandardMaterial,
  Fog,
  BoxGeometry,
  Color,
  DirectionalLight,
  PlaneBufferGeometry,
} from 'three';
import { mulberry32 } from '../utils/mulberry32';
import { hsluvToRgb } from 'hsluv-ts';
import { Graph } from '../types';
import { WrappedScene } from './WrappedScene';

const { sin, cos, pow, PI } = Math;

export const createCityScene = (graph: Graph): WrappedScene => {
  const random = mulberry32(graph.seed);
  const rand = (min: number, max: number) => min + random() * (max - min);

  const hue = graph.baseHue;
  const backgroundColor = new Color(
    ...hsluvToRgb([hue, rand(85, 95), rand(2.5, 15)]),
  );
  const buildingColor = new Color(
    ...hsluvToRgb([hue + rand(60, 120), rand(50, 70), rand(15, 45)]),
  );
  const groundColor = new Color(
    ...hsluvToRgb([hue - rand(0, 120), rand(30, 60), rand(90, 100)]),
  );

  const depth = rand(9, 21);
  const xSpacing = rand(1.8, 2.1);
  const ySpacing = rand(1.2, 1.8);
  const lightRotationTime = rand(60, 240);
  const lightRotationPhase = rand(0, 2 * PI);
  const minCameraHeight = rand(0.25, 0.75 * depth);
  const cameraRiseFallDepth = rand(depth * 0.25, 0.75 * depth);
  const cameraRiseFallTime = rand(40, 120);
  const cameraRiseFallPhase = rand(0, PI * 2);
  const speed = 0.8 + pow(random(), 5) * 2;

  const resources: { dispose: () => void }[] = [];

  const scene = new Scene();
  scene.fog = new Fog(backgroundColor, 0.1, depth);
  scene.background = backgroundColor;

  const camera = new PerspectiveCamera(60, 1, 0.1, depth);
  camera.position.set(0, 0, minCameraHeight + cameraRiseFallDepth / 2);
  camera.lookAt(
    0,
    rand(minCameraHeight + cameraRiseFallDepth / 2, 2.5 * depth),
    0,
  );
  scene.add(camera);

  const light = new DirectionalLight(0xffffff, 1);
  light.position.set(-20, depth / 2, 20);
  light.target.position.set(0, depth / 2, 0);
  light.castShadow = true;
  light.shadow.mapSize.width = 2048;
  light.shadow.mapSize.height = 2048;
  light.shadow.camera.left = -depth;
  light.shadow.camera.bottom = -depth;
  light.shadow.camera.top = depth;
  light.shadow.camera.right = depth;
  light.shadow.camera.near = 0.1;
  light.shadow.camera.far = 4 * depth;
  scene.add(light);
  scene.add(light.target);
  resources.push(light);

  const material = new MeshStandardMaterial({
    color: buildingColor,
    dithering: true,
  });
  resources.push(material);

  const meshes: Mesh[] = [];
  const heights = [1.5, 2.5, 1.5, 0.5, 0.5, 1.5, 2.5, 1.5];
  for (let x = -3.5 * xSpacing; x < 3.6 * xSpacing; x += xSpacing) {
    for (let y = -depth; y < depth * 2 + ySpacing; y += ySpacing) {
      const z =
        rand(-2.4, 2.4) * heights[Math.round((x + 3.5 * xSpacing) / xSpacing)] +
        depth / 2;
      const geometry = new BoxGeometry(1, 1, z);
      resources.push(geometry);

      const mesh = new Mesh(geometry, material);
      mesh.position.set(x + rand(-0.1, 0.1), y + rand(-0.1, 0.1), z / 2);
      mesh.receiveShadow = true;
      mesh.castShadow = true;
      scene.add(mesh);
      meshes.push(mesh);
    }
  }

  const planeGeometry = new PlaneBufferGeometry(6, 2 * depth);
  const planeMaterial = new MeshStandardMaterial({
    color: groundColor,
    dithering: true,
  });
  resources.push(planeGeometry, planeMaterial);

  const plane = new Mesh(planeGeometry, planeMaterial);
  plane.position.set(0, depth / 2, 0);
  plane.receiveShadow = true;
  scene.add(plane);

  const update = (time: number, delta: number): void => {
    camera.position.z =
      minCameraHeight +
      ((sin((time / cameraRiseFallTime) * PI * 2 + cameraRiseFallPhase) + 1) *
        cameraRiseFallDepth) /
        2;

    light.position.set(
      20 * cos((time / lightRotationTime) * PI * 2 + lightRotationPhase),
      20 * sin((time / lightRotationTime) * PI * 2 + lightRotationPhase) +
        ySpacing / 2,
      20,
    );

    meshes.forEach(mesh => {
      mesh.position.y -= delta * speed;
      if (mesh.position.y < -depth - 0.5) {
        mesh.position.y += 3 * depth + ySpacing * 2;
      }
    });
  };
  update(0, 0);

  return {
    scene,
    camera,
    update,
    dispose(): void {
      resources.forEach(r => r.dispose());
    },
  };
};
