import React, { FC, useRef, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { renderer, createSideCityScene } from '../../visualization';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export type SideCityProps = {};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`;

const Shade = styled.div`
  position: absolute;
  top: 70vh;
  height: 30vh;
  left: 0;
  width: 100vw;
  z-index: 2;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
`;

const orbit = false;

export const SideCity: FC<SideCityProps> = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { scene, camera, dispose, update } = useMemo(
    () => createSideCityScene(),
    [],
  );

  useEffect(() => {
    if (containerRef.current === null) return;

    containerRef.current.appendChild(renderer.domElement);

    const updateSize = () => {
      if (containerRef.current) {
        const { offsetWidth, offsetHeight } = containerRef.current;
        renderer.setSize(offsetWidth, offsetHeight);
        camera.aspect = offsetWidth / offsetHeight;
        camera.updateProjectionMatrix();
      }
    };
    window.addEventListener('resize', updateSize);
    updateSize();

    let initTime = Date.now() / 1000;
    let prevTime = initTime;
    let live = true;
    const animate = () => {
      if (!live) return;

      const time = Date.now() / 1000;
      const delta = time - prevTime;
      prevTime = time;

      renderer.render(scene, camera);
      update(time - initTime, delta);

      requestAnimationFrame(animate);
    };
    animate();

    if (orbit) {
      new OrbitControls(camera, renderer.domElement);
    }

    return () => {
      live = false;
      containerRef.current?.removeChild(renderer.domElement);
      window.removeEventListener('resize', updateSize);
      dispose();
    };
  }, [scene, camera, dispose, update, containerRef, renderer]);

  return (
    <Container>
      <Container ref={containerRef} />
      <Shade />
    </Container>
  );
};
