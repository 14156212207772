import { hsluvToHex } from 'hsluv-ts';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Graph } from '../../types';
import { imageRenderer } from '../../visualization';

type ImageProps = {
  graph: Graph;
};

const Img = styled.img`
  aspect-ratio: 1;
`;

export const Image: FC<ImageProps> = ({ graph }) => {
  const [src, setSrc] = useState<string | undefined>();
  useEffect(() => {
    imageRenderer.render(graph).then(setSrc);
  }, [graph]);

  return (
    <Img
      as={src === undefined ? 'div' : 'img'}
      {...(src === undefined ? {} : { src })}
      style={{ background: hsluvToHex([graph.baseHue, 70, 2.5]) }}
    />
  );
};
