import React, { FC } from 'react';
import { BasicLayout } from './BasicLayout';
import { H1 } from './H1';
import { Link } from './Link';
import { P } from './P';

export const NotFoundPage: FC = () => {
  return (
    <BasicLayout>
      <H1>404</H1>
      <P>The page you are looking for can’t be found.</P>
      <Link href="/">home</Link>
    </BasicLayout>
  );
};
