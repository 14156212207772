import React, { FC, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Graph } from '../types';
import { AudioGraph } from '../audioGraph';
import { renderer, createCityScene } from '../visualization';

export type IllustrationProps = {
  graph: Graph;
  audioGraph?: AudioGraph | null;
  audioElement?: HTMLAudioElement | null;
  onClick?: () => void;
};

export const Container = styled.div`
  aspect-ratio: 1;
  width: 100%;
  background: #001;
`;

export const Illustration: FC<IllustrationProps> = ({
  graph,
  audioGraph,
  audioElement,
  onClick,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current === null) return;

    containerRef.current.appendChild(renderer.domElement);

    const updateSize = () => {
      if (containerRef.current) {
        const size = Math.min(
          containerRef.current.offsetWidth,
          containerRef.current.offsetHeight,
        );
        renderer.setSize(size, size);
      }
    };
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {
      containerRef.current?.removeChild(renderer.domElement);
      window.removeEventListener('resize', updateSize);
    };
  }, [renderer]);

  useEffect(() => {
    if (audioGraph === null && audioElement === null) return;

    const { scene, camera, dispose, update } = createCityScene(graph);

    let initTime = 0;
    let prevTime = initTime;
    let live = true;
    const animate = () => {
      if (!live) return;

      const time =
        audioElement?.currentTime ?? audioGraph?.context?.currentTime ?? 0;
      const delta = time - prevTime;
      prevTime = time;

      renderer.render(scene, camera);
      update(time, delta);

      requestAnimationFrame(animate);
    };
    animate();

    return () => {
      live = false;
      dispose();
    };
  }, [graph.seed, audioGraph || audioElement]);

  return <Container ref={containerRef} onClick={onClick} />;
};
