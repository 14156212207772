import { hsluvToHex } from 'hsluv-ts';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Trait } from '../../types';

export const Table = styled.table`
  border-spacing: 0;
  width: 128px;
  td {
    padding-right: 1em;
    user-select: auto;
    white-space: pre;
    font-family: monospace;
    color: #666;
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export type StatsProps = {
  traits: Trait[];
  baseHue: number;
};

export const Stats: FC<StatsProps> = ({ traits, baseHue }) => {
  const color = hsluvToHex([baseHue + 120, 20, 15]);
  return (
    <Table style={{ color }}>
      {traits.map(([name, value]) => (
        <tr key={name}>
          <td>{name}</td>
          <td>{value}</td>
        </tr>
      ))}
    </Table>
  );
};
