import audioBufferToWav from 'audiobuffer-to-wav';
import { Graph } from '../types';
import { AudioGraph, clearFiles } from './AudioGraph';

export const downloadAudioFile = async (
  graph: Graph,
  numberOfChannels: number,
  sampleRate: number, // hz
  bitDepth: number, // must be 16 or 32 bits
  length: number, // seconds
  fadeIn: number = 0, // seconds
  fadeOut: number = 0, // seconds
  name: string,
  setProgress?: (ratio: number) => void,
  refresh: boolean = true,
): Promise<void> => {
  if (bitDepth !== 32 && bitDepth !== 16) {
    throw new Error('invalid bit depth');
  }

  const context = new OfflineAudioContext({
    numberOfChannels,
    length: length * sampleRate,
    sampleRate,
  });

  clearFiles();
  const ag = new AudioGraph(graph, context);
  await ag.filesReady;
  if (fadeIn !== 0) {
    ag.destination.gain.setValueAtTime(0, 0);
    ag.destination.gain.linearRampToValueAtTime(1, fadeIn);
  }
  if (fadeOut !== 0) {
    ag.destination.gain.setValueAtTime(1, length - fadeOut);
    ag.destination.gain.linearRampToValueAtTime(0, length);
  }
  let i;
  if (setProgress) {
    i = setInterval(() => {
      setProgress(context.currentTime / length);
    }, 500);
  }
  const buffer = await context.startRendering();
  clearInterval(i);
  const wav = audioBufferToWav(buffer, { float32: bitDepth === 32 });
  const blob = new window.Blob([new DataView(wav)], {
    type: 'audio/wav',
  });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `${name}.wav`;
  anchor.click();
  window.URL.revokeObjectURL(url);
  if (refresh) {
    window.location.reload();
  }
};
