import { Scene, PerspectiveCamera, Fog, Color, DirectionalLight } from 'three';
import { hsluvToRgb } from 'hsluv-ts';
import { WrappedScene } from '../WrappedScene';
import { buildTowers } from './buildTowers';
import { buildFreighter } from './buildFreighter';
import { animate } from './animate';

export const createSideCityScene = (): WrappedScene => {
  const hue = Math.floor(Math.random() * 360);
  const rand = (min: number, max: number) => min + Math.random() * (max - min);
  const backgroundColor = new Color(...hsluvToRgb([hue, rand(85, 95), 5]));
  const cityColor = new Color(
    ...hsluvToRgb([hue + 66, rand(50, 70), rand(15, 25)]),
  );
  const resources: { dispose: () => void }[] = [];

  const depth = 300;
  const dist = 40;
  const time = 60;

  const scene = new Scene();
  scene.background = backgroundColor;

  const fog = new Fog(backgroundColor, 0.1, 160);
  scene.fog = fog;

  const camera = new PerspectiveCamera();
  camera.position.set(-130, 10, dist + depth);
  camera.lookAt(0, 0, dist + depth);
  scene.add(camera);

  const light = new DirectionalLight(0xffffff, 0.8);
  light.position.set(-40, 100, 0);
  light.target.position.set(0, 0, 100);
  light.castShadow = true;
  light.shadow.mapSize.width = 2048;
  light.shadow.mapSize.height = 2048;
  light.shadow.camera.left = -depth / 2;
  light.shadow.camera.bottom = -depth;
  light.shadow.camera.top = depth;
  light.shadow.camera.right = depth / 2;
  light.shadow.camera.near = 0.1;
  light.shadow.camera.far = 4 * depth;
  scene.add(light);
  scene.add(light.target);
  resources.push(light);

  const freighter = buildFreighter(cityColor, dist, depth, time);
  resources.push(freighter);
  scene.add(freighter.group);

  const towers = buildTowers(cityColor, dist, depth);
  resources.push(towers);
  scene.add(towers.group);

  const animations = [
    ...freighter.animations,
    animate(light, [['.intensity', [0, 3], [0, 1]]]),
  ];

  const update = (time: number, delta: number): void => {
    animations.forEach(mixer => mixer.update(delta));
    light.position.x = Math.sin(time / 3) * 60;
  };
  update(0, 0);

  return {
    scene,
    camera,
    update,
    dispose(): void {
      resources.forEach(r => r.dispose());
    },
  };
};
