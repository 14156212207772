import styled, { css } from 'styled-components';
import { pulse } from '../style';

export type NetworkActivityIndicatorProps = {
  pending: boolean;
  active: boolean;
  error: boolean;
};

export const NetworkActivityIndicator = styled.div`
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background: ${({ error, active }: NetworkActivityIndicatorProps) =>
    error ? 'red' : active ? '#fff' : '#333'};
  color: #000;
  text-align: center;
  animation: ${({ active, error }) =>
    active && !error
      ? css`
          ${pulse} 1s linear infinite alternate
        `
      : 'none'};
  transform: ${({ pending, active, error }) =>
    pending && !active && !error ? 'scale(0.75)' : 'scale(1)'};
  transition: opacity 0.3s linear, background-color 0.2s linear;
  opacity: ${({ pending, active, error }) =>
    pending || active || error ? 1 : 0};
  &:after {
    content: ${({ error }) => (error ? "'!'" : '')};
  }
`;
