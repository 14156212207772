import { GraphForStorage, Graph } from '../types';

export const graphFromStorage = ({
  seed,
  name,
  nodes,
  edges,
}: GraphForStorage): Graph => {
  const incomingEdges: { [id: string]: Array<string> } = {};
  const outgoingEdges: { [id: string]: Array<string> } = {};
  Object.values(nodes).forEach(node => {
    incomingEdges[node.id] = [];
    outgoingEdges[node.id] = [];
  });
  Object.values(edges).forEach(
    ({ id, from: { node: fromNode }, to: { node: toNode } }) => {
      incomingEdges[toNode].push(id);
      outgoingEdges[fromNode].push(id);
    },
  );
  return {
    seed,
    name,
    nodes,
    edges,
    incomingEdges,
    outgoingEdges,
    traits: [],
    arrangement: [],
    baseHue: 0,
  };
};
