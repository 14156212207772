import { TriggerEvent } from '../audioNodeUtils';

export class RandomGateNode extends AudioWorkletNode {
  readonly clock: AudioParam;
  readonly probability: AudioParam;

  constructor(
    context: BaseAudioContext,
    {
      probability = 0.5,
    }: {
      probability?: number;
    } = {},
  ) {
    super(context, 'RandomGateProcessor', {
      numberOfInputs: 0,
      numberOfOutputs: 1,
      outputChannelCount: [1],
      channelCount: 1,
      channelCountMode: 'explicit',
      channelInterpretation: 'discrete',
      parameterData: { probability },
    });
    this.port.onmessage = () => {
      this.dispatchEvent(new TriggerEvent());
    };

    // @ts-ignore
    this.clock = this.parameters.get('clock');
    // @ts-ignore
    this.probability = this.parameters.get('probability');
  }
}
