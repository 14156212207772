import React, { FC, useRef, useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { randomGraph } from '../../randomGraph';
import {
  A,
  FullscreenButton,
  Illustration,
  Layout,
  Link,
  PlayButton,
} from '../../components';
import { GraphThumbnail } from '../player/GraphThumbnail';
import { Arrangement } from '../player/Arrangement';
import { useAPIData } from '../../hooks';
import { Metadata } from '../../types';
import { translateSeed } from '../../utils';
import { Stats } from '../player/Stats';

export type PrerenderPlayerProps = {
  path: string[];
};

const Main = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 3fr 0.5fr;
  position: relative;
  gap: 3em;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 3fr 1fr;
    align-items: stretch;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    align-items: stretch;
  }
`;

const Controls = styled.div`
  height: 2.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const PlayControls = styled.div`
  display: flex;
  gap: 1em;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(256px + 2em);
  gap: 2em;
`;

const LeftInfo = styled(Info)`
  & a,
  & a:visited {
    color: white;
  }
  @media screen and (max-width: 1000px) {
    order: 2;
  }
`;

const RightInfo = styled(Info)`
  @media screen and (max-width: 600px) {
    order: 1;
    flex-direction: row;
    justify-content: space-between;
    max-width: none;
  }
`;

const ThumbnailContainer = styled.div`
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RelativeContainer = styled.div`
  position: relative;
  aspect-ratio: 1;
  flex: 1;
  padding-bottom: 2.5em;
  box-sizing: content-box;
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const PrerenderPlayer: FC<PrerenderPlayerProps> = ({
  path: [, tokenIdString],
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [playing, setPlaying] = useState(false);

  const tokenId = parseInt(tokenIdString);

  let [loading, error, data] = useAPIData<Metadata>(`metadata/${tokenId}`, [
    tokenId,
  ]);

  const seed = data && data.seed ? translateSeed(data.seed) : null;
  const graph = useMemo(() => (seed ? randomGraph(seed, tokenId) : null), [
    seed,
    tokenId,
  ]);

  // track fullscreen status
  useEffect(() => {
    if (fullscreen) {
      const exitHandler = () => {
        if (document.fullscreenElement === null) setFullscreen(false);
      };
      document.addEventListener('fullscreenchange', exitHandler);
      return () => {
        document.removeEventListener('fullscreenchange', exitHandler);
      };
    }
    return undefined;
  }, [fullscreen]);

  // load and control fallback audio element
  const audioElement = useMemo(() => {
    const audio = new Audio();
    if (data) {
      audio.src = data.audio;
      audio.loop = true;
      audio.preload = 'auto';
    }
    return audio;
  }, [data]);
  useEffect(() => () => audioElement.pause(), [audioElement]);

  // set up play and pause
  const play = () => {
    audioElement?.play();
    setPlaying(true);
  };
  const pause = () => {
    audioElement?.pause();
    setPlaying(false);
  };

  // play / pause when spacebar is pressed
  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        e.stopPropagation();
        e.preventDefault();
        playing ? pause() : play();
      }
    };
    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  }, []);

  return (
    <Layout
      title={graph?.name ?? ''}
      breadcrumbs={[
        ['GALLERY', '/gallery'],
        [`#${tokenId} ${graph?.name}`, `/gallery/${tokenId}`],
        ['prerendered', `/gallery/${tokenId}/prerendered`],
      ]}
      loading={loading}
      error={!!error}
    >
      {graph && (
        <Main>
          <LeftInfo>
            <Stats traits={graph.traits} baseHue={graph.baseHue} />
            <Link href={`/gallery/${tokenId}/download`}>Download</Link>
            <Link href={data.video} target="_blank" rel="noopener noreferrer">
              Download video
            </Link>
            <Link href={data.image} target="_blank" rel="noopener noreferrer">
              Download image
            </Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="twitter-share-button"
              href={`https://twitter.com/intent/tweet?text=Check out ${graph.name} from the Synthopia NFT collection by @gramatik, @luxasmusix, and @audioglyphs.&url=${window.location.href}`}
              data-size="large"
            >
              Tweet
            </a>
            <Link href={`/gallery/${tokenId}/embed`}>Embed</Link>
            <Link
              href={`https://opensea.io/assets/0x709ea840f66fb32a762908afae673610e169bf5a/${tokenId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              OpenSea
            </Link>
            {tokenId > 1 && (
              <A href={`/gallery/${tokenId - 1}/prerendered`}>Previous</A>
            )}
            {tokenId < 1323 && (
              <A href={`/gallery/${tokenId + 1}/prerendered`}>Next</A>
            )}
          </LeftInfo>
          <Center ref={ref}>
            <RelativeContainer style={{ width: fullscreen ? 'auto' : '100%' }}>
              <AbsoluteContainer>
                <Illustration
                  graph={graph}
                  audioGraph={null}
                  audioElement={audioElement}
                  onClick={playing ? pause : play}
                />
              </AbsoluteContainer>
              <Controls>
                <PlayControls>
                  <PlayButton
                    playing={playing}
                    toggle={playing ? pause : play}
                    baseHue={graph.baseHue}
                  />
                  <div>{graph.name}</div>
                </PlayControls>
                <FullscreenButton
                  fullscreen={fullscreen}
                  toggle={() => {
                    if (fullscreen) {
                      document.exitFullscreen();
                      setFullscreen(false);
                    } else {
                      ref.current?.requestFullscreen();
                      setFullscreen(true);
                    }
                  }}
                />
              </Controls>
            </RelativeContainer>
            <div style={{ width: '100%', margin: '2em 0 0' }}>
              <p style={{ color: '#666', maxWidth: 500 }}>
                This version of Synthopia uses prerendered audio. If you are
                using chrome or safari on a powerful computer, Synthopia is
                capable of generating music live. You can find a live version{' '}
                <Link href={`/gallery/${tokenId}`}>here</Link>.
              </p>
            </div>
          </Center>
          <RightInfo>
            <ThumbnailContainer>
              <GraphThumbnail graph={graph} width={128} />
            </ThumbnailContainer>
            <Arrangement graph={graph} />
          </RightInfo>
        </Main>
      )}
    </Layout>
  );
};
