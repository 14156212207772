import { hsluvToHex } from 'hsluv-ts';
import styled from 'styled-components';

export type InputProps = {
  highlighted?: boolean;
  disabled?: boolean;
};

export const Input = styled.input.attrs({
  autoComplete: 'off',
  spellCheck: false,
})`
  background-color: #3d3d3d;
  color: black;
  border: 0;
  margin: 0;
  padding: 12px 10px;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 3px;
  border: 2px solid ${hsluvToHex([0 + 120, 70, 40])};
  display: block;

  color: white;
  box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.3);
  position: relative;

  &[type='checkbox'],
  &[type='radio'] {
    width: 1.5em;
    display: inline-block;
  }
  &[type='radio'] {
    border-radius: 0.75em;
  }

  &:checked:after {
    content: '';
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    width: 0.5em;
    height: 0.5em;
    background: black;
  }
  &[type='radio']:checked:after {
    border-radius: 0.25em;
  }

  & option {
    font-family: 'Mono', sans-serif;
  }
`;
