import React, { FC } from 'react';
import { SectionsContentLayout, A, P, Link } from '../../components';

export const FAQ: FC = () => {
  return (
    <SectionsContentLayout
      title="FAQ"
      path="/faq"
      sections={[
        {
          id: 'why-a-whitelist',
          title: 'Why a whitelist?',
          content: (
            <>
              <P>
                We are using a whitelist to try to ensure an inclusive mint and
                to avoid high gas costs.
              </P>
              <P>
                Each whitelist code guarantees a chance to mint up three
                Synthopia NFTs between Dec 8 and Dec 11. This allows whitelist
                members to mint at a time when gas is favorable.
              </P>
              <P>
                A limit of one code per wallet is intended to ensure that
                supporters of our previous projects have a chance to mint
                Synthopia.
              </P>
            </>
          ),
        },
        {
          id: 'how-can-i-get-on-the-whitelist-for-minting',
          title: 'How can I get on the whitelist for minting?',
          content: (
            <>
              <P>
                For a chance to claim a code, you must hold an Audioglyph NFT,
                Gramatik NFT, Party Degenerate NFT, or GRMTK ERC20 token at the
                time of the snapshot, which will be taken 12/5. Each minting
                code will allow you to mint up to 3 Synthopia NFTs. The price of
                Synthopia NFTs for code holders will be 0.0639 ETH per NFT.
              </P>
              <P>
                Whitelist Code Claiming starts on Dec. 7th at 9am PST / 12pm
                EST. Codes can be claimed in the Audioglyphs, Gramatik, or Party
                Degenerates Discord servers
              </P>
            </>
          ),
        },
        {
          id: 'when-does-whitelist-minting-start',
          title: 'When does whitelist minting start?',
          content: (
            <>
              <P>
                Whitelist minting starts on Wed, Dec 8th at 9am PST / 12pm EST
                and continues for three days until Sat, Dec 11th at 9am PST /
                12pm EST. The whitelist mint price is 0.0639 eth.
              </P>
              <P>
                Any Synthopia NFTs not minted during the whitelist phase will go
                to public sale for 0.0693 ETH.
              </P>
            </>
          ),
        },
        {
          id: 'when-does-public-minting-start',
          title: 'When does public minting start?',
          content: (
            <>
              <P>
                Public minting starts on Sat, Dec. 11th at 9am PST / 12pm EST
                and continues for three days until Dec. 14th at 9am PST / 12pm
                EST. The public mint price is 0.0693 ETH.
              </P>
            </>
          ),
        },
        {
          id: 'will-there-be-tokens-available-for-public-mint',
          title: 'Will there be tokens available for public mint?',
          content: (
            <>
              <P>
                We expect yes - each whitelist code allows minting of up to
                three tokens. Even if all codes are claimed, it is unlikely that
                everyone who recieves a code will mint all three of their
                allocated tokens.
              </P>
              <P>
                Any tokens unminted during the whitelist period go to public
                sale.
              </P>
            </>
          ),
        },
        {
          id: 'when-will-tokens-be-revealed',
          title: 'When will tokens be revealed?',
          content: (
            <>
              <P>Tokens will be revealed when minting closes on Dec 14.</P>
              <P>
                As the final step of the minting process, we use Chainlink VRF
                to generate a provably random number on the blockchain which
                becomes a component of the token seeds. This guarantees a fair
                distribution of traits, but it means that no seeds are available
                until minting ends.
              </P>
            </>
          ),
        },
        {
          id: 'what-parts-of-synthopia-are-on-chain',
          title: 'What parts of synthopia are on chain?',
          content: (
            <>
              <P>
                We store randomization scripts and the base graph description in
                a format playable by{' '}
                <Link href="https://github.com/tr666eth/helicon">helicon</Link>,
                the open source core of audioglyphs. We plan to put the helicon
                library and extensions on chain as part of the work on the
                upcoming Audioglyphs platform.
              </P>
              <P>
                Synthopia also uses audio samples for drums, bass, and reverb
                impulse response. These are stored in IPFS.
              </P>
            </>
          ),
        },
        {
          id: 'is-synthopia-carbon-neutral',
          title: 'Is Synthopa carbon neutral?',
          content: (
            <>
              <P>
                Yes! We will purchase a carbon offset through{' '}
                <Link href="https://offsetra.com/">Offsetra</Link> covering
                emissions from the minting Synthopia and update this site with a
                link to the transaction.
              </P>
            </>
          ),
        },
        {
          id: 'what-music-rights-do-synthopia-token-holders-have',
          title: 'What music rights do Synthopia token holders have?',
          content: (
            <>
              <P>
                Synthopia token holders have full commercial rights to their
                unique version. They can remix and release it, play it publicly,
                or use it in other content like videos.
              </P>
              <P>
                Synthopia.io includes the ability to render full quality audio
                files and to render individual tracks as Stems to make edits and
                remixes easy.
              </P>
              <P>
                Holders do not have exclusive rights, meaning they cannot remove
                their version from synthopia.io or stop it from showing up in
                NFT marketplaces like OpenSea.
              </P>
              <P>
                If you do use a version commercially, you should not sell the
                token. If you do, you will transfer the rights and may need to
                obtain permission from the new owner to coninue your use.
              </P>
              <P>
                Every buyer will be responsible for their own copyright claims.
              </P>
            </>
          ),
        },
        {
          id: 'how-do-synthopia-holders-get-access-to-gramatik-live-shows',
          title: 'How do Synthopia holders get access to Gramatik live shows?',
          content: (
            <>
              <P>
                Synthopia holders will have free entry to future Gramatik shows,
                excluding festival shows and other settings where he has no say
                in guest pass availability.
              </P>
              <P>
                Look for more information on how to get guest passes in the
                Gramatik discord when touring resumes.
              </P>
            </>
          ),
        },
        {
          id: 'how-does-the-generative-music-work',
          title: 'How does the generative music work?',
          content: (
            <>
              <P>
                Synthopia was built using the Audioglyph DAW (digital audio
                workstation), a tool for creating generative music that uses
                low-level web browser APIs to synthesize music directly in the
                browser.
              </P>
              <P>
                Using the Audioglyphs DAW, Gramatik and Luxas created a song
                called Synthopia Prime, implemented as a signal processing graph
                with more than 200 DSP nodes. More than 100 parameters of these
                nodes are randomized, with ranges carefully designed to produce
                musical results.
              </P>
              <P>
                The audio generation components of the DAW are embedded in each
                player and generate music locally in user’s browsers. The audio
                is entirely uncompressed and playback quality is lossless, while
                the file size of the seed and generation scripts is dramatically
                smaller than the raw audio. Running in browser is important for
                local generation to be practical - it provides cross platform
                audio libraries and its sandbox resolves the security issues
                that would be present with running untrusted native code to
                generate audio
              </P>
            </>
          ),
        },
        {
          id: 'how-does-synthopia-run-in-the-browser',
          title: 'How does Synthopia run in the browser?',
          content: (
            <>
              <P>
                Synthopia runs in the browser using the web audio standard,
                which is recently fully supported across common browsers. Its
                processing graph makes use of both native nodes implemented by
                the browser and custom nodes written in javascript and in c++
                compiled to run in the browser using web assembly.
              </P>
              <P>
                Synthopia pushes the limits of local synthesis, using a very
                large number of nodes and many processor intensive effects.
                Local synthesis works in Chrome and Safari, but a bug in
                Firefox's implementation of the WebAudio spec prevents it from
                working. Additionally, many mobile devices will not have the
                processing power to play back Synthopia in real time.
              </P>
              <P>
                After the reveal, we will synthesize and host high quality audio
                files in traditional formats for playback on devices that do not
                support local synthesis of Synthopia.
              </P>
            </>
          ),
        },
        {
          id: 'what-is-the-audioglyphs-daw',
          title: 'What is the Audioglyphs DAW?',
          content: (
            <>
              <P>
                The Audioglyph DAW (digital audio workstation) is a tool for
                creating generative music that uses low-level web browser APIs
                to synthesize music directly in the browser.
              </P>
              <P>
                Traditional DAWs can be used to produce a single song in a
                traditional audio format. The Audioglyphs DAW produces something
                similar to Synthopia, generative music with seeded random
                variations in a highly efficent local synthesis format.
              </P>
            </>
          ),
        },
        // {
        //   id: 'what-is-the-audioglyphs-dao',
        //   title: 'What is the Audioglyphs DAO?',
        //   content: (
        //     <>
        //       <P>
        //         The mission of the Audioglyphs DAO is to make generative music
        //         have an impact on society similar to that of the development of
        //         recorded music and to create a new business/distribution model
        //         for music that pays artists while sharing culture with everyone.
        //       </P>
        //       <P>
        //         We are working on an Audioglyphs platform to release generative
        //         music NFTs.
        //       </P>
        //       <P>
        //         The platform will include both team and community curated
        //         releases, with community releases being more frequent. Community
        //         releases will be managed by the DAO, and the DAO will have the
        //         power to grant early access to the DAW and release on the
        //         platform with its treasury recieving a portion of mint proceeds.
        //       </P>
        //       <P>
        //         10% of the total supply of governance tokens is allocated for
        //         Synthopia holders.
        //       </P>
        //     </>
        //   ),
        // },
      ]}
    />
  );
};
