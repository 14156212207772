import { AudioNodeType } from '../types';
import {
  ADEnvelopeNode,
  // MIDICCNode,
  // MIDIGateNode,
  // MIDIInputNode,
  PitchShifterNode,
  QuantizerNode,
  RandomGateNode,
  ResonatorNode,
  ReverbNode,
  RhythmNode,
  SampleAndHoldNode,
  SequenceNode,
  TriggeredSamplerNode,
  WhiteNoiseNode,
} from '../audioNodes';

export const audioNodeConstructors: {
  [type in AudioNodeType]: {
    new (context: BaseAudioContext, ...args: any): AudioNode;
  };
} = {
  [AudioNodeType.ADEnvelopeNode]: ADEnvelopeNode,
  [AudioNodeType.AnalyserNode]: AnalyserNode,
  [AudioNodeType.AudioBufferSourceNode]: AudioBufferSourceNode,
  [AudioNodeType.AudioDestinationNode]: AudioDestinationNode,
  [AudioNodeType.BiquadFilterNode]: BiquadFilterNode,
  [AudioNodeType.ChannelMergerNode]: ChannelMergerNode,
  [AudioNodeType.ChannelSplitterNode]: ChannelSplitterNode,
  [AudioNodeType.ConstantSourceNode]: ConstantSourceNode,
  [AudioNodeType.ConvolverNode]: ConvolverNode,
  [AudioNodeType.DelayNode]: DelayNode,
  [AudioNodeType.DynamicsCompressorNode]: DynamicsCompressorNode,
  [AudioNodeType.GainNode]: GainNode,
  [AudioNodeType.IIRFilterNode]: IIRFilterNode,
  // [AudioNodeType.MIDICCNode]: MIDICCNode,
  // [AudioNodeType.MIDIGateNode]: MIDIGateNode,
  // [AudioNodeType.MIDIInputNode]: MIDIInputNode,
  [AudioNodeType.OscillatorNode]: OscillatorNode,
  [AudioNodeType.PannerNode]: PannerNode,
  [AudioNodeType.PitchShifterNode]: PitchShifterNode,
  [AudioNodeType.QuantizerNode]: QuantizerNode,
  [AudioNodeType.RandomGateNode]: RandomGateNode,
  [AudioNodeType.ResonatorNode]: ResonatorNode,
  [AudioNodeType.ReverbNode]: ReverbNode,
  [AudioNodeType.RhythmNode]: RhythmNode,
  [AudioNodeType.SampleAndHoldNode]: SampleAndHoldNode,
  [AudioNodeType.SequenceNode]: SequenceNode,
  [AudioNodeType.StereoPannerNode]: StereoPannerNode,
  [AudioNodeType.TriggeredSamplerNode]: TriggeredSamplerNode,
  [AudioNodeType.WaveShaperNode]: WaveShaperNode,
  [AudioNodeType.WhiteNoiseNode]: WhiteNoiseNode,
};
