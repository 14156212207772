import {
  Object3D,
  AnimationMixer,
  AnimationClip,
  NumberKeyframeTrack,
  VectorKeyframeTrack,
  LoopOnce,
  QuaternionKeyframeTrack,
  InterpolateSmooth,
  KeyframeTrack,
} from 'three';

export const animate = (
  obj: Object3D,
  properties: (
    | [property: string, times: number[], values: number[]]
    | KeyframeTrack
  )[],
  start: number = 0,
  loop: boolean = false,
): AnimationMixer => {
  const mixer = new AnimationMixer(obj);
  const action = mixer
    .clipAction(
      new AnimationClip(
        '!',
        -1,
        properties.map(t => {
          if (t instanceof KeyframeTrack) return t;
          const [property, times, values] = t;
          switch (values.length) {
            case times.length:
              return new NumberKeyframeTrack(property, times, values);
            case 3 * times.length:
              return new VectorKeyframeTrack(property, times, values);
            case 4 * times.length:
              return new QuaternionKeyframeTrack(
                property,
                times,
                values,
                InterpolateSmooth,
              );
            default:
              throw new Error(
                `invalid values length for ${property} animation`,
              );
          }
        }),
      ),
    )
    .startAt(start)
    .play();
  if (loop === false) {
    action.loop = LoopOnce;
    action.clampWhenFinished = true;
  }
  return mixer;
};
