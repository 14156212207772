import { hsluvToHex } from 'hsluv-ts';
import React, { ChangeEvent, FC, MouseEvent, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Label,
  Input,
  RadioButtonGroup,
  Layout,
  Center,
  NetworkActivityIndicator,
  Link,
} from '../../components';
import WEB_URL from '../../data/WEB_URL';
import { useAPIData } from '../../hooks';
import { randomGraph } from '../../randomGraph';
import { Metadata } from '../../types';
import { TAB_ORDER, translateSeed } from '../../utils';

export type EmbedProps = {
  path: string[];
};

const Field = styled.div`
  margin: 1em 0;
`;

const Code = styled.div`
  margin: 2em 0 0;
  padding: 0.5em;
  width: 192px;
  word-wrap: break-word;
  user-select: auto;
`;

const Row = styled.div`
  margin-top: 1em;
  text-align: center;
  width: 192px;
`;

export const Embed: FC<EmbedProps> = ({ path }) => {
  const tokenId = parseInt(path[1]);

  const [loading, error, data] = useAPIData<Metadata>(
    Number.isNaN(tokenId) ? undefined : `metadata/${tokenId}`,
    [tokenId],
  );
  const seed = data ? translateSeed(data.seed) : null;
  const graph = useMemo(() => (seed ? randomGraph(seed, tokenId) : undefined), [
    seed,
    tokenId,
  ]);

  const [width, setWidth] = useState(360);
  const [includeVisualization, setIncludeVisualization] = useState<'y' | 'n'>(
    'y',
  );

  const w = Math.max(240, width);
  const h = includeVisualization ? w + 20 : 36;
  const embedCode = `<iframe width="${w}" height="${h}" scrolling="no" frameborder="no" src="${WEB_URL}/embed.html?id=${tokenId}&s=${seed}&v=${includeVisualization}"></iframe>`;

  if (loading || error || !graph) {
  }
  return loading || error || !graph ? (
    <Layout title="" breadcrumbs={[]} loading={loading} error={!!error} />
  ) : (
    <Layout
      title={`${graph.name} Embed`}
      breadcrumbs={[
        ['GALLERY', '/gallery'],
        [`#${tokenId} ${graph.name}`, `/gallery/${tokenId}`],
        ['Embed', `/gallery/${tokenId}/embed`],
      ]}
    >
      {loading ? (
        <Center>
          <NetworkActivityIndicator
            pending={false}
            active={loading}
            error={!!error}
          />
        </Center>
      ) : (
        <>
          <div>
            <p>
              The Synthopia audio or audiovisual player can be embedded in other
              websites.
            </p>
          </div>
          <div>
            <Field>
              <Label htmlFor="width">Width (pixels, min 240)</Label>
              <Input
                name="width"
                id="width"
                tabIndex={TAB_ORDER.INPUT}
                value={Number.isNaN(width) ? '' : width}
                onChange={e => {
                  setWidth(parseInt(e.target.value));
                }}
              />
            </Field>
            <Field>
              <Label htmlFor="visualization">Include visualization</Label>
              <RadioButtonGroup
                name="visualization"
                value={includeVisualization}
                options={{
                  y: 'Yes',
                  n: 'No',
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setIncludeVisualization(e.target.value as 'y' | 'n');
                }}
              />
            </Field>
            <Code
              onClick={(e: MouseEvent<HTMLDivElement>) => {
                // select text
                const selection = window.getSelection();
                const range = document.createRange();
                range.selectNodeContents(e.target as HTMLDivElement);
                selection?.removeAllRanges();
                selection?.addRange(range);

                // write to clipboard
                navigator.clipboard.writeText(embedCode);
              }}
              tabIndex={TAB_ORDER.INPUT}
              style={{ background: hsluvToHex([graph.baseHue, 80, 30]) }}
            >
              {embedCode}
            </Code>
            <Row>Click embed code to copy</Row>
          </div>
        </>
      )}
    </Layout>
  );
};
