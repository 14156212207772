export enum AudioNodeType {
  ADEnvelopeNode = 'ADEnvelopeNode',
  AnalyserNode = 'AnalyserNode',
  AudioBufferSourceNode = 'AudioBufferSourceNode',
  AudioDestinationNode = 'AudioDestinationNode',
  BiquadFilterNode = 'BiquadFilterNode',
  ChannelMergerNode = 'ChannelMergerNode',
  ChannelSplitterNode = 'ChannelSplitterNode',
  ConstantSourceNode = 'ConstantSourceNode',
  ConvolverNode = 'ConvolverNode',
  DelayNode = 'DelayNode',
  DynamicsCompressorNode = 'DynamicsCompressorNode',
  GainNode = 'GainNode',
  IIRFilterNode = 'IIRFilterNode',
  // MIDICCNode = 'MIDICCNode',
  // MIDIGateNode = 'MIDIGateNode',
  // MIDIInputNode = 'MIDIInputNode',
  OscillatorNode = 'OscillatorNode',
  PannerNode = 'PannerNode',
  PitchShifterNode = 'PitchShifterNode',
  QuantizerNode = 'QuantizerNode',
  RandomGateNode = 'RandomGateNode',
  ResonatorNode = 'ResonatorNode',
  ReverbNode = 'ReverbNode',
  RhythmNode = 'RhythmNode',
  SampleAndHoldNode = 'SampleAndHoldNode',
  SequenceNode = 'SequenceNode',
  StereoPannerNode = 'StereoPannerNode',
  TriggeredSamplerNode = 'TriggeredSamplerNode',
  WaveShaperNode = 'WaveShaperNode',
  WhiteNoiseNode = 'WhiteNoiseNode',
}

export type ADEnvelopeNodeParams = {
  readonly clock: number;
  readonly attack: number;
  readonly decay: number;
  readonly hold: boolean;
};
export type ADEnvelopeNodeConfig = {
  readonly type: AudioNodeType.ADEnvelopeNode;
  readonly params: ADEnvelopeNodeParams;
};

export type AnalyserNodeParams = {
  readonly fftSize: number;
  readonly minDecibels: number;
  readonly maxDecibels: number;
  readonly smoothingTimeConstant: number;
};
export type AnalyserNodeConfig = {
  readonly type: AudioNodeType.AnalyserNode;
  readonly params: AnalyserNodeParams;
};

export type AudioBufferSourceNodeParams = {
  readonly buffer: string | null;
  readonly loop: boolean;
  readonly loopStart: number;
  readonly loopEnd: number;
  readonly detune: number;
  readonly playbackRate: number;
};
export type AudioBufferSourceNodeConfig = {
  readonly type: AudioNodeType.AudioBufferSourceNode;
  readonly params: AudioBufferSourceNodeParams;
};

export type AudioDestinationNodeParams = {};
export type AudioDestinationNodeConfig = {
  readonly type: AudioNodeType.AudioDestinationNode;
  readonly params: AudioDestinationNodeParams;
};

export type BiquadFilterNodeParams = {
  readonly frequency: number;
  readonly detune: number;
  readonly Q: number;
  readonly gain: number;
  readonly type:
    | 'lowpass'
    | 'highpass'
    | 'bandpass'
    | 'lowshelf'
    | 'highshelf'
    | 'peaking'
    | 'notch'
    | 'allpass';
};
export type BiquadFilterNodeConfig = {
  readonly type: AudioNodeType.BiquadFilterNode;
  readonly params: BiquadFilterNodeParams;
};

export type ChannelMergerNodeParams = {};
export type ChannelMergerNodeConfig = {
  readonly type: AudioNodeType.ChannelMergerNode;
  readonly params: ChannelMergerNodeParams;
};

export type ChannelSplitterNodeParams = {};
export type ChannelSplitterNodeConfig = {
  readonly type: AudioNodeType.ChannelSplitterNode;
  readonly params: ChannelSplitterNodeParams;
};

export type ConstantSourceNodeParams = {
  readonly offset: number;
};
export type ConstantSourceNodeConfig = {
  readonly type: AudioNodeType.ConstantSourceNode;
  readonly params: ConstantSourceNodeParams;
};

export type ConvolverNodeParams = {
  readonly buffer: string | null;
  readonly normalize: boolean;
};
export type ConvolverNodeConfig = {
  readonly type: AudioNodeType.ConvolverNode;
  readonly params: ConvolverNodeParams;
};

export type DelayNodeParams = {
  readonly delayTime: number;
};
export type DelayNodeConfig = {
  readonly type: AudioNodeType.DelayNode;
  readonly params: DelayNodeParams;
};

export type DynamicsCompressorNodeParams = {
  readonly threshold: number;
  readonly knee: number;
  readonly ratio: number;
  readonly attack: number;
  readonly release: number;
};
export type DynamicsCompressorNodeConfig = {
  readonly type: AudioNodeType.DynamicsCompressorNode;
  readonly params: DynamicsCompressorNodeParams;
};

export type GainNodeParams = {
  readonly gain: number;
};
export type GainNodeConfig = {
  readonly type: AudioNodeType.GainNode;
  readonly params: GainNodeParams;
};

export type IIRFilterNodeParams = {};
export type IIRFilterNodeConfig = {
  readonly type: AudioNodeType.IIRFilterNode;
  readonly params: IIRFilterNodeParams;
};

// export type MIDICCNodeParams = { device: string; channel: number; cc: number };
// export type MIDICCNodeConfig = {
//   readonly type: AudioNodeType.MIDICCNode;
//   readonly params: MIDICCNodeParams;
// };

// export type MIDIGateNodeParams = {
//   device: string;
//   channel: number;
//   note: number;
// };
// export type MIDIGateNodeConfig = {
//   readonly type: AudioNodeType.MIDIGateNode;
//   readonly params: MIDIGateNodeParams;
// };

// export type MIDIInputNodeParams = {
//   device: string;
//   channel: number;
//   pitchBendRange: number;
// };
// export type MIDIInputNodeConfig = {
//   readonly type: AudioNodeType.MIDIInputNode;
//   readonly params: MIDIInputNodeParams;
// };

export type OscillatorNodeParams = {
  readonly frequency: number;
  readonly detune: number;
  readonly type: 'sine' | 'square' | 'sawtooth' | 'triangle' | 'custom';
};
export type OscillatorNodeConfig = {
  readonly type: AudioNodeType.OscillatorNode;
  readonly params: OscillatorNodeParams;
};

export type PannerNodeParams = {
  readonly coneInnerAngle: number;
  readonly coneOuterAngle: number;
  readonly coneOuterGain: number;
  readonly distanceModel: 'linear' | 'inverse' | 'exponential';
  readonly panningModel: 'equalpower' | 'HRTF';
  readonly maxDistance: number;
  readonly orientationX: number;
  readonly orientationY: number;
  readonly orientationZ: number;
  readonly positionX: number;
  readonly positionY: number;
  readonly positionZ: number;
  readonly refDistance: number;
  readonly rollOffFactor: number;
};
export type PannerNodeConfig = {
  readonly type: AudioNodeType.PannerNode;
  readonly params: PannerNodeParams;
};

export type PitchShifterNodeParams = {
  readonly detune: number;
};
export type PitchShifterNodeConfig = {
  readonly type: AudioNodeType.PitchShifterNode;
  readonly params: PitchShifterNodeParams;
};

export type QuantizerNodeParams = {
  readonly signal: number;
  readonly range: number;
  readonly values: number[];
};
export type QuantizerNodeConfig = {
  readonly type: AudioNodeType.QuantizerNode;
  readonly params: QuantizerNodeParams;
};

export type RandomGateNodeParams = {
  readonly clock: number;
  readonly probability: number;
};
export type RandomGateNodeConfig = {
  readonly type: AudioNodeType.RandomGateNode;
  readonly params: RandomGateNodeParams;
};

export type ResonatorNodeParams = {
  readonly frequency: number;
  readonly detune: number;
  readonly structure: number;
  readonly brightness: number;
  readonly damping: number;
  readonly position: number;
};
export type ResonatorNodeConfig = {
  readonly type: AudioNodeType.ResonatorNode;
  readonly params: ResonatorNodeParams;
};

export type ReverbNodeParams = {
  readonly diffusion: number;
  readonly lp: number;
  readonly reverbTime: number;
  readonly amount: number;
  readonly gain: number;
};
export type ReverbNodeConfig = {
  readonly type: AudioNodeType.ReverbNode;
  readonly params: ReverbNodeParams;
};

export type RhythmNodeParams = {
  readonly clock: number;
  readonly reset: number;
  readonly sequence: RhythmStep[];
};
export type RhythmNodeConfig = {
  readonly type: AudioNodeType.RhythmNode;
  readonly params: RhythmNodeParams;
};

export type SampleAndHoldNodeParams = {
  readonly signal: number;
  readonly clock: number;
};
export type SampleAndHoldNodeConfig = {
  readonly type: AudioNodeType.SampleAndHoldNode;
  readonly params: SampleAndHoldNodeParams;
};

export type SequenceNodeParams = {
  readonly clock: number;
  readonly reset: number;
  readonly sequence: number[];
};
export type SequenceNodeConfig = {
  readonly type: AudioNodeType.SequenceNode;
  readonly params: SequenceNodeParams;
};

export type StereoPannerNodeParams = {
  readonly pan: number;
};
export type StereoPannerNodeConfig = {
  readonly type: AudioNodeType.StereoPannerNode;
  readonly params: StereoPannerNodeParams;
};

export type TriggeredSamplerNodeParams = {
  readonly buffer: string | null;
  readonly clock: number;
  readonly detune: number;
};
export type TriggeredSamplerNodeConfig = {
  readonly type: AudioNodeType.TriggeredSamplerNode;
  readonly params: TriggeredSamplerNodeParams;
};

export type WaveShaperNodeParams = {
  readonly curve: number[];
  readonly oversample: 'none' | '2x' | '4x';
};
export type WaveShaperNodeConfig = {
  readonly type: AudioNodeType.WaveShaperNode;
  readonly params: WaveShaperNodeParams;
};

export type WhiteNoiseNodeParams = {};
export type WhiteNoiseNodeConfig = {
  readonly type: AudioNodeType.WhiteNoiseNode;
  readonly params: WhiteNoiseNodeParams;
};

export type AudioNodeConfig =
  | ADEnvelopeNodeConfig
  | AnalyserNodeConfig
  | AudioBufferSourceNodeConfig
  | AudioDestinationNodeConfig
  | BiquadFilterNodeConfig
  | ChannelMergerNodeConfig
  | ChannelSplitterNodeConfig
  | ConstantSourceNodeConfig
  | ConvolverNodeConfig
  | DelayNodeConfig
  | DynamicsCompressorNodeConfig
  | GainNodeConfig
  | IIRFilterNodeConfig
  // | MIDICCNodeConfig
  // | MIDIGateNodeConfig
  // | MIDIInputNodeConfig
  | OscillatorNodeConfig
  | PannerNodeConfig
  | PitchShifterNodeConfig
  | QuantizerNodeConfig
  | RandomGateNodeConfig
  | ResonatorNodeConfig
  | ReverbNodeConfig
  | RhythmNodeConfig
  | SampleAndHoldNodeConfig
  | SequenceNodeConfig
  | StereoPannerNodeConfig
  | TriggeredSamplerNodeConfig
  | WaveShaperNodeConfig
  | WhiteNoiseNodeConfig;

export const availableNodes: AudioNodeType[] = [
  AudioNodeType.ADEnvelopeNode,
  AudioNodeType.AudioBufferSourceNode,
  AudioNodeType.AudioDestinationNode,
  AudioNodeType.BiquadFilterNode,
  AudioNodeType.ChannelMergerNode,
  AudioNodeType.ChannelSplitterNode,
  AudioNodeType.ConstantSourceNode,
  AudioNodeType.ConvolverNode,
  AudioNodeType.DelayNode,
  AudioNodeType.DynamicsCompressorNode,
  AudioNodeType.GainNode,
  // AudioNodeType.MIDICCNode,
  // AudioNodeType.MIDIGateNode,
  // AudioNodeType.MIDIInputNode,
  AudioNodeType.OscillatorNode,
  AudioNodeType.PannerNode,
  // AudioNodeType.PitchShifterNode,
  AudioNodeType.QuantizerNode,
  // AudioNodeType.RandomGateNode,
  AudioNodeType.ResonatorNode,
  AudioNodeType.ReverbNode,
  AudioNodeType.RhythmNode,
  AudioNodeType.SampleAndHoldNode,
  AudioNodeType.SequenceNode,
  AudioNodeType.StereoPannerNode,
  AudioNodeType.TriggeredSamplerNode,
  AudioNodeType.WaveShaperNode,
  AudioNodeType.WhiteNoiseNode,
];

export enum RhythmStep {
  Off,
  On,
  Hold,
}
