import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Link } from './Link';
import { WalletLink } from './WalletLink';

const Container = styled.div`
  padding: 3em 0 0;
  margin: 0 0 3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  gap: 1em;
`;

const Hide = styled.div`
  @media screen and (max-width: 460px) {
    display: none;
  }
`;

export type HeaderProps = {
  className?: string;
};

export const Header: FC<HeaderProps> = ({ className }) => {
  return (
    <Container className={className}>
      <Link plain href="/">
        <img src="/logo.png" style={{ height: 24 }} />
      </Link>
      <Right>
        <Hide>
          <WalletLink />
        </Hide>
      </Right>
    </Container>
  );
};
