import React, { FC } from 'react';
import { ArrangementStep, Graph, RhythmStep } from '../../types';
import { hsluvToHex } from 'hsluv-ts';

export type ArrangementProps = {
  beat?: number;
  graph: Graph;
};

export const Arrangement: FC<ArrangementProps> = ({
  beat,
  graph: { arrangement, baseHue },
}) => {
  const length = arrangement.reduce(
    (sum: number, { duration }: ArrangementStep): number => duration + sum,
    0,
  );

  let currentSection: number | undefined;
  if (beat !== undefined) {
    currentSection = 0;
    let remaining =
      ((beat ?? 0) % length) - arrangement[currentSection].duration;
    while (remaining > 0) {
      currentSection++;
      remaining -= arrangement[currentSection].duration;
    }
  }

  const color = hsluvToHex([baseHue + 120, 20, 20]);
  const color2 = hsluvToHex([baseHue + 120, 20, 10]);

  return (
    <>
      <div
        style={{
          color,
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5em',
        }}
      >
        <div
          style={{
            width: 128,
            height: '0.5em',
            display: 'flex',
          }}
        >
          {arrangement.map(({ duration }: ArrangementStep, i: number) => (
            <div
              key={i}
              style={{
                flexBasis: duration,
                background: i === currentSection ? color : color2,
                position: 'relative',
              }}
            />
          ))}
        </div>
        <div>
          {arrangement[0].voices.map((_, i) => (
            <div
              key={i}
              style={{
                width: 128,
                display: 'flex',
              }}
            >
              {arrangement.map(
                ({ voices, duration }: ArrangementStep, j: number) => (
                  <div
                    key={j}
                    style={{
                      height: '0.5em',
                      flexBasis: duration,
                      background: voices[i] === RhythmStep.On ? color2 : 'none',
                    }}
                  />
                ),
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
