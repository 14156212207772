import styled from 'styled-components';

export const H2 = styled.h2`
  font-weight: 600;
  font-family: 'Mono', sans-serif;
  font-size: 2em;
  line-height: 1.25em;
  letter-spacing: 0.1em;
  color: var(--ui-control-color);
  max-width: 550px;
  user-select: text;
`;
