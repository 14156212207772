import { hsluvToHex } from 'hsluv-ts';
import styled from 'styled-components';

export type ButtonProps = {
  baseHue?: number;
};

export const Button = styled.button`
  display: block;
  width: 128px;
  color: ${({ baseHue = 0 }: ButtonProps) => hsluvToHex([baseHue + 60, 70, 5])};
  background: ${({ baseHue = 0 }: ButtonProps) =>
    hsluvToHex([baseHue + 120, 70, 30])};
  border: none;
  padding: 0.25em 0.5em;
  text-align: left;
  hyphens: auto;
  overflow-wrap: anywhere;
  position: relative;
  user-select: none;

  &:hover,
  &:active {
    background: ${({ baseHue = 0 }: ButtonProps) =>
      hsluvToHex([baseHue + 120, 70, 40])};
  }
`;
