import React, { FC, useRef, useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { randomGraph } from '../../randomGraph';
import {
  FullscreenButton,
  Illustration,
  PlayButton,
  ButtonPrimary,
  Layout,
  Link,
} from '../../components';
import { GraphThumbnail } from '../player/GraphThumbnail';
import { CurrentBeat } from '../player/CurrentBeat';
import { AudioGraph } from '../../audioGraph';
import { Positions } from '../player/Positions';

const { detect } = require('detect-browser');
const browser = detect();

const supportsLocalSynthesis = !(
  browser.name.match(/firefox/i) || browser.os.match(/ios|android|chromeos/i)
);

export type SeedExplorerProps = {
  path: string[];
};

const Main = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 3fr 0.5fr;
  position: relative;
  gap: 3em;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 3fr 1fr;
    align-items: stretch;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    align-items: stretch;
  }
`;

const Controls = styled.div`
  height: 2.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const PlayControls = styled.div`
  display: flex;
  gap: 1em;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(256px + 2em);
  gap: 2em;
`;

const LeftInfo = styled(Info)`
  @media screen and (max-width: 1000px) {
    order: 2;
  }
`;

const RightInfo = styled(Info)`
  @media screen and (max-width: 600px) {
    order: 1;
    flex-direction: row;
    justify-content: space-between;
    max-width: none;
  }
`;

const ThumbnailContainer = styled.div`
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RelativeContainer = styled.div`
  position: relative;
  aspect-ratio: 1;
  flex: 1;
  padding-bottom: 2.5em;
  box-sizing: content-box;
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const SeedExplorer: FC<SeedExplorerProps> = ({
  path: [, seedString],
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [ready, setReady] = useState(false);

  const seed = parseInt(seedString);
  const tokenId = (seed % 9000) + 1;

  const graph = useMemo(() => randomGraph(seed, tokenId), [seed, tokenId]);
  const audioGraph = useMemo(() => new AudioGraph(graph, undefined), [graph]);

  useEffect(() => {
    if (fullscreen) {
      const exitHandler = () => {
        if (document.fullscreenElement === null) setFullscreen(false);
      };
      document.addEventListener('fullscreenchange', exitHandler);
      return () => {
        document.removeEventListener('fullscreenchange', exitHandler);
      };
    }
    return undefined;
  }, [fullscreen]);

  const audioFallback = useMemo(() => {
    let audio;
    if (!supportsLocalSynthesis) {
      audio = new Audio();
      // audio.src = `/preview/${previewIndex}.m4a`;
      audio.loop = true;
      audio.preload = 'auto';
    }
    return audio;
  }, []);
  useEffect(() => () => audioFallback?.pause(), []);

  const context = audioGraph.context as AudioContext;
  const play = () => {
    if (supportsLocalSynthesis) {
      context.resume();
    } else {
      audioFallback?.play();
    }
    setPlaying(true);
  };
  const pause = () => {
    if (supportsLocalSynthesis) {
      context.suspend();
    } else {
      audioFallback?.pause();
    }
    setPlaying(false);
  };

  // play / pause when spacebar is pressed
  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        e.stopPropagation();
        e.preventDefault();
        playing ? pause() : play();
      }
    };
    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  }, []);

  // track when graph is ready, clean up when page is left
  useEffect(() => {
    audioGraph.ready.then(() => {
      audioGraph.update(graph);
      setReady(true);
    });
    return () => {
      audioGraph.close();
    };
  }, [audioGraph]);

  return (
    <Layout
      title={graph.name}
      breadcrumbs={[
        ['Voyager', null],
        [`${graph.name}`, `/seed/${seed}`],
      ]}
      loading={!ready}
    >
      {!supportsLocalSynthesis && (
        <div style={{ maxWidth: 400 }}>
          <p>
            Synthopia synthesizes music live in your browser using processor
            intensive DSP algorithms. To ensure the best quality, please use
            Chrome on a desktop computer.
          </p>
          <p>
            After mint, playback in all browsers will be supported using
            pre-rendered audio.
          </p>
          <p>
            You can play a demo version with pre-rendered audio{' '}
            <Link href="/prerendered">here</Link>
          </p>
        </div>
      )}
      {ready && supportsLocalSynthesis && (
        <Main>
          <LeftInfo>
            {supportsLocalSynthesis && (
              <Positions
                audioGraph={audioGraph}
                playing={playing}
                baseHue={graph.baseHue}
              />
            )}
            <div style={{ color: '#666' }}>
              <p>
                This is a preview of Synthopia using a seed that will never be
                minted.
              </p>
              <p>
                Synthopia synthesizes music live in your browser using processor
                intensive DSP algorithms. If you are experiencing issues with
                audio, you can listen to a version with pre-rendered audio{' '}
                <Link href="/prerendered">here</Link>.
              </p>
            </div>
            <a
              href={`/seed/${Math.floor(
                Math.random() * Number.MAX_SAFE_INTEGER,
              )}`}
              style={{ textDecoration: 'none' }}
            >
              <ButtonPrimary baseHue={graph.baseHue}>Randomize</ButtonPrimary>
            </a>
            <Link href={`/seed/${seed}/download`}>download</Link>
          </LeftInfo>
          <Center ref={ref}>
            <RelativeContainer style={{ width: fullscreen ? 'auto' : '100%' }}>
              <AbsoluteContainer>
                <Illustration
                  graph={graph}
                  audioGraph={audioGraph}
                  audioElement={supportsLocalSynthesis ? null : audioFallback}
                  onClick={playing ? pause : play}
                />
              </AbsoluteContainer>
              <Controls>
                <PlayControls>
                  <PlayButton
                    playing={playing}
                    toggle={playing ? pause : play}
                    baseHue={graph.baseHue}
                  />
                  <div>{graph.name}</div>
                </PlayControls>
                <FullscreenButton
                  fullscreen={fullscreen}
                  toggle={() => {
                    if (fullscreen) {
                      document.exitFullscreen();
                      setFullscreen(false);
                    } else {
                      ref.current?.requestFullscreen();
                      setFullscreen(true);
                    }
                  }}
                />
              </Controls>
            </RelativeContainer>
          </Center>
          <RightInfo>
            <ThumbnailContainer>
              <GraphThumbnail
                audioGraph={audioGraph}
                graph={graph}
                width={128}
              />
            </ThumbnailContainer>
            <CurrentBeat audioGraph={audioGraph} />
          </RightInfo>
        </Main>
      )}
    </Layout>
  );
};
