import React, { FC } from 'react';
import styled from 'styled-components';
import {
  Button,
  GridLayout,
  Layout,
  Link,
  NetworkActivityIndicator,
  P,
  Stack,
  Center,
} from '../../components';
import { repeat, shortAddress, translateSeed } from '../../utils';
import { useAPIData } from '../../hooks';
import { Metadata } from '../../types';
import { randomGraph } from '../../randomGraph';
import { Image } from '../gallery/Image';

const Container = styled.div`
  padding: 1em 2em 8em;
  overflow-y: auto;
`;

const IllustrationContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  color: #999;
`;

export type WalletProps = {
  path: string[];
};

export const Wallet: FC<WalletProps> = ({ path: [, addressString] }) => {
  const page = parseInt(addressString.split('?page=')[1] ?? 1);
  const address = addressString.split('?')[0];

  const [loading, error, data] = useAPIData<{
    numberOfPages: number;
    results: Metadata[];
  }>(`wallet/${address}?page=${page}`, [address, page]);

  return (
    <Layout
      title={shortAddress(address)}
      breadcrumbs={[
        ['WALLET', null],
        [shortAddress(address), `/wallet/${address}`],
      ]}
      loading={loading}
      error={!!error}
    >
      {data && (
        <GridLayout minSize={20} space={3}>
          {data.results?.map(metadata => {
            let image;
            let name;
            if ((metadata.seed as any) === '') {
              image = (
                <img src="/pre-reveal.png" style={{ aspectRatio: '1' }} />
              );
              name = 'Unrevealed';
            } else {
              const graph = randomGraph(
                translateSeed(metadata.seed),
                metadata.tokenId,
              );
              image = <Image graph={graph} />;
              name = graph.name;
            }
            return (
              <IllustrationContainer
                plain
                href={`/gallery/${metadata.tokenId}`}
                key={metadata.tokenId}
                disabled={(metadata.seed as any) === ''}
              >
                {image}
                {`#${metadata.tokenId}`} {name}
              </IllustrationContainer>
            );
          })}
          <Stack>
            {data.numberOfPages > 1 && page > 1 && (
              <div>
                <Link plain href={`/wallet/${address}?page=${page - 1}`}>
                  <Button>Previous Page</Button>
                </Link>
              </div>
            )}
            {data.numberOfPages > page && (
              <div>
                <Link plain href={`/wallet/${address}?page=${page + 1}`}>
                  <Button>Next Page</Button>
                </Link>
              </div>
            )}
            <P>
              Page {page} / {data.numberOfPages ?? '...'}
            </P>
          </Stack>
        </GridLayout>
      )}
    </Layout>
  );
};
