import styled from 'styled-components';

export const HR = styled.hr`
  border: 0;
  border-top: solid 1px rgba(255, 255, 255, 0.2);
  position: relative;
  overflow: visible;
  margin: 6em 0;

  &:after {
    content: '';
    border-top: solid 1px rgba(255, 255, 255, 0.2);
    width: 4em;
    position: absolute;
    right: 0;
    top: -1px;
  }
`;
