import React, { FC } from 'react';
import styled from 'styled-components';
import { Graph, ArrangementStep, RhythmStep } from '../../types';

export type TracksProps = {
  beat: number;
  graph: Graph;
};

const stems = [
  'kick',
  'sub',
  'stab',
  'snare',
  'mod',
  'string',
  'rhodes',
  'piano',
  'pad',
  'sequence',
  'alto',
  'soprano',
  'texture',
  'sweep',
  'intro',
];

const Container = styled.div`
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const Tracks: FC<TracksProps> = ({
  beat,
  graph,
  graph: { arrangement },
}) => {
  const length = arrangement.reduce(
    (sum: number, { duration }: ArrangementStep): number => duration + sum,
    0,
  );

  let currentSection = 0;
  let remaining = (beat % length) - arrangement[currentSection].duration;
  while (remaining > 0) {
    currentSection++;
    remaining -= arrangement[currentSection].duration;
  }

  return (
    <Container>
      {stems.map((name, i) => (
        <div
          key={name}
          style={{
            color:
              arrangement[currentSection].voices[i] === RhythmStep.On
                ? '#999'
                : '#333',
          }}
        >
          {name}
        </div>
      ))}
    </Container>
  );
};
