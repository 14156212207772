import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { H2 } from './H2';
import { SectionsLayout } from './SectionsLayout';

const HeaderLink = styled.a`
  text-decoration: none;
  color: white;
`;

export type SectionsContentLayoutProps = {
  title: string;
  path: string;
  sections: {
    id: string;
    title: string;
    content: ReactNode;
  }[];
  before?: ReactNode;
  after?: ReactNode;
};

export const SectionsContentLayout: FC<SectionsContentLayoutProps> = ({
  title,
  sections,
  before,
  after,
}) => {
  return (
    <SectionsLayout
      title={title}
      sections={sections.map(({ id, title, content }, i) => ({
        id,
        title,
        content: (
          <>
            <HeaderLink href={`#${id}`}>
              <H2 style={{ marginTop: 0 }}>{title}</H2>
            </HeaderLink>
            {content}
          </>
        ),
      }))}
      before={before}
      after={after}
    />
  );
};
