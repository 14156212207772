import React, { FC, useEffect } from 'react';
import { GlobalStyle } from './GlobalStyle';
import {
  Home,
  Player,
  FAQ,
  Terms,
  Gallery,
  Wallet,
  Download,
  Embed,
  Prerender,
  DownloadSeed,
} from '../pages';
import { AudioGraph } from '../audioGraph';
import { Graph, Metadata } from '../types';
import { Router } from '.';
import { NotFoundPage } from './NotFoundPage';
import { Web3Provider } from './Web3Provider';
import { SeedExplorer } from '../pages/seedExplorer';
import { PrerenderPlayer } from '../pages/prerenderPlayer';

export type RootProps = {};

export type RootState = {
  audioGraph: AudioGraph;
  graph: Graph;
  metadata: Metadata;
};

export const Root: FC<RootProps> = () => {
  return (
    <>
      <GlobalStyle />
      <Web3Provider>
        <Router
          routes={[
            [/^\/$/, Home],
            [/^\/faq$/, FAQ],
            [/^\/terms$/, Terms],
            [/^\/prerendered$/, Prerender],
            [/^\/seed\/\d{1,99}$/, SeedExplorer],
            [/^\/seed\/\d{1,99}\/download$/, DownloadSeed],
            [/^\/gallery(\?page=[0-9]+)?$/, Gallery],
            [/^\/gallery\/\d{1,5}$/, Player],
            [/^\/gallery\/\d{1,5}\/prerendered$/, PrerenderPlayer],
            [/^\/gallery\/\d{1,5}\/download$/, Download],
            [/^\/gallery\/\d{1,5}\/embed$/, Embed],
            [/^\/wallet\/0[xX][0-9a-fA-F]+$/, Wallet],
          ]}
          NotFound={NotFoundPage}
        />
      </Web3Provider>
    </>
  );
};
