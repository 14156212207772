import { mulberry32 } from './mulberry32';
import { shuffle } from './shuffle';

const seed = 96399639;
const rand = mulberry32(seed);

const prefixes = `
AI
Altered
Anarcho
Arco
Asteroid
Astro
Augmented
Auto
Autonomous
Binary
Carbon
Cloud
Compromised
Computer
Corporate
Crypto
Crystal
Cyber
Dark
Deep
Democratic Republic of
Digi
Digital
Distributed
Dream
DX
Electric
Electro
Electronic
Emerald
Encoded
Encrypted
Energy
Floating
Free
Future
Galactic
Genetic
Geno
Ghost
Giga
Hacked
Hidden
Hyper
New Jack
Jupiter
Laser
Liberated
Light
LED
Lunar
Martian
Mega
Mercury
Micro
Modified
Nano
Neo
Neon
Neptune
Net
Neu
Neural
New
Night
Nouveau
Nova
Novo
Nuclear
Nuevo
Occupied
Other
People's Republic of
Phantom
Phase
Pico
Pluto
Post
Pseudo
Rebel
Robo
Robot
Satellite
Saturn
Silicon
Sim
Sky
Socialist
Solar
Sovereign
Space
Spectral
Sponsored
Star
Subterranean
Sunrise
Sunset
Super
Techno
Terra
Titan
Twilight
Ultra
Underground
Universal
Utopian
Venusian
Virtual
Video
Web
`
  .split('\n')
  .slice(1, -1);

const suffixes = `
2000
33
3D
4D
666
99
Arcology
Area
Autonomous Zone
Base
Basin
Beacon
Belt
Block
Breach
Bridge
Cable
Chain
Cloud
Colony
Contact
Container
Corporation
CPU
Crater
Cube
DAO
Depot
Dimension
District
DLL
Domain
Dome
Drive
Enclave
Enterprise
ESO
Exclusion Zone
Extension
Factory
Field
Frame
Franchise
Gate
Habitat
Image
Implant
Interface
Labratories
Layer
Level
Link
Megacity
Megapolis
Mirage
Mission
Model
Module
Moon
Network
Nine
Orbiter
Outpost
Plant
Plug
Port
Portal
Prime
Reach
Reactor
Reality
Rift
Ring
Router
Scale
Scan
Scope
Section
Sector
Sequence
Server
Service
Simulation
Six
Space
Special Adminstrative Region
Special Economic Zone
Sphere
Sprawl
SSR
Stack
Stage
Station
Subnet
Subsection
System
Terminal
Terminus
Three
Towers
Tube
Tunnel
Universe
Upload
Visitation
X
Zaibatsu
Zone
`
  .split('\n')
  .slice(1, -1);

const cities = shuffle(
  `
Abidjan
Addis Ababa
Ahmedabad
Alexandria
Amsterdam
Anchorage
Ankara
Asunción
Athens
Atlanta
Auckland
Austin
Babylon
Baghdad
Baltimore
Bandung
Bangalore
Bangkok
Barcelona
Basel
Beijing
Beirut
Berlin
Bogota
Bombay
Brasilia
Brickell
Brisbane
Bronx
Brooklyn
Brussels
Bucurest
Budapest
Buenos Aires
Cahokia
Cairo
Calcutta
Cali
Cape Town
Caracas
Casablanca
Chelyabinsk
Changchun
Chengdu
Chernobyl
Chiba
Chicago
Chinatown
Chongqing
Colombo
Compton
Copenhagen
Cusco
Dalian
Dallas
Delhi
Denver
Detroit
Dhaka
Dresden
Dubai
Dublin
Edinburgh
Faisalabad
Fortaleza
Gaza
Geneva
Giza
Gothenburg
Guangzhou
Guayaquil
Harbin
Hanoi
Harlem
Harmony
Havana
Helsinki
Ho Chi Minh
Hollywood
Hong Kong
Houston
Hyderabad
Ilium
Inchon
Istanbul
Izmir
Izola
Jakarta
Jeruselem
Johannesburg
Jinan
Juarez
Juneau
Kabul
Kanpur
Kansas
Karachi
Kolkata
Kreuzberg
Kuwait
Kyiv
Kingston
Kinshasa
Koper
Koreatown
Lagos
Lahore
Lima
Ljubljana
London
Long Beach
Los Angeles
Lyon
Macao
Madras
Madrid
Makati
Malacca
Malang
Manila
Marrakesh
Marfa
Marseille
Mashhad
Mecca
Medan
Medellín
Melbourne
Memphis
Mexicali
Mexico
Miami
Milan
Minsk
Mogadishu
Mombasa
Monaco
Monrovia
Monte Carlo
Montreal
Moskva
Mumbai
Munich
Nagoya
Nairobi
Nanjing
New Orleans
New York
Oakland
Odessa
Osaka
Paris
Phnom Penh
Phoenix
Phuket
Piran
Portland
Prague
Pusan
Pyongyang
Qingdao
Quito
Quezon
Riga
Rio de Janeiro
Riverside
Roma
Roswell
Salvador
San Diego
San Francisco
San Jose
San Salvador
Santiago
Seattle
Semarang
Seoul
Shanghai
Shenyang
Shenzen
Sibirsk
Singapore
Sitka
Soma
St Louis
St Petersburg
Stockholm
Surabaya
Sydney
São Paulo
Taegu
Taipei
Taiyuan
Tallinn
Tehran
Tenochtitlan
Tel Aviv
Tianjin
Tijuana
Tokyo
Toronto
Tunguska
Ulaanbaatar
Vancouver
Venice
Vegas
Warsaw
Washington
Williamsburg
Wuhan
Xian
Yangon
Yerevan
Yokohama
Yuma
Zibo
Zion
Zurich
`
    .split('\n')
    .slice(1, -1),
  rand,
);

const modifiers = shuffle(
  prefixes
    .map(v => ({
      pre: true,
      v,
    }))
    .concat(
      suffixes.map(v => ({
        pre: false,
        v,
      })),
    ),
  rand,
);

export const randomName = (tokenId: number) => {
  if (modifiers.length < cities.length)
    throw new Error(
      `more cities ${cities.length} than modifiers ${modifiers.length}`,
    );

  const offset = Math.floor(tokenId / cities.length);

  const city = cities[tokenId % cities.length];
  const modifier = modifiers[(tokenId + offset) % cities.length];
  return modifier.pre ? `${modifier.v} ${city}` : `${city} ${modifier.v}`;
};
