import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BlockingOverlay, GridLayout, Layout } from '../../components';
import { randomGraph } from '../../randomGraph';
import { downloadAudioFile } from '../../audioGraph';
import { Label } from '../../components/Label';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { useAPIData } from '../../hooks';
import { translateSeed } from '../../utils';
import { Metadata } from '../../types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  max-width: 700px;
`;
const Field = styled.div`
  margin: 1em 0;
  width: 196px;
`;
const SubmitButton = styled(Button)`
  width: 196px;
`;

export type DownloadProps = {
  path: string[];
};

const finalNodes = {
  kick: 'pcVOI0mGUb8l',
  stab: 'mpcQH4RagLhr',
  snare: 'EsOVeDZrSxHE',
  string: '66HRMlChBbT1',
  rhodes: 'nkB2gAX8vNlY',
  piano: 'Y8ldcF0pWe98',
  pads: 'pKLMx9fGoV63',
  sequence: 'p3rWWomwWtQi',
  vox: '53IffwXWFmT8',
  fx: '0wv3LbOYfx2r',
  sweep: 'itgdSvP1SHnn',
  intro: 'Ddc8EOj4wigF',
};

const numberOfChannels = 2;
const sampleRate = 44100;
const bitDepth = 16;
const fadeIn = 0;
const fadeOut = 8;

export const Download: FC<DownloadProps> = ({ path: [, tokenIdString] }) => {
  const tokenId = parseInt(tokenIdString);
  const [renderActive, setRenderActive] = useState(false);
  const [cycleCount, setCycleCount] = useState(1);
  const [progress, setProgress] = useState(0);

  const [loading, error, data] = useAPIData<Metadata>(`metadata/${tokenId}`, [
    tokenId,
  ]);
  const seed = data && data.seed ? translateSeed(data.seed) : null;
  const graph = useMemo(() => (seed ? randomGraph(seed, tokenId) : null), [
    seed,
    tokenId,
  ]);

  const duration =
    graph !== null
      ? (Number.isNaN(cycleCount) ? 0 : cycleCount) * graph.cycleDuration +
        fadeOut
      : 0;

  const download = async (stemName?: string) => {
    if (renderActive || seed === null) return;

    const graph = randomGraph(seed, tokenId);

    const nodes = graph.nodes as any;

    // if stem name is provided, mute all other tracks
    if (stemName) {
      Object.entries(finalNodes).forEach(([name, id]) => {
        if (name !== stemName) {
          nodes[id].audioNode.params.gain = 0;
        }
      });
    }

    setProgress(0);
    setRenderActive(true);

    await downloadAudioFile(
      graph,
      numberOfChannels,
      sampleRate,
      bitDepth,
      duration,
      fadeIn,
      fadeOut,
      stemName ? `${graph.name} - ${stemName}` : graph.name,
      setProgress,
    );

    setRenderActive(false);
  };

  return loading || error || graph === null ? (
    <Layout title="" breadcrumbs={[]} loading={loading} error={!!error} />
  ) : (
    <Layout
      title={`${graph.name} Downloads`}
      breadcrumbs={[
        ['GALLERY', '/gallery'],
        [`#${tokenId} ${graph.name}`, `/gallery/${tokenId}`],
        ['Download', `/gallery/${tokenId}/download`],
      ]}
    >
      <Container>
        <div>
          <p>
            Holders of Synthopia tokens have full commercial rights to their
            version.
          </p>
          <p>
            When using this download feature, audio files are rendered locally
            and can be saved to your hard drive.
          </p>
          <p>
            Each synthopia token has a generated repeating arrangement. Because
            much of the modulation runs freely each repetition will be subtly
            different. You can use the cycle count input below to control how
            many iterations of the arrangement to render.
          </p>
          <Field>
            <Label htmlFor="cycleCount">Cycle count</Label>
            <Input
              disabled={loading}
              name="cycleCount"
              id="cycleCount"
              value={Number.isNaN(cycleCount) ? '' : cycleCount}
              onChange={e => {
                setCycleCount(parseInt(e.target.value));
              }}
            />
          </Field>
          <div style={{ marginTop: '1em' }}>
            <p>
              Downloads will be wave files, approx. {duration.toFixed(0)}{' '}
              seconds and{' '}
              {parseFloat(((2 * 44100 * 16 * duration) / 8000000).toFixed(2)) ||
                0}
              MB
            </p>
          </div>
        </div>
        <div>
          <h2 style={{ marginBottom: '2rem' }}>Download Song</h2>
          <SubmitButton
            onClick={() => {
              download();
            }}
          >
            Download
          </SubmitButton>
        </div>
        <div>
          <h2>Download Stems</h2>
          <p style={{ marginBottom: '2rem' }}>
            Stems are audio files of the individual tracks that make up this
            version of Synthopia, they can be used for editing or remixing.
          </p>
          <GridLayout>
            {Object.keys(finalNodes).map(name => (
              <SubmitButton
                onClick={() => {
                  download(name);
                }}
              >
                Download {name}
              </SubmitButton>
            ))}
          </GridLayout>
        </div>
      </Container>
      {renderActive && (
        <BlockingOverlay
          error={null}
          text={`${(progress * 100).toFixed(0)}%`}
        />
      )}
    </Layout>
  );
};
