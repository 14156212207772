import { hsluvToHex } from 'hsluv-ts';
import React, { FC } from 'react';
import styled from 'styled-components';

export type PlayButtonProps = {
  playing: boolean;
  toggle?: () => void;
  size?: number;
  baseHue?: number;
};

type SVGProps = {
  baseHue: number;
};

const SVG = styled.svg`
  rect,
  path {
    fill: ${({ baseHue = 0 }: SVGProps) => hsluvToHex([baseHue + 120, 70, 30])};
  }
  &:hover rect,
  &:hover path {
    fill: ${({ baseHue = 0 }: SVGProps) => hsluvToHex([baseHue + 120, 70, 40])};
  }
`;

export const PlayButton: FC<PlayButtonProps> = ({
  size = 16,
  playing,
  toggle,
  baseHue = 0,
}) => (
  <SVG
    tabIndex={1}
    onClick={toggle}
    onKeyDown={
      toggle &&
      (e => {
        if (e.key === 'Enter') toggle();
      })
    }
    width={size}
    height={size}
    viewBox="0 0 1 1"
    baseHue={baseHue}
  >
    <title>{playing ? 'pause' : 'play'}</title>
    {playing ? (
      <>
        <rect x={0} y={0.05} width={0.35} height={0.9} />
        <rect x={0.65} y={0.05} width={0.35} height={0.9} />
      </>
    ) : (
      <path d="m 0 0 L 1 0.5 L 0 1 L 0 0" />
    )}
  </SVG>
);
