import {
  ArrangementStep,
  Graph,
  Node,
  Override,
  RhythmStep,
  Trait,
  Vector,
} from './types';
import { baseGraph } from './baseGraph';
import { shuffle } from './utils/shuffle';
import { repeat } from './utils/repeat';
import { randomName } from './utils/randomName';
import { mulberry32 } from './utils/mulberry32';

const applyOverrides = (graph: Graph, overrides: Override[]): Graph => {
  return {
    ...graph,
    nodes: overrides.reduce((memo, [, nodeId, paramName, value]) => {
      const node = memo[nodeId];
      if (node === undefined) throw new Error(`can't find node ${nodeId}`);
      return {
        ...memo,
        [nodeId]: {
          ...memo[nodeId],
          audioNode: {
            ...memo[nodeId].audioNode,
            params: {
              ...memo[nodeId].audioNode.params,
              [paramName]: value,
            },
          } as any,
        },
      };
    }, graph.nodes),
  };
};

const randomizeNodePositions = (graph: Graph, seed: number): Graph => {
  const rand = mulberry32(seed);
  const randomizedPosition = ([x, y]: Vector): Vector => [
    x + Math.floor(rand() * 241) - 120,
    y + Math.floor(rand() * 241) - 120,
  ];

  return {
    ...graph,
    nodes: Object.entries(graph.nodes).reduce(
      (memo: { [id: string]: Node }, [id, node]) => {
        memo[id] = {
          ...node,
          data: {
            ...node.data,
            position: randomizedPosition(node.data.position),
          },
        };
        return memo;
      },
      {},
    ),
  };
};

// const formatRhythmTraitValue = (v: number[]) => {
//   const vv = v.join('');
//   let result = vv.slice(0, 16);
//   for (let i = 16; i < v.length; i += 16) {
//     result += `\n${vv.slice(i, i + 16)}`;
//   }
//   return result;
// };

export const randomOverrides = (
  seed: number,
): {
  overrides: Override[];
  traits: Trait[];
  arrangement: ArrangementStep[];
  clock: number;
} => {
  const rand = mulberry32(seed);
  const range = (v: number, min: number, max: number): number =>
    min + v * (max - min);
  const random = (min: number, max: number, pow: number = 1) =>
    range(Math.pow(rand(), pow), min, max);
  const randomInt = (min: number, max: number, pow: number = 1): number =>
    Math.floor(random(min, max + 1, pow));
  const randomMember = (items: any[], pow: number = 1) =>
    items[randomInt(0, items.length - 1, pow)];

  const rtm = ([s]: TemplateStringsArray): RhythmStep[] =>
    s.split('').map(c => (c === 'x' ? RhythmStep.On : RhythmStep.Off));
  const mel = ([s]: TemplateStringsArray): number[] =>
    s.split(' ').map(v => parseInt(v) * 100);
  // const seq = ([s]: TemplateStringsArray): number[] =>
  //   s.split(' ').map(v => parseInt(v));
  const fill = (duration: number, rhythm: RhythmStep[]): RhythmStep[] => {
    const result = new Array(duration);
    for (let i = 0; i < duration; i++) {
      result[i] = rhythm[i % rhythm.length];
    }
    return result;
  };
  const zero = (duration: number): RhythmStep[] =>
    repeat(duration, () => RhythmStep.Off);
  const one = (duration: number): RhythmStep[] =>
    [RhythmStep.Off, RhythmStep.On].concat(zero(duration - 2));

  const blue = Math.pow(rand(), 0.8);
  const green = 1 - blue;

  const clock = range(blue, 3, 10);

  const basePitch = randomInt(-1200, 1200);

  const stabPitch = basePitch + randomInt(0, 2) * 1200;
  const subPitch = basePitch % 1200;
  const padPitch = basePitch + randomInt(0, 1) * 1200;
  const pad1Pitch = padPitch - 700; // + randomMember([-700, -400, -200, 0, 100, 300, 500]);
  const pad3Pitch = padPitch; //+ randomMember([0, 300, 500, 700, 800, 1000]);
  const vox1Pitch =
    basePitch +
    randomInt(-2, 1) * 1200 +
    randomMember(mel`0 2 3 4 5 7 8 10 -2 -4 -5 -7 -9 -10 -12`);
  const vox2Pitch =
    basePitch +
    randomInt(-2, 1) * 1200 +
    randomMember(mel`0 2 3 4 5 7 8 10 -2 -4 -5 -7 -9 -10 -12`);
  const pianoPitch =
    basePitch + randomInt(-2, 2) * 1200 + randomMember(mel`0 2 3 5 7 10`);
  const rhodesPitch = basePitch + randomInt(-1, 1) * 1200;
  const rhodesDPitch = rhodesPitch; //+ randomMember(mel`0 -2`);
  const rhodesEPitch = rhodesPitch + randomMember(mel`0 0 -4 8`); //+ randomMember(mel`0 1 3 5 6 8 10 -2 -4 -6 -7 -9 -11 -12`);
  const rhodesFPitch = rhodesPitch; //randomMember(mel`0 2`);
  const rhodesAPitch = rhodesPitch; //+ randomMember(mel`0 1 3 5 7 8 10 -2 -4 -5 -7 -9 -11 -12`);
  const sequencePitch = basePitch + randomInt(-2, 0) * 1200;
  const sweepPitch = basePitch + 3600;

  const seqOktava = new Array(randomInt(5, 32))
    .fill(0)
    .map(() => randomMember([0, 0, 0, 1200, -1200]));
  const baseSeqOsc = mel`5 0 8 7 0 7 0 0 0 0 0 0 0 0 0 0`;
  const seqOsc = rand() > 0.3 ? shuffle(baseSeqOsc, rand) : baseSeqOsc;

  // const padBassSeq = shuffle(mel`5 3 0 -2 5 -5 -4 -2`, rand);
  // const padSeq = shuffle(mel`-2 -4 -5 -4 0`, rand);

  const [rtmKick, rtmSub, rtmSnare, rtmStab] = randomMember([
    [
      rtm`x...............................`, // kick
      rtm`x...............................`, // sub
      rtm`........x...............x.......`, // snare
      rtm`..x.............................`, // stab
    ],
    [
      rtm`x....................x..........`, // kick
      rtm`x....................x..........`, // sub
      rtm`........x...............x.......`, // snare
      rtm`................................`, // stab
    ],
    [
      rtm`x.......x.......x.......x.......`,
      rtm`x...............x...............`,
      rtm`........x...............x.......`,
      rtm`..x.............................`,
    ],
    [
      rtm`x.....x.....x.....x...x.....x...`,
      rtm`x.................x.............`,
      rtm`................x...............`,
      rtm`............................x...`,
    ],
    [
      rtm`x...............................`,
      rtm`x...................x...........`,
      rtm`................x...............`,
      rtm`..............................x.`,
    ],
    [
      rtm`x..x..x..x..x..xx..x..x..x..x.x.`,
      rtm`x...............................`,
      rtm`................x...............`,
      rtm`..................x.............`,
    ],
  ]);

  const [rtmVox1, rtmVox2] = randomMember([
    [
      rtm`x.x.............................`,
      rtm`..x.............................................................`,
    ],
    [
      rtm`......x...............x...............x.........................`,
      rtm`................................x...............................`,
    ],
    [
      rtm`..x......x.....x...................x.....x.....x...........x....`,
      rtm`......................................x.........................`,
    ],
    [
      rtm`........x.......................`,
      rtm`..............................................................x.`,
    ],
  ]);

  const rtmSequence = randomMember([
    rtm`x`,
    rtm`x`,
    rtm`x`,
    rtm`x`,
    rtm`x`,
    rtm`..xxxx..x..xxxx.`,
    rtm`........x.......`,
    rtm`xx.xx.xxxx.xxxxx`,
    rtm`x.`,
    rtm`x..`,
    rtm`x..`,
    rtm`x....`,
    rtm`....x.........x.`,
    rtm`.............x..`,
    rtm`..x..x..x..x..x.`,
    rtm`x.xxx.xx`,
    rtm`x..x..x..x..x..x`,
    rtm`x..x..x..x..x..x`,
    rtm`x...............`,
  ]);

  const rtmModSnare = rtm`.....................x..........................`;
  const rtmRhodes = repeat(128, () => RhythmStep.Off);
  rtmRhodes[16] = RhythmStep.On;
  const rtmPiano = repeat(256, () => RhythmStep.Off);
  rtmPiano[31] = RhythmStep.On;

  const arrangement = [
    rand() > 0
      ? { voices: rtm`..............x`, duration: 32 }
      : { voices: rtm`...............`, duration: Math.floor(0.2 * clock) },
  ].concat(
    repeat(randomInt(5, 11), () => ({
      // kick, sub, stab, snare, snare fx, pad1, rhodes, piano, pads, sequence, vox1, vox2, fx, sweep, intro
      voices: randomMember([
        rtm`........x...x..`,
        rtm`......x.x....x.`,
        rtm`xxxxxxx.x.xxx..`,
        rtm`xxxxx.xx..xx.x.`,
        rtm`xxxxx.xx.xxxx..`,
        rtm`xxxxxxx.xxxx.x.`,
        rtm`......x.xxxx.x.`,
        rtm`xx.x..x.x......`,
      ]),
      duration: randomMember([32, 128, 96, 64], 0.9),
    })),
  );
  // possibly remove some tracks
  const mute = (indices: number[], probability: number): void => {
    if (rand() < probability)
      arrangement.forEach(({ voices }) =>
        indices.forEach(index => (voices[index] = RhythmStep.Off)),
      );
  };
  mute([12], 0.2); // fx
  mute([7], 0.2); // piano
  mute([9], 0.2); // sequence
  mute([3], 0.2); // snare
  mute([4], 0.2); // snare fx
  mute([2], 0.2); // stab
  mute([5], 0.2); // pad 1
  mute([6], 0.1); // rhodes
  mute([10, 11], 0.2); // vox
  mute([0, 1, 3], 0.1); // drums

  const rhythms = [
    rtmKick,
    rtmSub,
    rtmStab,
    rtmSnare,
    rtmModSnare,
    [1],
    rtmRhodes,
    rtmPiano,
    [1],
    rtmSequence,
    rtmVox1,
    rtmVox2,
    one(128),
    one(128),
    one(128),
  ];

  let [
    kickArrangement,
    subArrangement,
    stabArrangement,
    snareArrangement,
    modSnareArrangement,
    pad1Arrangement,
    rhodesArrangement,
    pianoArrangement,
    padsArrangement,
    sequenceArrangement,
    vox1Arrangement,
    vox2Arrangement,
    fxArrangement,
    sweepArrangement,
    introArrangement,
  ] = arrangement.reduce(
    (memo, { voices, duration }): number[][] => {
      return memo.map((a, i) =>
        a.concat(
          voices[i] === RhythmStep.On
            ? fill(duration, rhythms[i])
            : zero(duration),
        ),
      );
    },
    repeat(15, (): number[] => []),
  );

  let padResetterArrangement = arrangement.reduce(
    (memo, { voices, duration }, i): RhythmStep[] => {
      return memo.concat(
        voices[8] === 1 && (i === 0 || arrangement[i - 1].voices[8] === 0)
          ? one(duration)
          : zero(duration),
      );
    },
    [] as number[],
  );

  const snareVersion = randomMember([0, 0, 1]);

  // shift sequences using convolution reverb left by 0.3 seconds to allow for
  // swell
  const shiftCount = Math.floor(2 * clock);
  const shift = (arrangement: RhythmStep[]): RhythmStep[] =>
    arrangement.slice(shiftCount).concat(arrangement.slice(0, shiftCount));
  padsArrangement = shift(padsArrangement);
  pad1Arrangement = shift(pad1Arrangement);
  rhodesArrangement = shift(rhodesArrangement);
  padResetterArrangement = shift(padResetterArrangement);

  const delay = randomInt(1, 5);
  const seqDecay = random(0.05, 0.35, 2);
  const osc = randomMember([
    'sawtooth',
    'sawtooth',
    'triangle',
    'triangle',
    'square',
    'sine',
  ]);
  const reverbTime = random(0.3, 0.7);
  const bright = random(0.3, 0.7);

  return {
    traits: [
      ['clock', `${clock.toFixed(1)}hz`],
      ['pitch', `${(440 * Math.pow(2, basePitch / 1200)).toFixed(0)}hz`],
      ['delay', delay],
      ['decay', seqDecay.toFixed(2)],
      ['shape', osc],
      ['space', reverbTime.toFixed(2)],
      ['light', bright.toFixed(2)],
      // [
      //   'ARRANGEMENT',
      //   arrangement
      //     .map(({ voices, duration }) => `${voices.join('')} ${duration}`)
      //     .join('\n'),
      // ],
      // ...Object.entries({
      //   KICK: rtmKick,
      //   SUB: rtmSub,
      //   STAB: rtmStab,
      //   SNARE: rtmSnare,
      //   SEQUENCE: rtmSequence,
      //   VOX1: rtmVox1,
      //   VOX2: rtmVox2,
      // }).map(([k, v]) => [k, formatRhythmTraitValue(v)] as Trait),
    ],
    overrides: [
      ['CLOCK', 'YDKALgbSHv1n', 'frequency', clock],

      // reverb times
      ['REVERB PIANO', 'draPZaV1g3tR', 'reverbTime', range(green, 0.7, 0.94)],
      ['REVERB VOX', 'RGi8X0OCMRqr', 'reverbTime', range(green, 0.9, 0.93)],
      ['STAB REVERB', 'iGj5LJWxS8je', 'reverbTime', range(green, 0.4, 0.92)],
      ['REVERB RHODES', 'nCy4JihrwBbb', 'reverbTime', range(green, 0.6, 0.93)],

      // delay
      ['SEQ DELAY', 'zvuEc5iutrnF', 'delayTime', (1 / clock) * delay],

      // arrangements
      ['RTM KICK', '3zN4BuNkiBZn', 'sequence', kickArrangement],
      ['RTM SUB', 'Dh6FoQ7nWHs9', 'sequence', subArrangement],
      ['RTM STAB', 'MjJ0DEuw5jpg', 'sequence', stabArrangement],
      ['RTM SNARE', 'H0jYihAkzQXn', 'sequence', snareArrangement],
      ['RTM MOD SNARE', 'TqvLloc5UjR9', 'sequence', modSnareArrangement],
      ['RTM VOX1', 'D27CsBonmtiB', 'sequence', vox1Arrangement],
      ['RTM VOX2', 'Qy3uqnIbfO9S', 'sequence', vox2Arrangement],
      ['RTM SEQUENCE', 'wKxDK97kD3Jj', 'sequence', sequenceArrangement],
      ['RTM RHODES', 'EQEQdRpBqm5t', 'sequence', rhodesArrangement],
      ['RTM PIANO', 'cVqvJJzDWYCf', 'sequence', pianoArrangement],
      ['RTM FX', '8Y8WRj8h9G1O', 'sequence', fxArrangement],
      ['RTM SWEEP', '0fXNjsyoDPK3', 'sequence', sweepArrangement],
      ['RTM INTRO', '0kUkD36PjCa9', 'sequence', introArrangement],
      ['SEQ PADS ON OFF', 'fR2oYvZc6hUX', 'sequence', padsArrangement],
      ['SEQ PAD1 ON OFF', '7ArmcOQPLc9h', 'sequence', pad1Arrangement],
      ['PAD RESETTER', 'wG9AgV2ZNGtI', 'sequence', padResetterArrangement],

      // pad pitch
      ['PAD 0', '1baCRqg3oxtz', 'detune', padPitch],
      ['PAD 1', 'nbcClsRtGK0d', 'detune', pad1Pitch],
      ['PAD 2', 'X92WUnRbWU3R', 'detune', padPitch],
      ['PAD 3', '1L8ckpJhblQ0', 'detune', pad3Pitch],

      // voice pitches
      ['OSC PAD1', '15cMS5uHgiPR', 'detune', basePitch - 1200],
      ['MELODY OSC', 'RJ4SzHG87fQN', 'detune', sequencePitch],
      ['STAB', 'pQyHwBlCCi8v', 'detune', stabPitch],
      ['SUB', 'Ix9lSVf8jnoe', 'detune', subPitch],
      ['VOX 1', 'M9NSemcFsNMM', 'detune', vox1Pitch],
      ['VOX 2', 'ANFDk8C9nhVg', 'detune', vox2Pitch],
      ['PIANO', 'VBWFfAw3Cx93', 'detune', pianoPitch],
      ['RHODES D', 'D9eT1RfolTkS', 'detune', rhodesDPitch],
      ['RHODES E', 'kcYwsGX5GMjO', 'detune', rhodesEPitch],
      ['RHODES F', 'i7oKK5FGT4Ms', 'detune', rhodesFPitch],
      ['RHODES A', '9nDWkFSoHTUH', 'detune', rhodesAPitch],
      ['SWEEP', 'nCZ94FqAKehN', 'detune', sweepPitch],
      [
        'INTRO SWEEP PITCH SHIFT',
        'rMuGAMzb30XF',
        'detune',
        randomInt(-3, -1, 2) * 1200,
      ],

      // sequences
      // ['PAD BASS SEQ', 'GiY1N5NltkrZ', 'sequence', padBassSeq],
      // ['PAD SEQ', '1Z3vnZtvuLad', 'sequence', padSeq],

      // drums
      ['SAMPLE SNARE', 'Qb1hD3whtAX1', 'detune', range(blue, -1200, 600)],
      ['PITCH SHIFT SNARE FX', 'V1NpR3qQYXs5', 'detune', random(-1200, 600)],
      ['SNARE 1 ON OFF', 'XNQdXf4xgtxA', 'gain', snareVersion],
      ['SNARE 2 ON OFF', 'k6GEzDrJKVpO', 'gain', 1 - snareVersion],
      ['SUB DECAY', 'ACjOqUilb7wZ', 'decay', random(0.3, 3)],

      // synth
      ['SYNTH SEQ OKTAVA', '5395MtgDUh22', 'sequence', seqOktava],
      ['SYNTH SEQ DECAY', 'drmgGPLnnwua', 'decay', seqDecay],
      ['SYNTH SEQ DELAY FEEDBACK', 'Hd1uTyZJu3nT', 'gain', random(0.1, 0.4)],
      ['SYNTH SEQ OSC', 'R9fQNNBNEB7H', 'sequence', seqOsc],
      ['MELODY OSC', 'RJ4SzHG87fQN', 'type', osc],
      ['SEQ REVERB', 'bWqdY1CT8hTN', 'diffusion', random(0.6, 0.8)],
      ['SEQ REVERB', 'bWqdY1CT8hTN', 'lp', random(0.6, 0.9)],
      ['SEQ REVERB', 'bWqdY1CT8hTN', 'reverbTime', random(0.3, 0.7)],
      ['SEQ REVERB', 'bWqdY1CT8hTN', 'amount', random(0.25, 0.4)],
      [
        'SEQUENCER OUT',
        'p3rWWomwWtQi',
        'gain',
        osc === 'square' ? 0.77 : osc === 'sine' ? 1.25 : 1.1,
      ],

      [
        'FX RESONATOR',
        'm4oHDrr8hlIT',
        'detune',
        basePitch + randomInt(-1, 1) * 1200,
      ],
      ['FX RESONATOR', 'm4oHDrr8hlIT', 'damping', random(0.2, 0.4)],
      ['FX RESONATOR', 'm4oHDrr8hlIT', 'brightness', bright],
      // restore original snare2
      ...(rand() > 0.55
        ? ([
            [
              'SAMPLE SNARE 2',
              'wOZ3iivjoOn7',
              'buffer',
              '/nSp6m~K91H-94fSpc1Ydzg.wav',
            ],
            ['SNARE 2 GAN', 'AAz0agNfiCTY', 'gain', '13'],
          ] as Override[])
        : []),
    ],
    arrangement,
    clock,
  };
};

export const randomGraph = (seed: number, tokenId: number): Graph => {
  const rand = mulberry32(seed);
  const { traits, overrides, arrangement, clock } = randomOverrides(seed);
  const graph = randomizeNodePositions(baseGraph, seed);

  return {
    ...graph,
    ...applyOverrides(graph, overrides),
    seed,
    tokenId,
    traits,
    arrangement,
    baseHue: Math.floor(rand() * 360),
    cycleDuration:
      arrangement.reduce((memo, v) => memo + v.duration, 0) / clock,
    name: randomName(tokenId),
  };
};
