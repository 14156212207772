import React, { FC, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { IOType, add, Graph } from '../../types';
import {
  graphBounds,
  getDefaultNodeSize,
  getRawIOCenterPosition,
} from '../../utils';
import { TriggerEvent } from '../../audioNodeUtils';
import { AudioGraph } from '../../audioGraph';
import { hsluvToHex } from 'hsluv-ts';

const nodeStrokeWidth = 4;
const edgeWidth = 4;

const SVG = styled.svg`
  margin: 0;
`;
const Node = styled.rect`
  stroke-width: ${nodeStrokeWidth};
`;
const NonScalingEdge = styled.line`
  stroke-linecap: round;
  stroke-width: ${edgeWidth};
`;

export type GraphThumbnailProps = {
  audioGraph?: AudioGraph;
  graph: Graph;
  width: number | string;
};

export const GraphThumbnail: FC<GraphThumbnailProps> = ({
  audioGraph,
  graph,
  width,
}) => {
  const [[x, y], [w, h]] = graphBounds(graph);
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (audioGraph) {
      const listeners = Object.entries(audioGraph.audioNodes).reduce(
        (memo, [id, node]) => {
          memo[id] = ((e: TriggerEvent) => {
            if (e.detail) {
              (svgRef.current?.querySelector(
                `[data-id='${id}']`,
              ) as any)?.beginElement();
            }
          }) as EventListener;
          node.addEventListener('trigger', memo[id]);
          return memo;
        },
        {} as { [id: string]: EventListener },
      );

      return () => {
        Object.entries(listeners).forEach(([id, listener]) =>
          audioGraph?.audioNodes[id].removeEventListener('trigger', listener),
        );
      };
    }
    return undefined;
  }, [audioGraph]);

  return (
    <SVG
      ref={svgRef}
      viewBox={`${x - nodeStrokeWidth} ${y - nodeStrokeWidth} ${
        w + 2 * nodeStrokeWidth
      } ${h + 2 * nodeStrokeWidth}`}
      width={width}
    >
      {Object.entries(graph.edges).map(([id, { from, to }]) => {
        const {
          audioNode: { type: fromNodeType },
          data: { position: fromNodePosition },
        } = graph.nodes[from.node];
        const {
          audioNode: { type: toNodeType },
          data: { position: toNodePosition },
        } = graph.nodes[to.node];

        const [x1, y1] = add(
          fromNodePosition,
          getRawIOCenterPosition(fromNodeType, IOType.Output, from.index),
        );
        const [x2, y2] = add(
          toNodePosition,
          getRawIOCenterPosition(toNodeType, IOType.Input, to.index),
        );
        return (
          <NonScalingEdge
            key={id}
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            stroke={hsluvToHex([graph.baseHue - 10, 100, 5])}
          />
        );
      })}
      {Object.entries(graph.nodes).map(([id, node]) => {
        const [x, y] = node.data.position;
        const [w, h] = getDefaultNodeSize(node.audioNode.type);
        return (
          <Node
            key={id}
            x={x}
            y={y}
            width={w}
            rx={1 / 3}
            height={h}
            stroke={hsluvToHex([graph.baseHue, 100, 15])}
          >
            <animate
              data-id={id}
              attributeName="stroke"
              begin="indefinate"
              dur="0.15s"
              from={hsluvToHex([graph.baseHue, 50, 50])}
              to={hsluvToHex([graph.baseHue, 100, 15])}
            />
          </Node>
        );
      })}
    </SVG>
  );
};
