import React, { FC } from 'react';
import { GridLayout } from './GridLayout';
import { Link } from './Link';
import styled from 'styled-components';
import { MINTING } from '../data/MINTING';

const links: [href: string, text: string, props?: any][] = [
  ['/faq', 'FAQ'],
  ...(MINTING ? [['/gallery', 'Gallery'] as any] : []),
  [
    'https://twitter.com/SynthopiaNFT',
    'Twitter',
    { target: '_blank', rel: 'noopener noreferrer' },
  ],
  [
    'https://discord.com/invite/MnQyYCjbvG',
    'Discord',
    { target: '_blank', rel: 'noopener noreferrer' },
  ],
  ...(MINTING
    ? [
        [
          'https://etherscan.io/address/0x709ea840f66fb32A762908aFaE673610E169bf5a',
          'Smart contract',
        ] as any,
      ]
    : []),
  ['/terms', 'Terms'],
];

const FooterLink = styled(Link)`
  text-decoration: none;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6em;
  margin: 6em 0;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Footer: FC = () => (
  <Row>
    <div>
      <img
        src="/logo.png"
        style={{ height: 24, marginLeft: -6, marginBottom: '1em' }}
      />
      <div>BY GRAMATIK + LUXAS</div>
      <div>+ AUDIOGLYPHS</div>
    </div>
    <GridLayout style={{ maxWidth: 400 }} minSize={10}>
      {links.map(([href, text, props = {}], i) => (
        <div>
          <FooterLink key={i} href={href} {...props}>
            {text}
          </FooterLink>
        </div>
      ))}
    </GridLayout>
  </Row>
);
