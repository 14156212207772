import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { PageTitle } from './PageTitle';
import { Header } from './Header';
import { Footer } from './Footer';
import { HR } from './HR';
import { Link } from '.';

export type BreadcrumbsProps = {
  breadcrumbs: [string, string | null][];
};

const Container = styled.div`
  margin: 4em 0;
`;
const TextSegment = styled.span`
  color: #999;
`;
const LinkSegment = styled(Link)`
  text-decoration: none;
`;
const Divider = styled.span`
  color: #36b6d9;
  padding: 0 1em;
  &:after {
    content: '/';
  }
`;

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  return (
    <Container>
      {breadcrumbs.map(([text, href], i) => (
        <Fragment key={i}>
          {i > 0 && <Divider />}
          {href === null ? (
            <TextSegment>{text}</TextSegment>
          ) : (
            <LinkSegment href={href}>{text}</LinkSegment>
          )}
        </Fragment>
      ))}
    </Container>
  );
};
