import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Overlay } from './Overlay';
import { ErrorText } from './ErrorText';
import { NetworkActivityIndicator } from './NetworkActivityIndicator';

const Main = styled(Overlay)`
  background: rgba(0, 0, 0, 0.5);
`;

export type BlockingOverlayProps = {
  error: string | null;
  text?: string;
};

export const BlockingOverlay: FC<BlockingOverlayProps> = ({ error, text }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Main>
      <NetworkActivityIndicator active={true} pending={true} error={!!error} />
      {text && <p>{text}</p>}
      <ErrorText>{error}</ErrorText>
    </Main>
  );
};
