import {
  ID,
  Node,
  Edge,
  Graph,
  ParamType,
  nodeDescriptions,
  AudioNodeType,
} from '../types';
import { getAudioParamNames } from '../utils';
import { audioNodeConstructors } from './audioNodeConstructors';

export const emptyGraph = {
  seed: 0,
  tokenId: 0,
  name: '',
  baseHue: 0,
  cycleDuration: 0,
  nodes: {},
  edges: {},
  incomingEdges: {},
  outgoingEdges: {},
  traits: [],
  arrangement: [],
};

// moved files to global so that they are not reloaded as the single page app
// is navigated
let files: {
  [sampleRate: number]: {
    [url: string]: {
      audioBuffer: Promise<AudioBuffer>;
    };
  };
} = {};

export const clearFiles = (): void => {
  files = {};
};

export class AudioGraph {
  context: BaseAudioContext;
  destination: GainNode;
  analyser: AnalyserNode;
  audioNodes: { [id: string]: AudioNode & { close?: () => void } };
  graph: Graph;
  closed: boolean;

  // resolved when worklet processors are loaded
  ready: Promise<void>;

  // resolved when all files in the initial version of the graph passed to the
  // constructor have loaded
  filesReady: Promise<void>;

  constructor(graph?: Graph, context?: BaseAudioContext) {
    this.context =
      context ||
      new AudioContext({
        latencyHint: 'playback',
      });
    this.destination = new GainNode(this.context);
    this.analyser = new AnalyserNode(this.context, {
      fftSize: 1024,
      smoothingTimeConstant: 0.6,
      maxDecibels: -6,
      minDecibels: -90,
    });
    this.audioNodes = {};
    this.ready = Promise.all(
      [
        '/ADEnvelopeProcessor.js',
        '/PitchShifterProcessor.js',
        '/QuantizerProcessor.js',
        '/RandomGateProcessor.js',
        '/ResonatorProcessor.js',
        '/ReverbProcessor.js',
        '/RhythmProcessor.js',
        '/SampleAndHoldProcessor.js',
        '/SequenceProcessor.js',
        '/TriggeredSamplerProcessor.js',
        '/WhiteNoiseProcessor.js',
      ].map(path => this.context.audioWorklet.addModule(path)),
    ).then(() => undefined);
    this.graph = emptyGraph;
    this.closed = false;

    this.destination.connect(this.analyser);
    this.analyser.connect(this.context.destination);

    if (this.context instanceof AudioContext) {
      this.context.suspend();
    }

    if (graph) {
      this.filesReady = this.ready
        .then(() => {
          this.update(graph);
          return Promise.all(
            Object.values(files[this.context.sampleRate]).map(
              ({ audioBuffer }) => audioBuffer,
            ),
          );
        })
        .then(() => undefined);
    } else {
      this.filesReady = Promise.resolve();
    }
  }

  update(nextGraph: Graph) {
    const graph = this.graph;
    this.graph = nextGraph;

    if (this.closed) return;

    // diff and update nodes
    if (nextGraph.nodes !== graph.nodes) {
      Object.keys(nextGraph.nodes).forEach((id: ID) => {
        const nextNode = nextGraph.nodes[id];
        const node = graph.nodes[id];
        if (node === undefined) {
          this.addNode(nextNode);
        } else if (node.audioNode !== nextNode.audioNode) {
          this.updateNode(node, nextNode);
        }
      });
      Object.keys(graph.nodes).forEach((id: ID) => {
        if (nextGraph.nodes[id] === undefined) {
          this.removeNode(graph.nodes[id]);
        }
      });
    }

    // diff and update edges
    if (nextGraph.edges !== graph.edges) {
      Object.keys(nextGraph.edges).forEach(id => {
        if (graph.edges[id] === undefined) {
          const edge = nextGraph.edges[id];
          const toNode = nextGraph.nodes[edge.to.node];
          this.connect(edge, toNode);
        }
      });
      Object.keys(graph.edges).forEach(id => {
        if (nextGraph.edges[id] === undefined) {
          // disconnect nodes
          const edge = graph.edges[id];
          const fromNode = nextGraph.nodes[edge.from.node];
          const toNode = nextGraph.nodes[edge.to.node];

          // if the node on either end of the edge has been removed, this
          // will have already been disconnected and we can stop here
          if (fromNode === undefined || toNode === undefined) return;

          this.disconnect(edge, toNode);
        }
      });
    }
  }

  close(): void {
    if (!(this.context instanceof AudioContext)) {
      throw new Error('close cannot be called when graph context is offline');
    }
    if (this.closed) {
      return;
    }
    this.closed = true;
    Object.values(this.audioNodes).forEach(node => {
      if (node.close) node.close();
    });
    this.context.close();
  }

  private addNode(node: Node): void {
    const {
      id,
      audioNode: { params, type },
    } = node;

    let audioNode: AudioNode;
    if (type === AudioNodeType.AudioDestinationNode) {
      // use special handling for destination nodes so that multiple nodes can
      // be created and deleted
      audioNode = new GainNode(this.context);
      audioNode.connect(this.destination);
    } else {
      const description = nodeDescriptions[type];
      const AudioNodeClass = audioNodeConstructors[type];
      audioNode = new AudioNodeClass(
        this.context,
        Object.keys(params).reduce((memo, key) => {
          const paramDescription = description.params[key];
          const value = (params as any)[key];
          if (paramDescription.type === ParamType.AudioBuffer) {
            if (value === null) {
              memo[key] = null;
            } else {
              this.loadAudioBuffer(node, key, value);
            }
          } else {
            memo[key] = value;
          }
          return memo;
        }, {} as any),
      );
      if (audioNode instanceof AudioScheduledSourceNode) audioNode.start();
    }

    this.audioNodes[id] = audioNode;
  }

  private updateNode(node: Node, nextNode: Node): void {
    const description = nodeDescriptions[nextNode.audioNode.type];
    const audioNode = this.audioNodes[node.id];
    const params = node.audioNode.params;
    const nextParams = nextNode.audioNode.params;
    Object.keys(params).forEach(key => {
      const value: any = (params as any)[key];
      const nextValue: any = (nextParams as any)[key];
      if (value !== nextValue) {
        const paramDescription = description.params[key];
        if (paramDescription.type === ParamType.AudioParam) {
          (audioNode as any)[key].value = nextValue;
        } else if (paramDescription.type === ParamType.AudioBuffer) {
          if (nextValue === null) {
            (audioNode as any)[key] = null;
          } else {
            this.loadAudioBuffer(nextNode, key, nextValue);
          }
        } else {
          (audioNode as any)[key] = nextValue;
        }
      }
    });
  }

  private removeNode({ id, audioNode: { type, params } }: Node): void {
    const audioNode = this.audioNodes[id];
    delete this.audioNodes[id];
    audioNode.disconnect();

    // hook for node to clean up any bindings (for example midi event listeners)
    if (audioNode.close) audioNode.close();

    // clean up referenced files
    const description = nodeDescriptions[type];
    Object.keys(params).forEach(key => {
      const paramDescription = description.params[key];
      if (paramDescription.type === ParamType.AudioBuffer) {
        const url = (params as any)[key];
      }
    });
  }

  private connect(edge: Edge, toNode: Node): void {
    const fromAudioNode = this.audioNodes[edge.from.node];
    const toAudioNode = this.audioNodes[edge.to.node];
    const toNodeDescription = nodeDescriptions[toNode.audioNode.type];
    if (edge.to.index < toNodeDescription.numberOfInputs) {
      fromAudioNode.connect(toAudioNode, edge.from.index, edge.to.index);
    } else {
      const name = getAudioParamNames(toNodeDescription)[
        edge.to.index - toNodeDescription.numberOfInputs
      ];
      const toAudioParam = (toAudioNode as any)[name];
      fromAudioNode.connect(toAudioParam, edge.from.index);
    }
  }

  private disconnect(edge: Edge, toNode: Node): void {
    const fromAudioNode = this.audioNodes[edge.from.node];
    const toAudioNode = this.audioNodes[edge.to.node];
    const toNodeDescription = nodeDescriptions[toNode.audioNode.type];
    if (edge.to.index < toNodeDescription.numberOfInputs) {
      fromAudioNode.disconnect(toAudioNode, edge.from.index, edge.to.index);
    } else {
      const name = getAudioParamNames(toNodeDescription)[
        edge.to.index - toNodeDescription.numberOfInputs
      ];
      const toAudioParam = (toAudioNode as any)[name];
      fromAudioNode.disconnect(toAudioParam, edge.from.index);
    }
  }

  private loadAudioBuffer(node: Node, key: string, url: string): void {
    const sampleRate = this.context.sampleRate;
    files[sampleRate] = files[sampleRate] || {};
    let audioBuffer: Promise<AudioBuffer>;
    if (files[sampleRate][url]) {
      ({ audioBuffer } = files[sampleRate][url]);
    } else {
      audioBuffer = fetch(url)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => this.context.decodeAudioData(arrayBuffer));
      files[sampleRate][url] = { audioBuffer };
    }

    audioBuffer.then(buffer => {
      const audioNode = this.audioNodes[node.id];
      (audioNode as any)[key] = buffer;
    });
  }
}
