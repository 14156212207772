export class WhiteNoiseNode extends AudioWorkletNode {
  constructor(context: BaseAudioContext) {
    super(context, 'WhiteNoiseProcessor', {
      numberOfInputs: 0,
      numberOfOutputs: 1,
      outputChannelCount: [1],
      channelCount: 1,
      channelCountMode: 'explicit',
      channelInterpretation: 'discrete',
    });
  }
}
