import styled from 'styled-components';
import { Button } from './Button';

export const ButtonPrimary = styled(Button)`
  width: auto;
  font-size: 1.25em;
  padding: 1em 2em;
  color: white;
  flex: 0 0 auto;
  text-decoration: none;
  white-space: nowrap;
`;
