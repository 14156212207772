import { TriggerEvent } from '../audioNodeUtils';

export class TriggeredSamplerNode extends AudioWorkletNode {
  readonly clock: AudioParam;
  readonly detune: AudioParam;
  private _buffer: AudioBuffer | null;

  constructor(
    context: BaseAudioContext,
    {
      clock = 0,
      detune = 0,
      buffer = null,
    }: { clock: number; detune: number; buffer: AudioBuffer | null },
  ) {
    super(context, 'TriggeredSamplerProcessor', {
      numberOfInputs: 0,
      numberOfOutputs: 1,
      outputChannelCount: [1],
      channelCount: 1,
      channelCountMode: 'explicit',
      channelInterpretation: 'discrete',
      processorOptions: { buffer },
      parameterData: {
        clock,
        detune,
      },
    });
    this.port.onmessage = () => {
      this.dispatchEvent(new TriggerEvent());
    };

    this._buffer = buffer;

    // @ts-ignore
    this.clock = this.parameters.get('clock');
    // @ts-ignore
    this.detune = this.parameters.get('detune');
  }

  get buffer(): AudioBuffer | null {
    return this._buffer;
  }
  set buffer(buffer: AudioBuffer | null) {
    this._buffer = buffer;
    if (buffer !== null) {
      this.port.postMessage(buffer.getChannelData(0));
    } else {
      this.port.postMessage(null);
    }
  }
}
