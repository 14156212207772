import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;
const Col = styled.div`
  max-width: 256px;
  padding: 1em;
  box-sizing: content-box;
  gap: 1em;
`;

export type BasicLayoutProps = {
  children: ReactNode;
};

export const BasicLayout: FC<BasicLayoutProps> = ({ children }) => {
  return (
    <Container>
      <Col>{children}</Col>
    </Container>
  );
};
