import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, GridLayout, Layout, Link, P, Stack } from '../../components';
import { useAPIData } from '../../hooks';
import { Metadata } from '../../types';
import { randomGraph } from '../../randomGraph';
import { Image } from './Image';
import { translateSeed } from '../../utils';

const IllustrationContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  color: #999;
`;

export type GalleryProps = {
  path: string[];
};

export const Gallery: FC<GalleryProps> = ({ path: [path] }) => {
  const page = parseInt(path.split('?page=')[1] ?? 1);

  let [loading, error, data] = useAPIData<{
    count: number;
    numberOfPages: number;
    results: Metadata[];
  }>(`gallery?page=${page}`, [page]);

  return (
    <Layout
      title="Gallery"
      breadcrumbs={[
        ['GALLERY', '/gallery'],
        ['...', null],
      ]}
      loading={loading}
      error={!!error}
    >
      {data && (
        <GridLayout minSize={20} space={3}>
          {data.results?.map(metadata => {
            let image;
            let name;
            if ((metadata.seed as any) === '') {
              image = (
                <img src="/pre-reveal.png" style={{ aspectRatio: '1' }} />
              );
              name = 'Unrevealed';
            } else {
              const graph = randomGraph(
                translateSeed(metadata.seed),
                metadata.tokenId,
              );
              image = <Image graph={graph} />;
              name = graph.name;
            }
            return (
              <IllustrationContainer
                plain
                href={`/gallery/${metadata.tokenId}`}
                key={metadata.tokenId}
                disabled={(metadata.seed as any) === ''}
              >
                {image}
                {`#${metadata.tokenId}`} {name}
              </IllustrationContainer>
            );
          })}
          <Stack>
            {data && data.numberOfPages > 1 && page > 1 && (
              <div>
                <Link plain href={`/gallery?page=${page - 1}`}>
                  <Button>Previous Page</Button>
                </Link>
              </div>
            )}
            {data && data.numberOfPages > page && (
              <div>
                <Link plain href={`/gallery?page=${page + 1}`}>
                  <Button>Next Page</Button>
                </Link>
              </div>
            )}
            <P>
              Page {page} / {data?.numberOfPages ?? '--'}
            </P>
          </Stack>
        </GridLayout>
      )}
    </Layout>
  );
};
