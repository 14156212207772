import React, { FC, ReactNode } from 'react';
import { web3Util } from '../utils';
import { Web3Context } from '../contexts';

export type Web3ProviderProps = { children?: ReactNode };

export const Web3Provider: FC<Web3ProviderProps> = ({ children }) => {
  const [accounts, setAccounts] = React.useState<string[] | undefined>();
  React.useEffect(() => {
    web3Util.on('accountsUpdated', newAccounts => {
      setAccounts(newAccounts);
    });
    // if (window.localStorage?.getItem(web3Util.LS_KEY) === 'true') {
    //   try {
    //     web3Util.enable();
    //   } catch {}
    // }
  }, []);

  return (
    <Web3Context.Provider
      value={{
        address: (accounts && accounts[0]) ?? null,
        hasProvider: web3Util.hasProvider,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
