import React, { FC } from 'react';

export type FullscreenButtonProps = {
  fullscreen: boolean;
  toggle: () => void;
  size?: number;
};

export const FullscreenButton: FC<FullscreenButtonProps> = ({
  size = 16,
  fullscreen,
  toggle,
}) => {
  return (
    <svg
      tabIndex={1}
      onClick={toggle}
      onKeyDown={e => {
        if (e.key === 'Enter') toggle();
      }}
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <title>{fullscreen ? 'end fullsecreen' : 'fullscreen'}</title>
      {fullscreen ? (
        <>
          <rect x={1} y={1} width={14} height={14} fill="#ccc" />
          <rect x={1} y={6} width={14} height={4} fill="#000" />
          <rect x={6} y={1} width={4} height={14} fill="#000" />
          <rect x={1} y={1} width={3} height={3} fill="#000" />
          <rect x={12} y={1} width={3} height={3} fill="#000" />
          <rect x={1} y={12} width={3} height={3} fill="#000" />
          <rect x={12} y={12} width={3} height={3} fill="#000" />
        </>
      ) : (
        <>
          <rect x={1} y={1} width={14} height={14} fill="#ccc" />
          <rect x={1} y={6} width={14} height={4} fill="#000" />
          <rect x={6} y={1} width={4} height={14} fill="#000" />
          <rect x={3} y={3} width={10} height={10} fill="#000" />
        </>
      )}
    </svg>
  );
};
