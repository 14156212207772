import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { PageTitle } from './PageTitle';
import { Header } from './Header';
import { Footer } from './Footer';
import { HR } from './HR';
import { Breadcrumbs } from './Breadcrumbs';
import { Center, H1, NetworkActivityIndicator } from '.';

export type LayoutProps = {
  title: string;
  children?: ReactNode;
  breadcrumbs?: [string, string | null][];
  loading?: boolean;
  error?: boolean;
};

const Container = styled.div`
  max-width: 1200px;
  box-sizing: border-box;
  padding: 0 3em 6em;
  margin: 0 auto;
`;

export const Layout: FC<LayoutProps> = ({
  title,
  children,
  breadcrumbs,
  loading,
  error,
}) => {
  return (
    <>
      <PageTitle title={title} />
      <Container>
        <Header />
        {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        {loading || error ? (
          <Center>
            <NetworkActivityIndicator
              pending={false}
              active={true}
              error={!!error}
            />
          </Center>
        ) : (
          <>
            <H1>{title}</H1>
            {children}
            <HR />
            <Footer />
          </>
        )}
      </Container>
    </>
  );
};
