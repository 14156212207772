import React, { FC, useState } from 'react';
import { AudioGraph } from '../../audioGraph';
import { useAnimationFrameEffect } from '../../hooks';
import { OscillatorNodeParams } from '../../types';
import { Tracks } from './Tracks';
import { Arrangement } from './Arrangement';

export type CurrentBeatProps = {
  audioGraph: AudioGraph;
};

export const CurrentBeat: FC<CurrentBeatProps> = ({ audioGraph }) => {
  const [beat, setBeat] = useState(0);
  useAnimationFrameEffect(
    () => {
      const rate = (audioGraph.graph.nodes['YDKALgbSHv1n'].audioNode
        .params as OscillatorNodeParams).frequency;
      const currentBeat = Math.floor(audioGraph.context.currentTime * rate);
      setBeat(currentBeat);
    },
    [audioGraph],
    1,
  );

  return (
    <>
      <Arrangement beat={beat} graph={audioGraph.graph} />
      <Tracks beat={beat} graph={audioGraph.graph} />
    </>
  );
};
