import React, { FC, ChangeEvent } from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { Label } from './Label';
import { TAB_ORDER } from '../utils';

const Group = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  border: 0;
  margin: 0;
  padding: 0;
`;

const ButtonLabel = styled(Label)`
  margin: 0;
  line-height: 1.5em;
  padding-right: 1em;
  &:last-child {
    padding-right: 0;
  }
`;

const ButtonInput = styled(Input)`
  margin-right: 0.5em;
`;

export type RadioButtonGroupProps = {
  name: string;
  value: string;
  options: { [value: string]: string };
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  name,
  value,
  onChange,
  options,
}) => (
  <Group>
    {Object.entries(options).map(([optionValue, optionLabel]) => (
      <ButtonLabel htmlFor={`${name}-${value}`}>
        <ButtonInput
          type="radio"
          name={name}
          value={optionValue}
          id={`${name}-${optionValue}`}
          tabIndex={TAB_ORDER.INPUT}
          checked={optionValue === value}
          onChange={onChange}
        />
        {optionLabel}
      </ButtonLabel>
    ))}
  </Group>
);
