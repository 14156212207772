import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BlockingOverlay, GridLayout, Layout } from '../../components';
import { randomGraph } from '../../randomGraph';
import { downloadAudioFile } from '../../audioGraph';
import { Label } from '../../components/Label';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  max-width: 700px;
`;
const Field = styled.div`
  margin: 1em 0;
  width: 196px;
`;
const SubmitButton = styled(Button)`
  width: 196px;
`;

export type DownloadSeedProps = {
  path: string[];
};

const finalNodes = {
  kick: 'pcVOI0mGUb8l',
  stab: 'mpcQH4RagLhr',
  snare: 'EsOVeDZrSxHE',
  string: '66HRMlChBbT1',
  rhodes: 'nkB2gAX8vNlY',
  piano: 'Y8ldcF0pWe98',
  pads: 'pKLMx9fGoV63',
  sequence: 'p3rWWomwWtQi',
  vox: '53IffwXWFmT8',
  fx: '0wv3LbOYfx2r',
  sweep: 'itgdSvP1SHnn',
  intro: 'Ddc8EOj4wigF',
};

const numberOfChannels = 2;
const sampleRate = 44100;
const bitDepth = 16;
const fadeIn = 0;
const fadeOut = 8;

export const DownloadSeed: FC<DownloadSeedProps> = ({
  path: [, seedString],
}) => {
  const seed = parseInt(seedString);
  const tokenId = (seed % 9000) + 1;

  const [renderActive, setRenderActive] = useState(false);
  const [cycleCount, setCycleCount] = useState(1);
  const [progress, setProgress] = useState(0);

  const graph = useMemo(() => randomGraph(seed, tokenId), [seed, tokenId]);

  const duration =
    graph !== null
      ? (Number.isNaN(cycleCount) ? 0 : cycleCount) * graph.cycleDuration +
        fadeOut
      : 0;

  const download = async (stemName?: string) => {
    if (renderActive || seed === null) return;

    const graph = randomGraph(seed, tokenId);

    const nodes = graph.nodes as any;

    // if stem name is provided, mute all other tracks
    if (stemName) {
      Object.entries(finalNodes).forEach(([name, id]) => {
        if (name !== stemName) {
          nodes[id].audioNode.params.gain = 0;
        }
      });
    }

    setProgress(0);
    setRenderActive(true);

    await downloadAudioFile(
      graph,
      numberOfChannels,
      sampleRate,
      bitDepth,
      duration,
      fadeIn,
      fadeOut,
      stemName ? `${graph.name} - ${stemName}` : graph.name,
      setProgress,
      false,
    );

    setRenderActive(false);
  };

  return (
    <Layout
      title={`Unminted Seed Downloads (${graph.name})`}
      breadcrumbs={[
        ['Voyager', null],
        [`#${tokenId} ${graph.name}`, `/seed/${seed}`],
        ['Download', `/seed/${seed}/download`],
      ]}
    >
      <Container>
        <div>
          <Field>
            <Label htmlFor="cycleCount">Cycle count</Label>
            <Input
              name="cycleCount"
              id="cycleCount"
              value={Number.isNaN(cycleCount) ? '' : cycleCount}
              onChange={e => {
                setCycleCount(parseInt(e.target.value));
              }}
            />
          </Field>
          <div style={{ marginTop: '1em' }}>
            <p>
              Downloads will be wave files, approx. {duration.toFixed(0)}{' '}
              seconds and{' '}
              {parseFloat(((2 * 44100 * 16 * duration) / 8000000).toFixed(2)) ||
                0}
              MB
            </p>
          </div>
        </div>
        <div>
          <h2 style={{ marginBottom: '2rem' }}>Download Song</h2>
          <SubmitButton
            onClick={() => {
              download();
            }}
          >
            Download
          </SubmitButton>
        </div>
        <div>
          <h2 style={{ marginBottom: '2rem' }}>Download All Stems</h2>
          <SubmitButton
            onClick={async () => {
              const keys = Object.keys(finalNodes);
              for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                await download(key);
              }
            }}
          >
            Download
          </SubmitButton>
        </div>
        <div>
          <h2>Download Stems</h2>
          <GridLayout>
            {Object.keys(finalNodes).map(name => (
              <SubmitButton
                onClick={() => {
                  download(name);
                }}
              >
                Download {name}
              </SubmitButton>
            ))}
          </GridLayout>
        </div>
      </Container>
      {renderActive && (
        <BlockingOverlay
          error={null}
          text={`${(progress * 100).toFixed(0)}%`}
        />
      )}
    </Layout>
  );
};
