export class ResonatorNode extends AudioWorkletNode {
  readonly frequency: AudioParam;
  readonly structure: AudioParam;
  readonly brightness: AudioParam;
  readonly damping: AudioParam;
  readonly position: AudioParam;

  constructor(
    context: BaseAudioContext,
    {
      frequency = 220,
      detune = 0,
      structure = 0.25,
      brightness = 0.5,
      damping = 0.3,
      position = 0.999,
    }: {
      frequency?: number;
      detune?: number;
      structure?: number;
      brightness?: number;
      damping?: number;
      position?: number;
    },
  ) {
    super(context, 'ResonatorProcessor', {
      numberOfInputs: 1,
      numberOfOutputs: 1,
      outputChannelCount: [2],
      channelCount: 1,
      channelCountMode: 'explicit',
      channelInterpretation: 'discrete',
      parameterData: {
        frequency,
        detune,
        structure,
        brightness,
        damping,
        position,
      },
    });

    // @ts-ignore
    this.frequency = this.parameters.get('frequency');
    // @ts-ignore
    this.detune = this.parameters.get('detune');
    // @ts-ignore
    this.structure = this.parameters.get('structure');
    // @ts-ignore
    this.brightness = this.parameters.get('brightness');
    // @ts-ignore
    this.damping = this.parameters.get('damping');
    // @ts-ignore
    this.position = this.parameters.get('position');
  }
}
