import { graphFromStorage } from './utils/graphFromStorage';

export const baseGraphForStorage: any = {
  id: 'charlie/NE2uczSzO5iN',
  metadata: {},
  nodes: {
    oeYy75wASWYe: {
      id: 'oeYy75wASWYe',
      audioNode: { type: 'AudioDestinationNode', params: {} },
      data: { position: [81, -189], name: 'OUTPUT' },
    },
    '1L8ckpJhblQ0': {
      id: '1L8ckpJhblQ0',
      audioNode: {
        type: 'OscillatorNode',
        params: { frequency: 440, detune: 0, type: 'sawtooth' },
      },
      data: { position: [-101, -201], name: 'OSC PAD 3' },
    },
    YDKALgbSHv1n: {
      id: 'YDKALgbSHv1n',
      audioNode: {
        type: 'OscillatorNode',
        params: { frequency: 9, detune: 0, type: 'square' },
      },
      data: { position: [-261, -200], name: 'MAIN CLOCK' },
    },
    iGj5LJWxS8je: {
      id: 'iGj5LJWxS8je',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 0.7,
          reverbTime: 0.5,
          amount: 0.6,
          gain: 0.5,
        },
      },
      data: { position: [-70, -459], name: 'STAB REVERB' },
    },
    pQyHwBlCCi8v: {
      id: 'pQyHwBlCCi8v',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/4woWjOmYW7fr5IxtzEvcdQ.wav',
          clock: 0,
          detune: -1200,
        },
      },
      data: { position: [-82, -460], name: 'OB STAB' },
    },
    MjJ0DEuw5jpg: {
      id: 'MjJ0DEuw5jpg',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-103, -458], name: 'RTM OB STAB' },
    },
    JFN7p82T4N0j: {
      id: 'JFN7p82T4N0j',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 1000, detune: 0, Q: 1, gain: 0, type: 'lowpass' },
      },
      data: { position: [-76, -459], name: 'FLT' },
    },
    '96EWMaMVO5Ux': {
      id: '96EWMaMVO5Ux',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [0, 0, 0, 0, 0, 0, 300, 300] },
      },
      data: { position: [-89, -457], name: 'SEQ' },
    },
    ivCoN2VVjZSN: {
      id: 'ivCoN2VVjZSN',
      audioNode: { type: 'GainNode', params: { gain: 0 } },
      data: { position: [-28, -456], name: 'STAB GAIN' },
    },
    Ix9lSVf8jnoe: {
      id: 'Ix9lSVf8jnoe',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/Kxt~u3lCAsYqOYUPvFKu7g.wav',
          clock: 0,
          detune: -1200,
        },
      },
      data: { position: [-44, -508], name: 'SUB SAMPLE' },
    },
    Dh6FoQ7nWHs9: {
      id: 'Dh6FoQ7nWHs9',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-59, -508], name: 'RTM SUB SAMPLE' },
    },
    JnRqDvagyW7p: {
      id: 'JnRqDvagyW7p',
      audioNode: {
        type: 'RhythmNode',
        params: { clock: 0, reset: 0, sequence: [1, 0, 0] },
      },
      data: { position: [-225, -207], name: 'RTM' },
    },
    '3zN4BuNkiBZn': {
      id: '3zN4BuNkiBZn',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-60, -517], name: 'KICK RITM' },
    },
    ZzoXngovckug: {
      id: 'ZzoXngovckug',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/sk2vtqOT1ihuhVzR1rkaeg.wav',
          clock: 0,
          detune: 0,
        },
      },
      data: { position: [-52, -517], name: 'KICK' },
    },
    FeRCVX0Frp7j: {
      id: 'FeRCVX0Frp7j',
      audioNode: { type: 'GainNode', params: { gain: 4 } },
      data: { position: [-32, -517], name: 'GAN' },
    },
    ikKWqA0cGN4u: {
      id: 'ikKWqA0cGN4u',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 1000, detune: 0, Q: 1, gain: 0, type: 'lowpass' },
      },
      data: { position: [-42, -518], name: 'FLT' },
    },
    NlgR2mYIeGfR: {
      id: 'NlgR2mYIeGfR',
      audioNode: { type: 'GainNode', params: { gain: 0.09 } },
      data: { position: [-32, -510], name: 'GAN' },
    },
    ACjOqUilb7wZ: {
      id: 'ACjOqUilb7wZ',
      audioNode: {
        type: 'ADEnvelopeNode',
        params: { clock: 0, attack: 0.001, decay: 3, hold: false },
      },
      data: { position: [-42, -497], name: 'AD' },
    },
    '5W7jLwgwIkvC': {
      id: '5W7jLwgwIkvC',
      audioNode: {
        type: 'DynamicsCompressorNode',
        params: {
          threshold: -24,
          knee: 30,
          ratio: 20,
          attack: 0.003,
          release: 0.25,
        },
      },
      data: { position: [-23, -513], name: 'COMP' },
    },
    t48vNOxxsY5I: {
      id: 't48vNOxxsY5I',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 200, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-37, -518], name: 'FLT' },
    },
    JfGI7qB3Pt1C: {
      id: 'JfGI7qB3Pt1C',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [-600, 500, -800, 500, -700, -200],
        },
      },
      data: { position: [-91, -329], name: 'SEQ' },
    },
    pJiJOf2SlDNQ: {
      id: 'pJiJOf2SlDNQ',
      audioNode: { type: 'DelayNode', params: { delayTime: 0.07 } },
      data: { position: [-60, -449], name: 'DLY' },
    },
    QePlxvl6aV85: {
      id: 'QePlxvl6aV85',
      audioNode: { type: 'GainNode', params: { gain: 0.4 } },
      data: { position: [-65, -449], name: 'GAN' },
    },
    '6EY22LD9KpIM': {
      id: '6EY22LD9KpIM',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 0.7,
          reverbTime: 0.9,
          amount: 1,
          gain: 0.5,
        },
      },
      data: { position: [-50, -449], name: 'RVB' },
    },
    ShgkxSVAKBr7: {
      id: 'ShgkxSVAKBr7',
      audioNode: { type: 'PitchShifterNode', params: { detune: 1200 } },
      data: { position: [-55, -449], name: 'PCH' },
    },
    UeInoFH7qIvH: {
      id: 'UeInoFH7qIvH',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 350, detune: 0, Q: 1, gain: 0, type: 'lowpass' },
      },
      data: { position: [-46, -449], name: 'FLT' },
    },
    TN6cCI6lLDR1: {
      id: 'TN6cCI6lLDR1',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [7000, 0, 1000, 0, 500, 700] },
      },
      data: { position: [-238, -195], name: 'SEQ' },
    },
    XZ4aMLtRgb3S: {
      id: 'XZ4aMLtRgb3S',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 200, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-38, -449], name: 'FLT' },
    },
    CSNoT8X2aR6i: {
      id: 'CSNoT8X2aR6i',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/Z9g90W5OUTpzEELJUNn0bw.wav',
          clock: 0,
          detune: 0,
        },
      },
      data: { position: [-99, -342], name: 'MOD SMPL SNR 1' },
    },
    TqvLloc5UjR9: {
      id: 'TqvLloc5UjR9',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-107, -346], name: 'RTM' },
    },
    DwX6aPRnrGxB: {
      id: 'DwX6aPRnrGxB',
      audioNode: { type: 'GainNode', params: { gain: 0 } },
      data: { position: [-56, -343], name: 'GAN' },
    },
    H7eQEOWeV6HL: {
      id: 'H7eQEOWeV6HL',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 0.7,
          reverbTime: 0.5,
          amount: 0.5,
          gain: 0.5,
        },
      },
      data: { position: [-77, -343], name: 'REVERB SNARE FX' },
    },
    JKIXAxwpieov: {
      id: 'JKIXAxwpieov',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [30, 0, 70, 0, 90] },
      },
      data: { position: [-85, -332], name: 'SEQ' },
    },
    W3uSO5Bvc4jb: {
      id: 'W3uSO5Bvc4jb',
      audioNode: {
        type: 'ADEnvelopeNode',
        params: { clock: 0, attack: 0.001, decay: 6, hold: false },
      },
      data: { position: [-72, -345], name: 'AD' },
    },
    roK2PFcV8Ubc: {
      id: 'roK2PFcV8Ubc',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 400, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-72, -335], name: 'FLT' },
    },
    mMdRlRiP9kHT: {
      id: 'mMdRlRiP9kHT',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 700, detune: 0, Q: 1, gain: 0, type: 'lowpass' },
      },
      data: { position: [-67, -335], name: 'FLT' },
    },
    TwY0W29nZ3Uj: {
      id: 'TwY0W29nZ3Uj',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [7000, 0, 1000, 0, 500, 700] },
      },
      data: { position: [-78, -331], name: 'SEQ' },
    },
    Qb1hD3whtAX1: {
      id: 'Qb1hD3whtAX1',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/7wD~-aMHM55CENNcGuTAYA.wav',
          clock: 0,
          detune: 0,
        },
      },
      data: { position: [-114, -364], name: 'SAMPLE SNARE' },
    },
    H0jYihAkzQXn: {
      id: 'H0jYihAkzQXn',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-122, -380], name: 'SNARE RITM' },
    },
    oBeABfwlnE7F: {
      id: 'oBeABfwlnE7F',
      audioNode: { type: 'GainNode', params: { gain: 0 } },
      data: { position: [-54, -391], name: 'GAN' },
    },
    FZ4Frgp6EdOT: {
      id: 'FZ4Frgp6EdOT',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 0.7,
          reverbTime: 0.5,
          amount: 0.5,
          gain: 0.5,
        },
      },
      data: { position: [-73, -360], name: 'PREVERB SNARE' },
    },
    V1NpR3qQYXs5: {
      id: 'V1NpR3qQYXs5',
      audioNode: { type: 'PitchShifterNode', params: { detune: 0 } },
      data: { position: [-78, -396], name: 'PITCH SHIFT SNARE FX' },
    },
    CeKxgK10vPN6: {
      id: 'CeKxgK10vPN6',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 0.7,
          reverbTime: 0.1,
          amount: 1,
          gain: 0.5,
        },
      },
      data: { position: [-66, -399], name: 'REVERB SNARE' },
    },
    v3V3wKdzu2pb: {
      id: 'v3V3wKdzu2pb',
      audioNode: { type: 'GainNode', params: { gain: 0.5 } },
      data: { position: [-60, -395], name: 'GAN' },
    },
    '53X5uLHgMoir': {
      id: '53X5uLHgMoir',
      audioNode: {
        type: 'OscillatorNode',
        params: { frequency: 2, detune: 0, type: 'sawtooth' },
      },
      data: { position: [-73, -393], name: 'OSC' },
    },
    '1fvTI3pSxnUg': {
      id: '1fvTI3pSxnUg',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [0, 3, 0, 6, 0, 200, 0, 1, 0, 4, 0],
        },
      },
      data: { position: [-89, -384], name: 'SEQ' },
    },
    HwAVSAm2ajm8: {
      id: 'HwAVSAm2ajm8',
      audioNode: {
        type: 'OscillatorNode',
        params: { frequency: 3, detune: 0, type: 'square' },
      },
      data: { position: [-76, -388], name: 'OSC' },
    },
    erz9Y7C35gJL: {
      id: 'erz9Y7C35gJL',
      audioNode: {
        type: 'ADEnvelopeNode',
        params: { clock: 0, attack: 0.001, decay: 0.5, hold: false },
      },
      data: { position: [-59, -388], name: 'AD' },
    },
    '483w7AjLcCK8': {
      id: '483w7AjLcCK8',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [0, -500, 0, -700, 0, -900, 0, -1100],
        },
      },
      data: { position: [-87, -393], name: 'SEQ' },
    },
    LRfgZgrBFEFY: {
      id: 'LRfgZgrBFEFY',
      audioNode: {
        type: 'DynamicsCompressorNode',
        params: {
          threshold: -30,
          knee: 40,
          ratio: 20,
          attack: 0.003,
          release: 0.25,
        },
      },
      data: { position: [-50, -392], name: 'COMP' },
    },
    eMF4Bv7bakuR: {
      id: 'eMF4Bv7bakuR',
      audioNode: { type: 'GainNode', params: { gain: 0.3 } },
      data: { position: [-36, -367], name: 'SNARE OUT' },
    },
    '1Z3vnZtvuLad': {
      id: '1Z3vnZtvuLad',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [-200, -400, -500, -400, 0] },
      },
      data: { position: [-107, -207], name: 'PAD SEQ' },
    },
    GaZekPuRdo9M: {
      id: 'GaZekPuRdo9M',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-119, -188], name: 'RTM' },
    },
    E4MemSm2PdKF: {
      id: 'E4MemSm2PdKF',
      audioNode: { type: 'GainNode', params: { gain: 0 } },
      data: { position: [-82, -202], name: 'GAN' },
    },
    SiEwTZZvLtPV: {
      id: 'SiEwTZZvLtPV',
      audioNode: {
        type: 'ADEnvelopeNode',
        params: { clock: 0, attack: 5, decay: 0.001, hold: false },
      },
      data: { position: [-91, -200], name: 'AD' },
    },
    jiMee9SKripV: {
      id: 'jiMee9SKripV',
      audioNode: { type: 'GainNode', params: { gain: 0.5 } },
      data: { position: [-59, -198], name: 'GAN' },
    },
    jVtdzPQn8tRn: {
      id: 'jVtdzPQn8tRn',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.4,
          lp: 0.7,
          reverbTime: 0.6,
          amount: 0.1,
          gain: 0.5,
        },
      },
      data: { position: [-64, -196], name: 'RVB' },
    },
    '2VqoF91Gwa0F': {
      id: '2VqoF91Gwa0F',
      audioNode: { type: 'StereoPannerNode', params: { pan: 0 } },
      data: { position: [-69, -196], name: 'PAN' },
    },
    W8ojWHlZ2rPk: {
      id: 'W8ojWHlZ2rPk',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [-30, 600, -200, 700, -500, 260],
        },
      },
      data: { position: [-103, -190], name: 'SEQ' },
    },
    '5fSOiZX7mzit': {
      id: '5fSOiZX7mzit',
      audioNode: { type: 'DelayNode', params: { delayTime: 0.1 } },
      data: { position: [-76, -198], name: 'DLY' },
    },
    PjQpHSu8CL5Z: {
      id: 'PjQpHSu8CL5Z',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [0.37, 0.001, 0.1] },
      },
      data: { position: [-97, -198], name: 'SEQ' },
    },
    BkkdIjPWdlJB: {
      id: 'BkkdIjPWdlJB',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 0.7,
          reverbTime: 0.1,
          amount: 0.1,
          gain: 0.1,
        },
      },
      data: { position: [-48, -197], name: 'RVB' },
    },
    RAOMDTNwvJ2j: {
      id: 'RAOMDTNwvJ2j',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 350, detune: 0, Q: 0, gain: 0, type: 'highpass' },
      },
      data: { position: [-54, -198], name: 'FLT' },
    },
    '1kmUx3Ql0qch': {
      id: '1kmUx3Ql0qch',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-139, -196], name: 'RTM' },
    },
    INcru5tDlJYR: {
      id: 'INcru5tDlJYR',
      audioNode: {
        type: 'DynamicsCompressorNode',
        params: {
          threshold: 0,
          knee: 0,
          ratio: 20,
          attack: 0.003,
          release: 0.07,
        },
      },
      data: { position: [67, -190], name: 'MASTER COMPRESSOR' },
    },
    OILApVhTP6rJ: {
      id: 'OILApVhTP6rJ',
      audioNode: {
        type: 'ADEnvelopeNode',
        params: { clock: 0, attack: 0.001, decay: 8, hold: false },
      },
      data: { position: [-37, -454], name: 'AD' },
    },
    e8ABNigDCYop: {
      id: 'e8ABNigDCYop',
      audioNode: {
        type: 'RandomGateNode',
        params: { clock: 0, probability: 0.1 },
      },
      data: { position: [-66, -187], name: 'RND' },
    },
    Cvo5UoS6mWiG: {
      id: 'Cvo5UoS6mWiG',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [0, 9, 0, 13, 0, 0, 6, 0] },
      },
      data: { position: [-57, -187], name: 'SEQ' },
    },
    PjbB5oMgI7CU: {
      id: 'PjbB5oMgI7CU',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [8, 0, 17, 0, 26] },
      },
      data: { position: [-66, -386], name: 'SEQ' },
    },
    '3xUWR0KOdPTM': {
      id: '3xUWR0KOdPTM',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 0.7,
          reverbTime: 0.6,
          amount: 0.9,
          gain: 0.5,
        },
      },
      data: { position: [-38, -199], name: 'RVB' },
    },
    ZiwYtG60o1Ts: {
      id: 'ZiwYtG60o1Ts',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-61, -221], name: 'RTM' },
    },
    TwhF67Pypisq: {
      id: 'TwhF67Pypisq',
      audioNode: {
        type: 'ADEnvelopeNode',
        params: { clock: 0, attack: 5, decay: 6, hold: false },
      },
      data: { position: [-52, -216], name: 'AD' },
    },
    j17sUIM19m6z: {
      id: 'j17sUIM19m6z',
      audioNode: { type: 'GainNode', params: { gain: 0.01 } },
      data: { position: [-44, -204], name: 'GAN' },
    },
    '1baCRqg3oxtz': {
      id: '1baCRqg3oxtz',
      audioNode: {
        type: 'OscillatorNode',
        params: { frequency: 440, detune: 0, type: 'sawtooth' },
      },
      data: { position: [-52, -204], name: 'PAD BASS' },
    },
    GiY1N5NltkrZ: {
      id: 'GiY1N5NltkrZ',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [-3100, -3300, -3600, -3800, -3100, -4100, -4000, -3800],
        },
      },
      data: { position: [-63, -210], name: 'PAD BASS SEQ' },
    },
    XcsBDsAxnNKC: {
      id: 'XcsBDsAxnNKC',
      audioNode: { type: 'GainNode', params: { gain: 0 } },
      data: { position: [-28, -197], name: 'EVOLVE MELODY OUT' },
    },
    x7Gg38aRDicF: {
      id: 'x7Gg38aRDicF',
      audioNode: { type: 'GainNode', params: { gain: 1 } },
      data: { position: [-47, -273], name: 'GAN' },
    },
    EQEQdRpBqm5t: {
      id: 'EQEQdRpBqm5t',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-59, -271], name: 'RTM' },
    },
    '75y5pKi781r0': {
      id: '75y5pKi781r0',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 0, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-43, -274], name: 'FLT' },
    },
    nkB2gAX8vNlY: {
      id: 'nkB2gAX8vNlY',
      audioNode: { type: 'GainNode', params: { gain: 0.9 } },
      data: { position: [-20, -269], name: 'RHODES OUT' },
    },
    pcVOI0mGUb8l: {
      id: 'pcVOI0mGUb8l',
      audioNode: { type: 'GainNode', params: { gain: 1 } },
      data: { position: [-17, -509], name: 'KICKSUB OUT' },
    },
    nCy4JihrwBbb: {
      id: 'nCy4JihrwBbb',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 0.7,
          reverbTime: 0.7,
          amount: 0.3,
          gain: 0.5,
        },
      },
      data: { position: [-35, -272], name: 'REVERB RHODES 2' },
    },
    N1JW2JOi5ol3: {
      id: 'N1JW2JOi5ol3',
      audioNode: { type: 'GainNode', params: { gain: 0.6 } },
      data: { position: [-36, -358], name: 'SNAREFX OUT' },
    },
    mpcQH4RagLhr: {
      id: 'mpcQH4RagLhr',
      audioNode: { type: 'GainNode', params: { gain: 1 } },
      data: { position: [-15, -455], name: 'STAB OUT' },
    },
    YFGrqaLM4g1g: {
      id: 'YFGrqaLM4g1g',
      audioNode: {
        type: 'DynamicsCompressorNode',
        params: {
          threshold: -24,
          knee: 30,
          ratio: 12,
          attack: 0.003,
          release: 1,
        },
      },
      data: { position: [-39, -273], name: 'COMP' },
    },
    SsgqtJr24Ny3: {
      id: 'SsgqtJr24Ny3',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 2000, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-25, -271], name: 'FLT' },
    },
    pKLMx9fGoV63: {
      id: 'pKLMx9fGoV63',
      audioNode: { type: 'GainNode', params: { gain: 0.021 } },
      data: { position: [-6, -196], name: 'PADS OUT' },
    },
    BHoxWl2yq5Dg: {
      id: 'BHoxWl2yq5Dg',
      audioNode: {
        type: 'ConvolverNode',
        params: {
          buffer: '/c3khZA0wUzHM1KUrCnOS~A.wav',
          normalize: true,
        },
      },
      data: { position: [-11, -196], name: 'CNV' },
    },
    JeKtz67rTJAd: {
      id: 'JeKtz67rTJAd',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 180, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-22, -197], name: 'FLT' },
    },
    ZVcQJRbGCQw3: {
      id: 'ZVcQJRbGCQw3',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 16000, detune: 0, Q: 1, gain: 0, type: 'lowpass' },
      },
      data: { position: [-16, -197], name: 'FLT' },
    },
    NFhdGuIYCzke: {
      id: 'NFhdGuIYCzke',
      audioNode: {
        type: 'ConvolverNode',
        params: {
          buffer: '/GeX6fJzeNrK2wefqPuzWzw.wav',
          normalize: true,
        },
      },
      data: { position: [-30, -271], name: 'CNV' },
    },
    ri5K35PPibFL: {
      id: 'ri5K35PPibFL',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            0,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-112, -207], name: 'RTM' },
    },
    X92WUnRbWU3R: {
      id: 'X92WUnRbWU3R',
      audioNode: {
        type: 'OscillatorNode',
        params: { frequency: 440, detune: 0, type: 'sawtooth' },
      },
      data: { position: [-101, -207], name: 'OSC PAD 2' },
    },
    nbcClsRtGK0d: {
      id: 'nbcClsRtGK0d',
      audioNode: {
        type: 'OscillatorNode',
        params: { frequency: 440, detune: -700, type: 'sawtooth' },
      },
      data: { position: [-101, -213], name: 'OSC PAD 1' },
    },
    uKYq6BcNBMzG: {
      id: 'uKYq6BcNBMzG',
      audioNode: { type: 'GainNode', params: { gain: 1 } },
      data: { position: [-90, -206], name: 'GAN' },
    },
    tj2AQrrx66Wq: {
      id: 'tj2AQrrx66Wq',
      audioNode: { type: 'GainNode', params: { gain: 0.6 } },
      data: { position: [-48, -118], name: 'GAN' },
    },
    EsOVeDZrSxHE: {
      id: 'EsOVeDZrSxHE',
      audioNode: { type: 'GainNode', params: { gain: 1 } },
      data: { position: [-16, -364], name: 'SNARE SUM' },
    },
    '53IffwXWFmT8': {
      id: '53IffwXWFmT8',
      audioNode: { type: 'GainNode', params: { gain: 0.6 } },
      data: { position: [-5, -109], name: 'VOXY OUT' },
    },
    dVycsrhekZwR: {
      id: 'dVycsrhekZwR',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            0,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-86, -216], name: 'RTM' },
    },
    nHJ8SgkCRFnA: {
      id: 'nHJ8SgkCRFnA',
      audioNode: {
        type: 'RhythmNode',
        params: { clock: 0, reset: 0, sequence: [0, 1, 0] },
      },
      data: { position: [-247, -179], name: 'RTM' },
    },
    NrzEpem4itPA: {
      id: 'NrzEpem4itPA',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [0, -1600] },
      },
      data: { position: [-46, -172], name: 'SEQ' },
    },
    Q9lSJCnKkhVw: {
      id: 'Q9lSJCnKkhVw',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 350, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-42, -449], name: 'FLT' },
    },
    Qy3uqnIbfO9S: {
      id: 'Qy3uqnIbfO9S',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-64, -118], name: 'RITEM VOX 2' },
    },
    pWzHgPrhP8cY: {
      id: 'pWzHgPrhP8cY',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 10000, detune: 0, Q: 1, gain: 0, type: 'lowpass' },
      },
      data: { position: [-25, -115], name: 'FLT' },
    },
    RGi8X0OCMRqr: {
      id: 'RGi8X0OCMRqr',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 0.7,
          reverbTime: 0.9,
          amount: 0.9,
          gain: 0.5,
        },
      },
      data: { position: [-19, -111], name: 'REVERB VOX' },
    },
    R8ffBwvZImrH: {
      id: 'R8ffBwvZImrH',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 500, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-10, -109], name: 'FLT' },
    },
    ANFDk8C9nhVg: {
      id: 'ANFDk8C9nhVg',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/P1tYKSqE1B9XseDzU10Hyw.wav',
          clock: 0,
          detune: 0,
        },
      },
      data: { position: [-55, -118], name: 'KAJA VOX 2' },
    },
    aSdDL2GHQ1HZ: {
      id: 'aSdDL2GHQ1HZ',
      audioNode: { type: 'GainNode', params: { gain: 0.2 } },
      data: { position: [-33, -112], name: 'GAN' },
    },
    M9NSemcFsNMM: {
      id: 'M9NSemcFsNMM',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/1f-STu5kETmwyO9QpeRJow.wav',
          clock: 0,
          detune: 0,
        },
      },
      data: { position: [-49, -109], name: 'KAJA VOX 1' },
    },
    D27CsBonmtiB: {
      id: 'D27CsBonmtiB',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            1,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-64, -109], name: 'RITEM VOX' },
    },
    ATqrrIuZqWwT: {
      id: 'ATqrrIuZqWwT',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 500, detune: 0, Q: 1, gain: 0, type: 'lowpass' },
      },
      data: { position: [-43, -110], name: 'FLT' },
    },
    '9DLqsZtlwgcF': {
      id: '9DLqsZtlwgcF',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 500, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-38, -113], name: 'FLT' },
    },
    lpLdsQENXH3P: {
      id: 'lpLdsQENXH3P',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [0, 0, 1200, 1200, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      },
      data: { position: [-57, -105], name: 'SEQ' },
    },
    VBWFfAw3Cx93: {
      id: 'VBWFfAw3Cx93',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/DO3VAIjFEYhWk7kLTK38Qw.wav',
          clock: 0,
          detune: 0,
        },
      },
      data: { position: [-45, -235], name: 'PIANO SAMPLE' },
    },
    cVqvJJzDWYCf: {
      id: 'cVqvJJzDWYCf',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-51, -235], name: 'RTM PIANO' },
    },
    dp4WKlCWMHrM: {
      id: 'dp4WKlCWMHrM',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 300, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-27, -237], name: 'FLT' },
    },
    Y8ldcF0pWe98: {
      id: 'Y8ldcF0pWe98',
      audioNode: { type: 'GainNode', params: { gain: 0.8 } },
      data: { position: [-21, -237], name: 'PIANO OUT' },
    },
    '8G5e77PubEsl': {
      id: '8G5e77PubEsl',
      audioNode: { type: 'GainNode', params: { gain: 0.3 } },
      data: { position: [-40, -237], name: 'GAN' },
    },
    draPZaV1g3tR: {
      id: 'draPZaV1g3tR',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 0.7,
          reverbTime: 0.8,
          amount: 1,
          gain: 0.5,
        },
      },
      data: { position: [-32, -243], name: 'REVERB PIANO' },
    },
    ygLx4JFDnFeN: {
      id: 'ygLx4JFDnFeN',
      audioNode: {
        type: 'BiquadFilterNode',
        params: {
          frequency: 350,
          detune: 0,
          Q: 1,
          gain: -10,
          type: 'lowshelf',
        },
      },
      data: { position: [-43, -198], name: 'EQ PAD' },
    },
    D9eT1RfolTkS: {
      id: 'D9eT1RfolTkS',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/R~--wwe2oJm6wXGBMyFieg.wav',
          clock: 0,
          detune: 0,
        },
      },
      data: { position: [-53, -278], name: 'D' },
    },
    kcYwsGX5GMjO: {
      id: 'kcYwsGX5GMjO',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/vcFKayyzVN5pjzrYcLPVFw.wav',
          clock: 0,
          detune: 0,
        },
      },
      data: { position: [-53, -273], name: 'E' },
    },
    i7oKK5FGT4Ms: {
      id: 'i7oKK5FGT4Ms',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/AvuCiUmrxqWba71kLOxugw.wav',
          clock: 0,
          detune: 0,
        },
      },
      data: { position: [-53, -268], name: 'F' },
    },
    '9nDWkFSoHTUH': {
      id: '9nDWkFSoHTUH',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/shc-N3NQRqfPyB9VOZRtSQ.wav',
          clock: 0,
          detune: 0,
        },
      },
      data: { position: [-53, -263], name: 'A' },
    },
    H46Gr3mCHWrd: {
      id: 'H46Gr3mCHWrd',
      audioNode: {
        type: 'DynamicsCompressorNode',
        params: {
          threshold: -24,
          knee: 30,
          ratio: 20,
          attack: 1,
          release: 0.5,
        },
      },
      data: { position: [-24, -365], name: 'COMP' },
    },
    bmSJpE3CIDeu: {
      id: 'bmSJpE3CIDeu',
      audioNode: { type: 'GainNode', params: { gain: 0.0001 } },
      data: { position: [-89, -296], name: 'GAN' },
    },
    '5MGQAsaqrNVW': {
      id: '5MGQAsaqrNVW',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-257, -216], name: 'RTM' },
    },
    IQyNz1wn6z8n: {
      id: 'IQyNz1wn6z8n',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [500, 0, 800, 700, 0, 700, 0] },
      },
      data: { position: [-112, -300], name: 'SEQ SYNTH OSC' },
    },
    HHpLMWQAbfFA: {
      id: 'HHpLMWQAbfFA',
      audioNode: {
        type: 'ADEnvelopeNode',
        params: { clock: 0, attack: 6, decay: 1, hold: false },
      },
      data: { position: [-106, -284], name: 'AD' },
    },
    '15cMS5uHgiPR': {
      id: '15cMS5uHgiPR',
      audioNode: {
        type: 'OscillatorNode',
        params: { frequency: 440, detune: -1200, type: 'sawtooth' },
      },
      data: { position: [-105, -299], name: 'OSC' },
    },
    '6OlB1nMoX5Q5': {
      id: '6OlB1nMoX5Q5',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 0, detune: 3500, Q: 8, gain: 1, type: 'highpass' },
      },
      data: { position: [-95, -297], name: 'FLT' },
    },
    xXNNORpMQoRg: {
      id: 'xXNNORpMQoRg',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [600, 200, -500, 900, 600, -200],
        },
      },
      data: { position: [-114, -285], name: 'SEQ' },
    },
    ytMldOSvKWbU: {
      id: 'ytMldOSvKWbU',
      audioNode: {
        type: 'BiquadFilterNode',
        params: {
          frequency: 1000,
          detune: 20000,
          Q: 9,
          gain: 0,
          type: 'lowpass',
        },
      },
      data: { position: [-100, -297], name: 'FLT' },
    },
    TAe77jcawygy: {
      id: 'TAe77jcawygy',
      audioNode: { type: 'GainNode', params: { gain: 0 } },
      data: { position: [-82, -296], name: 'GAN' },
    },
    wHdKO2CieD8s: {
      id: 'wHdKO2CieD8s',
      audioNode: {
        type: 'BiquadFilterNode',
        params: {
          frequency: 16000,
          detune: 0,
          Q: 10,
          gain: 0,
          type: 'lowpass',
        },
      },
      data: { position: [-72, -297], name: 'FLT' },
    },
    '3uj0wKaeitdX': {
      id: '3uj0wKaeitdX',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [2000, -3000, 5000, -1000, 8000],
        },
      },
      data: { position: [-75, -287], name: 'SEQ' },
    },
    KJFYyJl3uj7I: {
      id: 'KJFYyJl3uj7I',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 400, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-65, -295], name: 'FLT' },
    },
    '66HRMlChBbT1': {
      id: '66HRMlChBbT1',
      audioNode: { type: 'GainNode', params: { gain: 0.04 } },
      data: { position: [-19, -294], name: 'SYNTH PAD 1 END' },
    },
    ZPNf5KX5eA79: {
      id: 'ZPNf5KX5eA79',
      audioNode: {
        type: 'ConvolverNode',
        params: {
          buffer: '/GeX6fJzeNrK2wefqPuzWzw.wav',
          normalize: true,
        },
      },
      data: { position: [-77, -296], name: 'CNV' },
    },
    Qad8q7vkHTqR: {
      id: 'Qad8q7vkHTqR',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 350, detune: 0, Q: 1, gain: -7, type: 'peaking' },
      },
      data: { position: [-47, -518], name: 'FLT' },
    },
    '23OidW1ayI86': {
      id: '23OidW1ayI86',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 0.7,
          reverbTime: 0.9,
          amount: 0.5,
          gain: 0.2,
        },
      },
      data: { position: [-44, -296], name: 'RVB' },
    },
    WZ9YFvYSsEm8: {
      id: 'WZ9YFvYSsEm8',
      audioNode: { type: 'StereoPannerNode', params: { pan: 0 } },
      data: { position: [-50, -296], name: 'PAN' },
    },
    pa42JmNw65he: {
      id: 'pa42JmNw65he',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [12000, 1000, 9000, 1000, 12000, 10000],
        },
      },
      data: { position: [-72, -85], name: 'VOX MODULATOR SEQ' },
    },
    '6jzZelhbdCDT': {
      id: '6jzZelhbdCDT',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [900, 500, 700] },
      },
      data: { position: [-51, -82], name: 'VOX MODULATOR SEQ 2' },
    },
    ZtjNgo8BCclu: {
      id: 'ZtjNgo8BCclu',
      audioNode: { type: 'GainNode', params: { gain: 0 } },
      data: { position: [-88, -361], name: 'GAN' },
    },
    LLpykHYpXqh1: {
      id: 'LLpykHYpXqh1',
      audioNode: {
        type: 'ADEnvelopeNode',
        params: { clock: 0, attack: 0.001, decay: 0.2, hold: false },
      },
      data: { position: [-95, -375], name: 'AD' },
    },
    FR33j83dcLFX: {
      id: 'FR33j83dcLFX',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 350, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-28, -364], name: 'FLT' },
    },
    I73ovj1iCP8q: {
      id: 'I73ovj1iCP8q',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [600, 200, -500, 900, 600, -200],
        },
      },
      data: { position: [-83, -145], name: 'SEQ' },
    },
    wKxDK97kD3Jj: {
      id: 'wKxDK97kD3Jj',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        },
      },
      data: { position: [-126, -159], name: 'SEQUENCE CLOCK' },
    },
    R9fQNNBNEB7H: {
      id: 'R9fQNNBNEB7H',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [500, 0, 800, 700, 0, 700, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      },
      data: { position: [-93, -158], name: 'SEQ SYNTH OSC' },
    },
    RJ4SzHG87fQN: {
      id: 'RJ4SzHG87fQN',
      audioNode: {
        type: 'OscillatorNode',
        params: { frequency: 440, detune: 0, type: 'sawtooth' },
      },
      data: { position: [-82, -152], name: 'OSC' },
    },
    '5395MtgDUh22': {
      id: '5395MtgDUh22',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            1200,
            1200,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            -1200,
            -1200,
          ],
        },
      },
      data: { position: [-94, -154], name: 'SYNTH SEQ OKTAVA' },
    },
    fBcRvHVcXlZa: {
      id: 'fBcRvHVcXlZa',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 110, detune: 0, Q: 0, gain: 1, type: 'highpass' },
      },
      data: { position: [-69, -150], name: 'FLT' },
    },
    HMKjVapLhUTL: {
      id: 'HMKjVapLhUTL',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 19080, detune: 0, Q: 9, gain: 0, type: 'lowpass' },
      },
      data: { position: [-75, -150], name: 'FLT' },
    },
    C8q29UOSlbr8: {
      id: 'C8q29UOSlbr8',
      audioNode: { type: 'GainNode', params: { gain: 0.14 } },
      data: { position: [-12, -141], name: 'GAN' },
    },
    drmgGPLnnwua: {
      id: 'drmgGPLnnwua',
      audioNode: {
        type: 'ADEnvelopeNode',
        params: { clock: 0, attack: 0.001, decay: 0.1, hold: false },
      },
      data: { position: [-69, -136], name: 'AD' },
    },
    DKxCJ47HlhDN: {
      id: 'DKxCJ47HlhDN',
      audioNode: { type: 'GainNode', params: { gain: 0 } },
      data: { position: [-64, -149], name: 'GAN' },
    },
    '4pF1Xj5mUjBO': {
      id: '4pF1Xj5mUjBO',
      audioNode: { type: 'ChannelMergerNode', params: {} },
      data: { position: [-52, -150], name: 'CHM' },
    },
    bWqdY1CT8hTN: {
      id: 'bWqdY1CT8hTN',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.8,
          lp: 0.9,
          reverbTime: 0.6,
          amount: 0.3,
          gain: 0.5,
        },
      },
      data: { position: [-42, -150], name: 'RVB' },
    },
    TCKT2IPJi6k2: {
      id: 'TCKT2IPJi6k2',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [0, 0.3, 0, 0, 0, 0, 0, 0.2, 0, 0, 0, 0],
        },
      },
      data: { position: [-83, -133], name: 'SEQ' },
    },
    k2DQzyEx4j6T: {
      id: 'k2DQzyEx4j6T',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            12000,
            14000,
            16000,
            8000,
            10000,
            12000,
            5000,
            10000,
            2000,
            8000,
            12000,
            90000,
          ],
        },
      },
      data: { position: [-80, -164], name: 'SEQ' },
    },
    RWgYtdyddfxw: {
      id: 'RWgYtdyddfxw',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 0, detune: 0, Q: 1, gain: 0, type: 'lowpass' },
      },
      data: { position: [-47, -150], name: 'FLT' },
    },
    ghnTsjapd5rs: {
      id: 'ghnTsjapd5rs',
      audioNode: {
        type: 'RandomGateNode',
        params: { clock: 0, probability: 0.1 },
      },
      data: { position: [-116, -163], name: 'RND' },
    },
    p3rWWomwWtQi: {
      id: 'p3rWWomwWtQi',
      audioNode: { type: 'GainNode', params: { gain: 1.1 } },
      data: { position: [-6, -141], name: 'SEQENCER OUT' },
    },
    B96N9JT5jCol: {
      id: 'B96N9JT5jCol',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 400, detune: 0, Q: 1, gain: -6, type: 'peaking' },
      },
      data: { position: [-37, -295], name: 'FLT' },
    },
    wOZ3iivjoOn7: {
      id: 'wOZ3iivjoOn7',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/909clap.wav',
          clock: 0,
          detune: 200,
        },
      },
      data: { position: [-114, -357], name: 'SAMPLE SNARE 2' },
    },
    XNQdXf4xgtxA: {
      id: 'XNQdXf4xgtxA',
      audioNode: { type: 'GainNode', params: { gain: 0 } },
      data: { position: [-100, -363], name: 'SNARE 1 ON OFF' },
    },
    AAz0agNfiCTY: {
      id: 'AAz0agNfiCTY',
      audioNode: { type: 'GainNode', params: { gain: 2.5 } },
      data: { position: [-107, -357], name: 'GAN' },
    },
    '5xdexpdlEUCH': {
      id: '5xdexpdlEUCH',
      audioNode: { type: 'ChannelMergerNode', params: {} },
      data: { position: [-81, -360], name: 'CHM' },
    },
    sRlmkFaoNhGR: {
      id: 'sRlmkFaoNhGR',
      audioNode: { type: 'GainNode', params: { gain: 1 } },
      data: { position: [-85, -342], name: 'MOD SMPL SNR 1 ON OFF' },
    },
    k6GEzDrJKVpO: {
      id: 'k6GEzDrJKVpO',
      audioNode: { type: 'GainNode', params: { gain: 1 } },
      data: { position: [-99, -357], name: 'SNARE ON 2 OFF' },
    },
    mjCZ7In37Xa3: {
      id: 'mjCZ7In37Xa3',
      audioNode: {
        type: 'BiquadFilterNode',
        params: {
          frequency: 10000,
          detune: 0,
          Q: 1,
          gain: -9,
          type: 'highshelf',
        },
      },
      data: { position: [-94, -365], name: 'FLT' },
    },
    JBp2VjgGinuU: {
      id: 'JBp2VjgGinuU',
      audioNode: { type: 'WhiteNoiseNode', params: {} },
      data: { position: [-77, -74], name: 'NSE' },
    },
    '0wv3LbOYfx2r': {
      id: '0wv3LbOYfx2r',
      audioNode: { type: 'GainNode', params: { gain: 0.0005 } },
      data: { position: [-25, -63], name: 'FX TRACK OUT' },
    },
    yhsAJpGSGgH5: {
      id: 'yhsAJpGSGgH5',
      audioNode: { type: 'GainNode', params: { gain: 0 } },
      data: { position: [-72, -71], name: 'GAN' },
    },
    '8Y8WRj8h9G1O': {
      id: '8Y8WRj8h9G1O',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      },
      data: { position: [-84, -65], name: 'RTM FX' },
    },
    pq3VBFivKLNu: {
      id: 'pq3VBFivKLNu',
      audioNode: {
        type: 'ADEnvelopeNode',
        params: { clock: 0, attack: 7, decay: 0.1, hold: false },
      },
      data: { position: [-77, -68], name: 'AD NOISE' },
    },
    Aqh9dSX2F1dE: {
      id: 'Aqh9dSX2F1dE',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 1,
          reverbTime: 0.97,
          amount: 1,
          gain: 0.25,
        },
      },
      data: { position: [-33, -65], name: 'RVB' },
    },
    Df2KuJ6fLT79: {
      id: 'Df2KuJ6fLT79',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 12000, detune: 0, Q: 1, gain: 0, type: 'lowpass' },
      },
      data: { position: [-59, -71], name: 'FLT' },
    },
    zvuEc5iutrnF: {
      id: 'zvuEc5iutrnF',
      audioNode: { type: 'DelayNode', params: { delayTime: 0.222 } },
      data: { position: [-30, -155], name: 'SEQ DELAY' },
    },
    iM7yOShr9ZCw: {
      id: 'iM7yOShr9ZCw',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [1000, -1000, 750, -750, 0, 0],
        },
      },
      data: { position: [-29, -138], name: 'SEQ' },
    },
    fT5uC0tXaJ5A: {
      id: 'fT5uC0tXaJ5A',
      audioNode: { type: 'StereoPannerNode', params: { pan: 0 } },
      data: { position: [-24, -151], name: 'SEQ PAN' },
    },
    mqYk7izHF7WN: {
      id: 'mqYk7izHF7WN',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            1,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
            1,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-83, -54], name: 'RHYTHM DELAY REFERENCE' },
    },
    Hd1uTyZJu3nT: {
      id: 'Hd1uTyZJu3nT',
      audioNode: { type: 'GainNode', params: { gain: 0.2 } },
      data: { position: [-35, -151], name: 'GAN' },
    },
    m4oHDrr8hlIT: {
      id: 'm4oHDrr8hlIT',
      audioNode: {
        type: 'ResonatorNode',
        params: {
          frequency: 220,
          detune: 0,
          structure: 0.25,
          brightness: 0.5,
          damping: 0.3,
          position: 0.999,
        },
      },
      data: { position: [-65, -72], name: 'RES' },
    },
    HvdxavuB4LZv: {
      id: 'HvdxavuB4LZv',
      audioNode: {
        type: 'OscillatorNode',
        params: { frequency: 3, detune: 0, type: 'sine' },
      },
      data: { position: [-69, -19], name: 'OSC' },
    },
    lkrgkTOvvhT9: {
      id: 'lkrgkTOvvhT9',
      audioNode: { type: 'StereoPannerNode', params: { pan: 0 } },
      data: { position: [-49, -67], name: 'PAN' },
    },
    bpMZcX4x0mhM: {
      id: 'bpMZcX4x0mhM',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 500, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-42, -67], name: 'FLT' },
    },
    tcfc5Ivp4XV1: {
      id: 'tcfc5Ivp4XV1',
      audioNode: {
        type: 'SequenceNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [-12000, 12000, -10000, 10000],
        },
      },
      data: { position: [-60, -61], name: 'SEQ' },
    },
    itgdSvP1SHnn: {
      id: 'itgdSvP1SHnn',
      audioNode: { type: 'GainNode', params: { gain: 0.004 } },
      data: { position: [-31, -11], name: 'REVERSE SWEEP PIANO' },
    },
    '0fXNjsyoDPK3': {
      id: '0fXNjsyoDPK3',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            1,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-60, -23], name: 'RTM SWEEP' },
    },
    WqroUPkUDn0E: {
      id: 'WqroUPkUDn0E',
      audioNode: {
        type: 'ADEnvelopeNode',
        params: { clock: 0, attack: 7, decay: 0.1, hold: false },
      },
      data: { position: [-53, -24], name: 'AD NOISE' },
    },
    NhcXke6qnh6D: {
      id: 'NhcXke6qnh6D',
      audioNode: { type: 'GainNode', params: { gain: 0 } },
      data: { position: [-50, -13], name: 'GAN' },
    },
    nWuB1SGwLaUB: {
      id: 'nWuB1SGwLaUB',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 1,
          reverbTime: 0.95,
          amount: 1,
          gain: 0.5,
        },
      },
      data: { position: [-38, -13], name: 'RVB' },
    },
    nCZ94FqAKehN: {
      id: 'nCZ94FqAKehN',
      audioNode: {
        type: 'TriggeredSamplerNode',
        params: {
          buffer: '/DO3VAIjFEYhWk7kLTK38Qw.wav',
          clock: 0,
          detune: 3600,
        },
      },
      data: { position: [-69, -15], name: 'SMP' },
    },
    zFF3K2L2fqEr: {
      id: 'zFF3K2L2fqEr',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 1,
          reverbTime: 1,
          amount: 1,
          gain: 0.5,
        },
      },
      data: { position: [-64, -15], name: 'RVB' },
    },
    UWlKrOcmvVXO: {
      id: 'UWlKrOcmvVXO',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 600, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-45, -14], name: 'FLT' },
    },
    jdRJWEnronKf: {
      id: 'jdRJWEnronKf',
      audioNode: { type: 'StereoPannerNode', params: { pan: 0 } },
      data: { position: [-57, -13], name: 'PAN' },
    },
    iINmH5evXULx: {
      id: 'iINmH5evXULx',
      audioNode: {
        type: 'OscillatorNode',
        params: { frequency: 3, detune: 0, type: 'sine' },
      },
      data: { position: [-39, -56], name: 'OSC' },
    },
    Ddc8EOj4wigF: {
      id: 'Ddc8EOj4wigF',
      audioNode: { type: 'GainNode', params: { gain: 0.07 } },
      data: { position: [-27, -4], name: 'INTRO SWEEP' },
    },
    DRd78zKP3uTg: {
      id: 'DRd78zKP3uTg',
      audioNode: {
        type: 'ReverbNode',
        params: {
          diffusion: 0.625,
          lp: 0.7,
          reverbTime: 0.93,
          amount: 0.7,
          gain: 0.5,
        },
      },
      data: { position: [-46, -6], name: 'RVB' },
    },
    qEP4895CpmlE: {
      id: 'qEP4895CpmlE',
      audioNode: {
        type: 'ADEnvelopeNode',
        params: { clock: 0, attack: 3.4, decay: 0.1, hold: false },
      },
      data: { position: [-57, 4], name: 'INTRO AD' },
    },
    '14oKthuxYsBu': {
      id: '14oKthuxYsBu',
      audioNode: { type: 'GainNode', params: { gain: 0 } },
      data: { position: [-52, -5], name: 'GAN' },
    },
    rMuGAMzb30XF: {
      id: 'rMuGAMzb30XF',
      audioNode: { type: 'PitchShifterNode', params: { detune: -3600 } },
      data: { position: [-59, -5], name: 'PITCH INTRO MODULATION' },
    },
    mIx80nAKKbNf: {
      id: 'mIx80nAKKbNf',
      audioNode: {
        type: 'BiquadFilterNode',
        params: { frequency: 400, detune: 0, Q: 1, gain: 0, type: 'highpass' },
      },
      data: { position: [-35, -4], name: 'FLT' },
    },
    '0kUkD36PjCa9': {
      id: '0kUkD36PjCa9',
      audioNode: {
        type: 'RhythmNode',
        params: {
          clock: 0,
          reset: 0,
          sequence: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      },
      data: { position: [-63, 4], name: 'RTM INTRO' },
    },
    fR2oYvZc6hUX: {
      id: 'fR2oYvZc6hUX',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [1] },
      },
      data: { position: [-53, -177], name: 'SEQ PADS ON OFF' },
    },
    '7ArmcOQPLc9h': {
      id: '7ArmcOQPLc9h',
      audioNode: {
        type: 'SequenceNode',
        params: { clock: 0, reset: 0, sequence: [0] },
      },
      data: { position: [-85, -289], name: 'PAD1 ON OFF SEQ' },
    },
    wG9AgV2ZNGtI: {
      id: 'wG9AgV2ZNGtI',
      audioNode: {
        type: 'RhythmNode',
        params: { clock: 0, reset: 0, sequence: [0] },
      },
      data: { position: [-140, -203], name: 'PAD RESETTER' },
    },
  },
  edges: {
    BfpEBqXbLjtm: {
      id: 'BfpEBqXbLjtm',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'MjJ0DEuw5jpg', index: 0 },
    },
    '7SfFFqFzT07N': {
      id: '7SfFFqFzT07N',
      from: { type: 1, node: 'MjJ0DEuw5jpg', index: 0 },
      to: { type: 0, node: 'pQyHwBlCCi8v', index: 0 },
    },
    qikVD3uTS6as: {
      id: 'qikVD3uTS6as',
      from: { type: 1, node: '96EWMaMVO5Ux', index: 0 },
      to: { type: 0, node: 'pQyHwBlCCi8v', index: 1 },
    },
    Ia32cbNUw3Xi: {
      id: 'Ia32cbNUw3Xi',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: '96EWMaMVO5Ux', index: 0 },
    },
    DN2Eb4GisB5X: {
      id: 'DN2Eb4GisB5X',
      from: { type: 1, node: 'JFN7p82T4N0j', index: 0 },
      to: { type: 0, node: 'iGj5LJWxS8je', index: 0 },
    },
    f5yTSmP4FDBO: {
      id: 'f5yTSmP4FDBO',
      from: { type: 1, node: 'iGj5LJWxS8je', index: 0 },
      to: { type: 0, node: 'ivCoN2VVjZSN', index: 0 },
    },
    '7Vqf5yZ1YJas': {
      id: '7Vqf5yZ1YJas',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'Dh6FoQ7nWHs9', index: 0 },
    },
    '6B7ucsCtbSLE': {
      id: '6B7ucsCtbSLE',
      from: { type: 1, node: 'Dh6FoQ7nWHs9', index: 0 },
      to: { type: 0, node: 'Ix9lSVf8jnoe', index: 0 },
    },
    jc5po68u0Go3: {
      id: 'jc5po68u0Go3',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'JnRqDvagyW7p', index: 0 },
    },
    HeNVD21msM9F: {
      id: 'HeNVD21msM9F',
      from: { type: 1, node: '3zN4BuNkiBZn', index: 0 },
      to: { type: 0, node: 'ZzoXngovckug', index: 0 },
    },
    BfsgaeAx6yGP: {
      id: 'BfsgaeAx6yGP',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: '3zN4BuNkiBZn', index: 0 },
    },
    '2p5q33VSt4Gp': {
      id: '2p5q33VSt4Gp',
      from: { type: 1, node: 'ACjOqUilb7wZ', index: 0 },
      to: { type: 0, node: 'NlgR2mYIeGfR', index: 1 },
    },
    '6U886pFR4ysg': {
      id: '6U886pFR4ysg',
      from: { type: 1, node: 'Dh6FoQ7nWHs9', index: 0 },
      to: { type: 0, node: 'ACjOqUilb7wZ', index: 0 },
    },
    giisA9YGpHa7: {
      id: 'giisA9YGpHa7',
      from: { type: 1, node: 'NlgR2mYIeGfR', index: 0 },
      to: { type: 0, node: '5W7jLwgwIkvC', index: 0 },
    },
    EijzZz4KxSIC: {
      id: 'EijzZz4KxSIC',
      from: { type: 1, node: 'ikKWqA0cGN4u', index: 0 },
      to: { type: 0, node: 't48vNOxxsY5I', index: 0 },
    },
    s6ydcowki8NT: {
      id: 's6ydcowki8NT',
      from: { type: 1, node: 't48vNOxxsY5I', index: 0 },
      to: { type: 0, node: 'FeRCVX0Frp7j', index: 0 },
    },
    LLKeLPtw2Sop: {
      id: 'LLKeLPtw2Sop',
      from: { type: 1, node: 'QePlxvl6aV85', index: 0 },
      to: { type: 0, node: 'pJiJOf2SlDNQ', index: 0 },
    },
    OnNYzSbeOgJe: {
      id: 'OnNYzSbeOgJe',
      from: { type: 1, node: 'ShgkxSVAKBr7', index: 0 },
      to: { type: 0, node: '6EY22LD9KpIM', index: 0 },
    },
    ID61axpAVv2e: {
      id: 'ID61axpAVv2e',
      from: { type: 1, node: 'iGj5LJWxS8je', index: 0 },
      to: { type: 0, node: 'QePlxvl6aV85', index: 0 },
    },
    lsx4OGf2CA8o: {
      id: 'lsx4OGf2CA8o',
      from: { type: 1, node: '6EY22LD9KpIM', index: 0 },
      to: { type: 0, node: 'UeInoFH7qIvH', index: 0 },
    },
    '4LvY3iD6qOD0': {
      id: '4LvY3iD6qOD0',
      from: { type: 1, node: 'TN6cCI6lLDR1', index: 0 },
      to: { type: 0, node: 'UeInoFH7qIvH', index: 1 },
    },
    jn6VD5C5cc0s: {
      id: 'jn6VD5C5cc0s',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'TN6cCI6lLDR1', index: 0 },
    },
    R53BP73qXU7u: {
      id: 'R53BP73qXU7u',
      from: { type: 1, node: 'XZ4aMLtRgb3S', index: 0 },
      to: { type: 0, node: 'ivCoN2VVjZSN', index: 0 },
    },
    jOEtnw2fXNf8: {
      id: 'jOEtnw2fXNf8',
      from: { type: 1, node: 'TN6cCI6lLDR1', index: 0 },
      to: { type: 0, node: 'XZ4aMLtRgb3S', index: 1 },
    },
    lAR2kNHsFh6l: {
      id: 'lAR2kNHsFh6l',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'TqvLloc5UjR9', index: 0 },
    },
    aSP9y4khfTN5: {
      id: 'aSP9y4khfTN5',
      from: { type: 1, node: 'TqvLloc5UjR9', index: 0 },
      to: { type: 0, node: 'CSNoT8X2aR6i', index: 0 },
    },
    pWlYaKi79klk: {
      id: 'pWlYaKi79klk',
      from: { type: 1, node: 'JfGI7qB3Pt1C', index: 0 },
      to: { type: 0, node: 'JKIXAxwpieov', index: 0 },
    },
    WHV6FwAnJhAm: {
      id: 'WHV6FwAnJhAm',
      from: { type: 1, node: 'JKIXAxwpieov', index: 0 },
      to: { type: 0, node: 'H7eQEOWeV6HL', index: 3 },
    },
    QcSJ40oj2Ibn: {
      id: 'QcSJ40oj2Ibn',
      from: { type: 1, node: 'W3uSO5Bvc4jb', index: 0 },
      to: { type: 0, node: 'DwX6aPRnrGxB', index: 1 },
    },
    '296uQ3HidBSW': {
      id: '296uQ3HidBSW',
      from: { type: 1, node: 'TqvLloc5UjR9', index: 0 },
      to: { type: 0, node: 'W3uSO5Bvc4jb', index: 0 },
    },
    VOPK6RQABHFz: {
      id: 'VOPK6RQABHFz',
      from: { type: 1, node: 'JKIXAxwpieov', index: 0 },
      to: { type: 0, node: 'W3uSO5Bvc4jb', index: 2 },
    },
    b1XK7fKgaZfv: {
      id: 'b1XK7fKgaZfv',
      from: { type: 1, node: 'H7eQEOWeV6HL', index: 0 },
      to: { type: 0, node: 'roK2PFcV8Ubc', index: 0 },
    },
    ogrLM23cTApR: {
      id: 'ogrLM23cTApR',
      from: { type: 1, node: 'roK2PFcV8Ubc', index: 0 },
      to: { type: 0, node: 'mMdRlRiP9kHT', index: 0 },
    },
    '7hNOUYGGyJz3': {
      id: '7hNOUYGGyJz3',
      from: { type: 1, node: 'mMdRlRiP9kHT', index: 0 },
      to: { type: 0, node: 'DwX6aPRnrGxB', index: 0 },
    },
    Lh0AAvMkH7tm: {
      id: 'Lh0AAvMkH7tm',
      from: { type: 1, node: 'JnRqDvagyW7p', index: 0 },
      to: { type: 0, node: 'TwY0W29nZ3Uj', index: 0 },
    },
    O5bxVwOfTguO: {
      id: 'O5bxVwOfTguO',
      from: { type: 1, node: 'TwY0W29nZ3Uj', index: 0 },
      to: { type: 0, node: 'roK2PFcV8Ubc', index: 1 },
    },
    fJZ3g8CqMiAX: {
      id: 'fJZ3g8CqMiAX',
      from: { type: 1, node: 'TwY0W29nZ3Uj', index: 0 },
      to: { type: 0, node: 'mMdRlRiP9kHT', index: 1 },
    },
    '5vfirfKwemHy': {
      id: '5vfirfKwemHy',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'H0jYihAkzQXn', index: 0 },
    },
    z48jy7lqjCV9: {
      id: 'z48jy7lqjCV9',
      from: { type: 1, node: 'FZ4Frgp6EdOT', index: 0 },
      to: { type: 0, node: 'oBeABfwlnE7F', index: 0 },
    },
    eXW19ZK0nqGd: {
      id: 'eXW19ZK0nqGd',
      from: { type: 1, node: 'V1NpR3qQYXs5', index: 0 },
      to: { type: 0, node: 'CeKxgK10vPN6', index: 0 },
    },
    '2fMqy14eMw8X': {
      id: '2fMqy14eMw8X',
      from: { type: 1, node: 'v3V3wKdzu2pb', index: 0 },
      to: { type: 0, node: 'oBeABfwlnE7F', index: 0 },
    },
    vVKAt3tUBFwe: {
      id: 'vVKAt3tUBFwe',
      from: { type: 1, node: 'CeKxgK10vPN6', index: 0 },
      to: { type: 0, node: 'v3V3wKdzu2pb', index: 0 },
    },
    '5W3XtxlC2cdC': {
      id: '5W3XtxlC2cdC',
      from: { type: 1, node: '53X5uLHgMoir', index: 0 },
      to: { type: 0, node: 'CeKxgK10vPN6', index: 4 },
    },
    uMuZQta0Sn9b: {
      id: 'uMuZQta0Sn9b',
      from: { type: 1, node: '53X5uLHgMoir', index: 0 },
      to: { type: 0, node: 'CeKxgK10vPN6', index: 1 },
    },
    Z1LeNt4B7aMJ: {
      id: 'Z1LeNt4B7aMJ',
      from: { type: 1, node: '53X5uLHgMoir', index: 0 },
      to: { type: 0, node: 'CeKxgK10vPN6', index: 2 },
    },
    ImMhk5cE0aKZ: {
      id: 'ImMhk5cE0aKZ',
      from: { type: 1, node: '53X5uLHgMoir', index: 0 },
      to: { type: 0, node: 'CeKxgK10vPN6', index: 3 },
    },
    kGMrrwzgYwDU: {
      id: 'kGMrrwzgYwDU',
      from: { type: 1, node: '53X5uLHgMoir', index: 0 },
      to: { type: 0, node: 'CeKxgK10vPN6', index: 5 },
    },
    oqpyaCVnz62m: {
      id: 'oqpyaCVnz62m',
      from: { type: 1, node: 'H0jYihAkzQXn', index: 0 },
      to: { type: 0, node: '1fvTI3pSxnUg', index: 0 },
    },
    qq97ZrP8opj1: {
      id: 'qq97ZrP8opj1',
      from: { type: 1, node: '1fvTI3pSxnUg', index: 0 },
      to: { type: 0, node: '53X5uLHgMoir', index: 0 },
    },
    ldXaYbkyel0u: {
      id: 'ldXaYbkyel0u',
      from: { type: 1, node: 'HwAVSAm2ajm8', index: 0 },
      to: { type: 0, node: '53X5uLHgMoir', index: 1 },
    },
    GcNV2nf89YIr: {
      id: 'GcNV2nf89YIr',
      from: { type: 1, node: 'H0jYihAkzQXn', index: 0 },
      to: { type: 0, node: 'erz9Y7C35gJL', index: 0 },
    },
    HzVcVshJ08Z4: {
      id: 'HzVcVshJ08Z4',
      from: { type: 1, node: 'erz9Y7C35gJL', index: 0 },
      to: { type: 0, node: 'oBeABfwlnE7F', index: 1 },
    },
    IfM86tRTCl0i: {
      id: 'IfM86tRTCl0i',
      from: { type: 1, node: 'H0jYihAkzQXn', index: 0 },
      to: { type: 0, node: '483w7AjLcCK8', index: 0 },
    },
    wLv6GLqOWG5J: {
      id: 'wLv6GLqOWG5J',
      from: { type: 1, node: '483w7AjLcCK8', index: 0 },
      to: { type: 0, node: 'V1NpR3qQYXs5', index: 1 },
    },
    fQFJ5LLIcWb6: {
      id: 'fQFJ5LLIcWb6',
      from: { type: 1, node: 'oBeABfwlnE7F', index: 0 },
      to: { type: 0, node: 'LRfgZgrBFEFY', index: 0 },
    },
    YekuiEF7iBpP: {
      id: 'YekuiEF7iBpP',
      from: { type: 1, node: 'LRfgZgrBFEFY', index: 0 },
      to: { type: 0, node: 'eMF4Bv7bakuR', index: 0 },
    },
    ZHenMPYGORYE: {
      id: 'ZHenMPYGORYE',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'GaZekPuRdo9M', index: 0 },
    },
    GOuKi5zRR6Bu: {
      id: 'GOuKi5zRR6Bu',
      from: { type: 1, node: 'SiEwTZZvLtPV', index: 0 },
      to: { type: 0, node: 'E4MemSm2PdKF', index: 1 },
    },
    S8ML7hb1ZFN6: {
      id: 'S8ML7hb1ZFN6',
      from: { type: 1, node: 'GaZekPuRdo9M', index: 0 },
      to: { type: 0, node: 'SiEwTZZvLtPV', index: 0 },
    },
    '4rYe9Y55ewD3': {
      id: '4rYe9Y55ewD3',
      from: { type: 1, node: '2VqoF91Gwa0F', index: 0 },
      to: { type: 0, node: 'jVtdzPQn8tRn', index: 0 },
    },
    gxeB05UOwhnU: {
      id: 'gxeB05UOwhnU',
      from: { type: 1, node: 'GaZekPuRdo9M', index: 0 },
      to: { type: 0, node: 'W8ojWHlZ2rPk', index: 0 },
    },
    GP8T5JCldQlT: {
      id: 'GP8T5JCldQlT',
      from: { type: 1, node: 'W8ojWHlZ2rPk', index: 0 },
      to: { type: 0, node: '2VqoF91Gwa0F', index: 1 },
    },
    TxaGM4BzHt5Z: {
      id: 'TxaGM4BzHt5Z',
      from: { type: 1, node: 'W8ojWHlZ2rPk', index: 0 },
      to: { type: 0, node: 'PjQpHSu8CL5Z', index: 0 },
    },
    UUcTbN8IqTfT: {
      id: 'UUcTbN8IqTfT',
      from: { type: 1, node: 'jiMee9SKripV', index: 0 },
      to: { type: 0, node: 'RAOMDTNwvJ2j', index: 0 },
    },
    PSI8DVbAOYcM: {
      id: 'PSI8DVbAOYcM',
      from: { type: 1, node: 'pQyHwBlCCi8v', index: 0 },
      to: { type: 0, node: 'JFN7p82T4N0j', index: 0 },
    },
    ypAhS874puCj: {
      id: 'ypAhS874puCj',
      from: { type: 1, node: 'Ix9lSVf8jnoe', index: 0 },
      to: { type: 0, node: 'NlgR2mYIeGfR', index: 0 },
    },
    GKm2WDWKgmS1: {
      id: 'GKm2WDWKgmS1',
      from: { type: 1, node: 'RAOMDTNwvJ2j', index: 0 },
      to: { type: 0, node: 'BkkdIjPWdlJB', index: 0 },
    },
    Aet4gog8RcWV: {
      id: 'Aet4gog8RcWV',
      from: { type: 1, node: 'ACjOqUilb7wZ', index: 0 },
      to: { type: 0, node: 'BkkdIjPWdlJB', index: 1 },
    },
    GnD39QFSsbRm: {
      id: 'GnD39QFSsbRm',
      from: { type: 1, node: '5fSOiZX7mzit', index: 0 },
      to: { type: 0, node: '2VqoF91Gwa0F', index: 0 },
    },
    '4IxEMJfHd1QR': {
      id: '4IxEMJfHd1QR',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: '1kmUx3Ql0qch', index: 0 },
    },
    w6igI7bCEZxn: {
      id: 'w6igI7bCEZxn',
      from: { type: 1, node: '1kmUx3Ql0qch', index: 0 },
      to: { type: 0, node: 'SiEwTZZvLtPV', index: 0 },
    },
    pyvrFCUbE2Yj: {
      id: 'pyvrFCUbE2Yj',
      from: { type: 1, node: 'PjQpHSu8CL5Z', index: 0 },
      to: { type: 0, node: 'SiEwTZZvLtPV', index: 2 },
    },
    ZqjdhSKjhrNc: {
      id: 'ZqjdhSKjhrNc',
      from: { type: 1, node: 'OILApVhTP6rJ', index: 0 },
      to: { type: 0, node: 'ivCoN2VVjZSN', index: 1 },
    },
    aRJA6XbOINi0: {
      id: 'aRJA6XbOINi0',
      from: { type: 1, node: 'MjJ0DEuw5jpg', index: 0 },
      to: { type: 0, node: 'OILApVhTP6rJ', index: 0 },
    },
    rDAiiJ0iqewC: {
      id: 'rDAiiJ0iqewC',
      from: { type: 1, node: 'GaZekPuRdo9M', index: 0 },
      to: { type: 0, node: 'e8ABNigDCYop', index: 0 },
    },
    bSpCbiPNgAxH: {
      id: 'bSpCbiPNgAxH',
      from: { type: 1, node: 'e8ABNigDCYop', index: 0 },
      to: { type: 0, node: 'Cvo5UoS6mWiG', index: 0 },
    },
    '7pqmY7tj3sJX': {
      id: '7pqmY7tj3sJX',
      from: { type: 1, node: 'Cvo5UoS6mWiG', index: 0 },
      to: { type: 0, node: 'BkkdIjPWdlJB', index: 3 },
    },
    IdWC5ZQj4Oh3: {
      id: 'IdWC5ZQj4Oh3',
      from: { type: 1, node: 'Cvo5UoS6mWiG', index: 0 },
      to: { type: 0, node: 'BkkdIjPWdlJB', index: 1 },
    },
    '9YQHb4fgh5Oa': {
      id: '9YQHb4fgh5Oa',
      from: { type: 1, node: '1fvTI3pSxnUg', index: 0 },
      to: { type: 0, node: 'PjbB5oMgI7CU', index: 0 },
    },
    i0dk2ixFFOR1: {
      id: 'i0dk2ixFFOR1',
      from: { type: 1, node: 'PjbB5oMgI7CU', index: 0 },
      to: { type: 0, node: 'erz9Y7C35gJL', index: 2 },
    },
    F1aCY2KYvh63: {
      id: 'F1aCY2KYvh63',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'ZiwYtG60o1Ts', index: 0 },
    },
    oqPwMMeYDrvP: {
      id: 'oqPwMMeYDrvP',
      from: { type: 1, node: 'ZiwYtG60o1Ts', index: 0 },
      to: { type: 0, node: 'TwhF67Pypisq', index: 0 },
    },
    YRiFdpe6vw7I: {
      id: 'YRiFdpe6vw7I',
      from: { type: 1, node: 'TwhF67Pypisq', index: 0 },
      to: { type: 0, node: 'j17sUIM19m6z', index: 1 },
    },
    '4eRIR10J4aCx': {
      id: '4eRIR10J4aCx',
      from: { type: 1, node: '3xUWR0KOdPTM', index: 0 },
      to: { type: 0, node: 'XcsBDsAxnNKC', index: 0 },
    },
    '3eCwdvXF8MLQ': {
      id: '3eCwdvXF8MLQ',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'EQEQdRpBqm5t', index: 0 },
    },
    fR7U3Z8fn0Zl: {
      id: 'fR7U3Z8fn0Zl',
      from: { type: 1, node: '5W7jLwgwIkvC', index: 0 },
      to: { type: 0, node: 'pcVOI0mGUb8l', index: 0 },
    },
    '3a3e0TlsrBVN': {
      id: '3a3e0TlsrBVN',
      from: { type: 1, node: 'DwX6aPRnrGxB', index: 0 },
      to: { type: 0, node: 'N1JW2JOi5ol3', index: 0 },
    },
    IGetQRptEbFs: {
      id: 'IGetQRptEbFs',
      from: { type: 1, node: 'ivCoN2VVjZSN', index: 0 },
      to: { type: 0, node: 'mpcQH4RagLhr', index: 0 },
    },
    xilY0bNZmLpm: {
      id: 'xilY0bNZmLpm',
      from: { type: 1, node: 'x7Gg38aRDicF', index: 0 },
      to: { type: 0, node: '75y5pKi781r0', index: 0 },
    },
    nBJHgZDNjEX8: {
      id: 'nBJHgZDNjEX8',
      from: { type: 1, node: 'YFGrqaLM4g1g', index: 0 },
      to: { type: 0, node: 'nCy4JihrwBbb', index: 0 },
    },
    meOwZfShFHE2: {
      id: 'meOwZfShFHE2',
      from: { type: 1, node: 'SsgqtJr24Ny3', index: 0 },
      to: { type: 0, node: 'nkB2gAX8vNlY', index: 0 },
    },
    CQ8I1ilCCUvt: {
      id: 'CQ8I1ilCCUvt',
      from: { type: 1, node: 'XcsBDsAxnNKC', index: 0 },
      to: { type: 0, node: 'JeKtz67rTJAd', index: 0 },
    },
    tYMokmD6vBab: {
      id: 'tYMokmD6vBab',
      from: { type: 1, node: 'JeKtz67rTJAd', index: 0 },
      to: { type: 0, node: 'ZVcQJRbGCQw3', index: 0 },
    },
    BgKDGrxnxm6N: {
      id: 'BgKDGrxnxm6N',
      from: { type: 1, node: 'nCy4JihrwBbb', index: 0 },
      to: { type: 0, node: 'NFhdGuIYCzke', index: 0 },
    },
    EfojaFn9TQuo: {
      id: 'EfojaFn9TQuo',
      from: { type: 1, node: 'NFhdGuIYCzke', index: 0 },
      to: { type: 0, node: 'SsgqtJr24Ny3', index: 0 },
    },
    yp1AEziHbu7K: {
      id: 'yp1AEziHbu7K',
      from: { type: 1, node: 'JnRqDvagyW7p', index: 0 },
      to: { type: 0, node: 'ri5K35PPibFL', index: 0 },
    },
    '6XoNkBnD7Tux': {
      id: '6XoNkBnD7Tux',
      from: { type: 1, node: 'ri5K35PPibFL', index: 0 },
      to: { type: 0, node: '1Z3vnZtvuLad', index: 0 },
    },
    pb47cI88R44N: {
      id: 'pb47cI88R44N',
      from: { type: 1, node: 'X92WUnRbWU3R', index: 0 },
      to: { type: 0, node: 'uKYq6BcNBMzG', index: 0 },
    },
    HsSmu7iw5j8O: {
      id: 'HsSmu7iw5j8O',
      from: { type: 1, node: 'pJiJOf2SlDNQ', index: 0 },
      to: { type: 0, node: 'ShgkxSVAKBr7', index: 0 },
    },
    QnCQf06hlkmX: {
      id: 'QnCQf06hlkmX',
      from: { type: 1, node: 'mpcQH4RagLhr', index: 0 },
      to: { type: 0, node: 'INcru5tDlJYR', index: 0 },
    },
    cnBfc7vrbgLk: {
      id: 'cnBfc7vrbgLk',
      from: { type: 1, node: 'pcVOI0mGUb8l', index: 0 },
      to: { type: 0, node: 'INcru5tDlJYR', index: 0 },
    },
    AvsYTSuI6hqk: {
      id: 'AvsYTSuI6hqk',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'dVycsrhekZwR', index: 0 },
    },
    '3ZAe7DMbLjyX': {
      id: '3ZAe7DMbLjyX',
      from: { type: 1, node: 'dVycsrhekZwR', index: 0 },
      to: { type: 0, node: 'GiY1N5NltkrZ', index: 0 },
    },
    UZ8RbOOpCA9e: {
      id: 'UZ8RbOOpCA9e',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'nHJ8SgkCRFnA', index: 0 },
    },
    rHZIcq1Nh03P: {
      id: 'rHZIcq1Nh03P',
      from: { type: 1, node: 'NrzEpem4itPA', index: 0 },
      to: { type: 0, node: 'ZVcQJRbGCQw3', index: 1 },
    },
    BJRjqy817YJt: {
      id: 'BJRjqy817YJt',
      from: { type: 1, node: 'nHJ8SgkCRFnA', index: 0 },
      to: { type: 0, node: 'NrzEpem4itPA', index: 0 },
    },
    vy21anwk0yMY: {
      id: 'vy21anwk0yMY',
      from: { type: 1, node: 'UeInoFH7qIvH', index: 0 },
      to: { type: 0, node: 'Q9lSJCnKkhVw', index: 0 },
    },
    MoxQyICwjxP5: {
      id: 'MoxQyICwjxP5',
      from: { type: 1, node: '75y5pKi781r0', index: 0 },
      to: { type: 0, node: 'YFGrqaLM4g1g', index: 0 },
    },
    oIf6buvFHFwX: {
      id: 'oIf6buvFHFwX',
      from: { type: 1, node: 'nHJ8SgkCRFnA', index: 0 },
      to: { type: 0, node: 'Qy3uqnIbfO9S', index: 0 },
    },
    '2LbCkMcdvbjy': {
      id: '2LbCkMcdvbjy',
      from: { type: 1, node: 'tj2AQrrx66Wq', index: 0 },
      to: { type: 0, node: 'pWzHgPrhP8cY', index: 0 },
    },
    iM1BJIR6JN8f: {
      id: 'iM1BJIR6JN8f',
      from: { type: 1, node: 'pWzHgPrhP8cY', index: 0 },
      to: { type: 0, node: 'RGi8X0OCMRqr', index: 0 },
    },
    wtUPDUj9ou8X: {
      id: 'wtUPDUj9ou8X',
      from: { type: 1, node: 'RGi8X0OCMRqr', index: 0 },
      to: { type: 0, node: 'R8ffBwvZImrH', index: 0 },
    },
    F75fHfEV8bj8: {
      id: 'F75fHfEV8bj8',
      from: { type: 1, node: 'R8ffBwvZImrH', index: 0 },
      to: { type: 0, node: '53IffwXWFmT8', index: 0 },
    },
    t7cALvvirRpS: {
      id: 't7cALvvirRpS',
      from: { type: 1, node: 'Qy3uqnIbfO9S', index: 0 },
      to: { type: 0, node: 'ANFDk8C9nhVg', index: 0 },
    },
    '3mII44ReAOlU': {
      id: '3mII44ReAOlU',
      from: { type: 1, node: 'ANFDk8C9nhVg', index: 0 },
      to: { type: 0, node: 'tj2AQrrx66Wq', index: 0 },
    },
    czh8OlhdOPsc: {
      id: 'czh8OlhdOPsc',
      from: { type: 1, node: 'TN6cCI6lLDR1', index: 0 },
      to: { type: 0, node: 'pWzHgPrhP8cY', index: 1 },
    },
    Gt55LKpEjDJU: {
      id: 'Gt55LKpEjDJU',
      from: { type: 1, node: 'TN6cCI6lLDR1', index: 0 },
      to: { type: 0, node: 'RGi8X0OCMRqr', index: 2 },
    },
    W2WIx9sD1xrO: {
      id: 'W2WIx9sD1xrO',
      from: { type: 1, node: 'D27CsBonmtiB', index: 0 },
      to: { type: 0, node: 'M9NSemcFsNMM', index: 0 },
    },
    lULhogG7l7QG: {
      id: 'lULhogG7l7QG',
      from: { type: 1, node: 'aSdDL2GHQ1HZ', index: 0 },
      to: { type: 0, node: 'pWzHgPrhP8cY', index: 0 },
    },
    B0EaSsBP5ic0: {
      id: 'B0EaSsBP5ic0',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'D27CsBonmtiB', index: 0 },
    },
    ZxFtyKhHb1sS: {
      id: 'ZxFtyKhHb1sS',
      from: { type: 1, node: 'M9NSemcFsNMM', index: 0 },
      to: { type: 0, node: 'ATqrrIuZqWwT', index: 0 },
    },
    KED5FMiVjGOM: {
      id: 'KED5FMiVjGOM',
      from: { type: 1, node: 'ATqrrIuZqWwT', index: 0 },
      to: { type: 0, node: '9DLqsZtlwgcF', index: 0 },
    },
    Aff7PTJMk4VE: {
      id: 'Aff7PTJMk4VE',
      from: { type: 1, node: '9DLqsZtlwgcF', index: 0 },
      to: { type: 0, node: 'aSdDL2GHQ1HZ', index: 0 },
    },
    vnaXRSgHi1Ig: {
      id: 'vnaXRSgHi1Ig',
      from: { type: 1, node: 'lpLdsQENXH3P', index: 0 },
      to: { type: 0, node: 'M9NSemcFsNMM', index: 1 },
    },
    IkBpeapvqUmF: {
      id: 'IkBpeapvqUmF',
      from: { type: 1, node: 'D27CsBonmtiB', index: 0 },
      to: { type: 0, node: 'lpLdsQENXH3P', index: 0 },
    },
    dUvXUkfiyX0u: {
      id: 'dUvXUkfiyX0u',
      from: { type: 1, node: 'cVqvJJzDWYCf', index: 0 },
      to: { type: 0, node: 'VBWFfAw3Cx93', index: 0 },
    },
    ctn0jP6IWpp8: {
      id: 'ctn0jP6IWpp8',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'cVqvJJzDWYCf', index: 0 },
    },
    rRX22FWDt2YM: {
      id: 'rRX22FWDt2YM',
      from: { type: 1, node: 'dp4WKlCWMHrM', index: 0 },
      to: { type: 0, node: 'Y8ldcF0pWe98', index: 0 },
    },
    cqft4cIqGOzi: {
      id: 'cqft4cIqGOzi',
      from: { type: 1, node: 'VBWFfAw3Cx93', index: 0 },
      to: { type: 0, node: '8G5e77PubEsl', index: 0 },
    },
    XE0e071zppUx: {
      id: 'XE0e071zppUx',
      from: { type: 1, node: '8G5e77PubEsl', index: 0 },
      to: { type: 0, node: 'dp4WKlCWMHrM', index: 0 },
    },
    uW7WtznXUXFp: {
      id: 'uW7WtznXUXFp',
      from: { type: 1, node: '8G5e77PubEsl', index: 0 },
      to: { type: 0, node: 'draPZaV1g3tR', index: 0 },
    },
    vl9JE1UgFelh: {
      id: 'vl9JE1UgFelh',
      from: { type: 1, node: 'draPZaV1g3tR', index: 0 },
      to: { type: 0, node: 'dp4WKlCWMHrM', index: 0 },
    },
    QpDU1NsuYXGO: {
      id: 'QpDU1NsuYXGO',
      from: { type: 1, node: 'uKYq6BcNBMzG', index: 0 },
      to: { type: 0, node: 'E4MemSm2PdKF', index: 0 },
    },
    '0BkoaJIWrZYW': {
      id: '0BkoaJIWrZYW',
      from: { type: 1, node: 'BkkdIjPWdlJB', index: 0 },
      to: { type: 0, node: 'ygLx4JFDnFeN', index: 0 },
    },
    MvNziyYXO9Mt: {
      id: 'MvNziyYXO9Mt',
      from: { type: 1, node: 'nbcClsRtGK0d', index: 0 },
      to: { type: 0, node: 'uKYq6BcNBMzG', index: 0 },
    },
    zjBIWSsEPjTs: {
      id: 'zjBIWSsEPjTs',
      from: { type: 1, node: '1L8ckpJhblQ0', index: 0 },
      to: { type: 0, node: 'uKYq6BcNBMzG', index: 0 },
    },
    '7nceqwABTPO3': {
      id: '7nceqwABTPO3',
      from: { type: 1, node: '1baCRqg3oxtz', index: 0 },
      to: { type: 0, node: 'j17sUIM19m6z', index: 0 },
    },
    ZV0ETtLwKHKb: {
      id: 'ZV0ETtLwKHKb',
      from: { type: 1, node: 'EQEQdRpBqm5t', index: 0 },
      to: { type: 0, node: 'D9eT1RfolTkS', index: 0 },
    },
    hKH41HNsMF9L: {
      id: 'hKH41HNsMF9L',
      from: { type: 1, node: 'EQEQdRpBqm5t', index: 0 },
      to: { type: 0, node: 'kcYwsGX5GMjO', index: 0 },
    },
    jc81VQLa6Xrk: {
      id: 'jc81VQLa6Xrk',
      from: { type: 1, node: 'EQEQdRpBqm5t', index: 0 },
      to: { type: 0, node: 'i7oKK5FGT4Ms', index: 0 },
    },
    XdwaEuZxfLWU: {
      id: 'XdwaEuZxfLWU',
      from: { type: 1, node: 'EQEQdRpBqm5t', index: 0 },
      to: { type: 0, node: '9nDWkFSoHTUH', index: 0 },
    },
    XkPOuL3rIWdw: {
      id: 'XkPOuL3rIWdw',
      from: { type: 1, node: '9nDWkFSoHTUH', index: 0 },
      to: { type: 0, node: 'x7Gg38aRDicF', index: 0 },
    },
    yuzdivsp4jKx: {
      id: 'yuzdivsp4jKx',
      from: { type: 1, node: 'i7oKK5FGT4Ms', index: 0 },
      to: { type: 0, node: 'x7Gg38aRDicF', index: 0 },
    },
    sTls0fla3Ith: {
      id: 'sTls0fla3Ith',
      from: { type: 1, node: 'kcYwsGX5GMjO', index: 0 },
      to: { type: 0, node: 'x7Gg38aRDicF', index: 0 },
    },
    HDaJJKyC3xir: {
      id: 'HDaJJKyC3xir',
      from: { type: 1, node: 'D9eT1RfolTkS', index: 0 },
      to: { type: 0, node: 'x7Gg38aRDicF', index: 0 },
    },
    ld8tXNXOZSeC: {
      id: 'ld8tXNXOZSeC',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: '5MGQAsaqrNVW', index: 0 },
    },
    eydijtutnus7: {
      id: 'eydijtutnus7',
      from: { type: 1, node: '5MGQAsaqrNVW', index: 0 },
      to: { type: 0, node: 'HHpLMWQAbfFA', index: 0 },
    },
    '8MBR3uTvNVMy': {
      id: '8MBR3uTvNVMy',
      from: { type: 1, node: 'IQyNz1wn6z8n', index: 0 },
      to: { type: 0, node: '15cMS5uHgiPR', index: 1 },
    },
    raMnAstKJa4u: {
      id: 'raMnAstKJa4u',
      from: { type: 1, node: 'HHpLMWQAbfFA', index: 0 },
      to: { type: 0, node: 'bmSJpE3CIDeu', index: 1 },
    },
    nQnPMdmSe4px: {
      id: 'nQnPMdmSe4px',
      from: { type: 1, node: '6OlB1nMoX5Q5', index: 0 },
      to: { type: 0, node: 'bmSJpE3CIDeu', index: 0 },
    },
    plvcmSp2MiFg: {
      id: 'plvcmSp2MiFg',
      from: { type: 1, node: '15cMS5uHgiPR', index: 0 },
      to: { type: 0, node: 'ytMldOSvKWbU', index: 0 },
    },
    yPokZ8faK9X8: {
      id: 'yPokZ8faK9X8',
      from: { type: 1, node: 'ytMldOSvKWbU', index: 0 },
      to: { type: 0, node: '6OlB1nMoX5Q5', index: 0 },
    },
    CnqepZHSYYhr: {
      id: 'CnqepZHSYYhr',
      from: { type: 1, node: 'bmSJpE3CIDeu', index: 0 },
      to: { type: 0, node: 'TAe77jcawygy', index: 0 },
    },
    aRZPXXM1tbxu: {
      id: 'aRZPXXM1tbxu',
      from: { type: 1, node: '3uj0wKaeitdX', index: 0 },
      to: { type: 0, node: 'wHdKO2CieD8s', index: 1 },
    },
    cCeX6idyjEmX: {
      id: 'cCeX6idyjEmX',
      from: { type: 1, node: '5MGQAsaqrNVW', index: 0 },
      to: { type: 0, node: '3uj0wKaeitdX', index: 0 },
    },
    L92vO4Vc18tI: {
      id: 'L92vO4Vc18tI',
      from: { type: 1, node: 'wHdKO2CieD8s', index: 0 },
      to: { type: 0, node: 'KJFYyJl3uj7I', index: 0 },
    },
    '45IeHJewOBNH': {
      id: '45IeHJewOBNH',
      from: { type: 1, node: 'xXNNORpMQoRg', index: 0 },
      to: { type: 0, node: '6OlB1nMoX5Q5', index: 1 },
    },
    eQZCF5pVbiws: {
      id: 'eQZCF5pVbiws',
      from: { type: 1, node: 'xXNNORpMQoRg', index: 0 },
      to: { type: 0, node: 'ytMldOSvKWbU', index: 1 },
    },
    AZpxfILPUvah: {
      id: 'AZpxfILPUvah',
      from: { type: 1, node: 'ZzoXngovckug', index: 0 },
      to: { type: 0, node: 'Qad8q7vkHTqR', index: 0 },
    },
    PGHvkL9lJg1P: {
      id: 'PGHvkL9lJg1P',
      from: { type: 1, node: 'Qad8q7vkHTqR', index: 0 },
      to: { type: 0, node: 'ikKWqA0cGN4u', index: 0 },
    },
    ogDnuKkojTZC: {
      id: 'ogDnuKkojTZC',
      from: { type: 1, node: '5MGQAsaqrNVW', index: 0 },
      to: { type: 0, node: 'xXNNORpMQoRg', index: 0 },
    },
    gDDV1LOcKZ9H: {
      id: 'gDDV1LOcKZ9H',
      from: { type: 1, node: 'TAe77jcawygy', index: 0 },
      to: { type: 0, node: 'ZPNf5KX5eA79', index: 0 },
    },
    Z0zc7NJWsnRz: {
      id: 'Z0zc7NJWsnRz',
      from: { type: 1, node: 'ZPNf5KX5eA79', index: 0 },
      to: { type: 0, node: 'wHdKO2CieD8s', index: 0 },
    },
    d51umcgWlHcC: {
      id: 'd51umcgWlHcC',
      from: { type: 1, node: '5MGQAsaqrNVW', index: 0 },
      to: { type: 0, node: 'IQyNz1wn6z8n', index: 0 },
    },
    lweB0uuOAieS: {
      id: 'lweB0uuOAieS',
      from: { type: 1, node: 'WZ9YFvYSsEm8', index: 0 },
      to: { type: 0, node: '23OidW1ayI86', index: 0 },
    },
    J0KrvThy4Ok3: {
      id: 'J0KrvThy4Ok3',
      from: { type: 1, node: '3uj0wKaeitdX', index: 0 },
      to: { type: 0, node: 'WZ9YFvYSsEm8', index: 1 },
    },
    nveXrvTam6RB: {
      id: 'nveXrvTam6RB',
      from: { type: 1, node: 'pa42JmNw65he', index: 0 },
      to: { type: 0, node: 'ATqrrIuZqWwT', index: 1 },
    },
    vBtuadWnQPxT: {
      id: 'vBtuadWnQPxT',
      from: { type: 1, node: '5MGQAsaqrNVW', index: 0 },
      to: { type: 0, node: 'pa42JmNw65he', index: 0 },
    },
    sjLFCGaJDtOu: {
      id: 'sjLFCGaJDtOu',
      from: { type: 1, node: '5MGQAsaqrNVW', index: 0 },
      to: { type: 0, node: '6jzZelhbdCDT', index: 0 },
    },
    vN0uLTKiFhMY: {
      id: 'vN0uLTKiFhMY',
      from: { type: 1, node: '6jzZelhbdCDT', index: 0 },
      to: { type: 0, node: '9DLqsZtlwgcF', index: 1 },
    },
    PanwGud2gX4h: {
      id: 'PanwGud2gX4h',
      from: { type: 1, node: '6jzZelhbdCDT', index: 0 },
      to: { type: 0, node: 'R8ffBwvZImrH', index: 1 },
    },
    ECYQ46duftXw: {
      id: 'ECYQ46duftXw',
      from: { type: 1, node: 'LLpykHYpXqh1', index: 0 },
      to: { type: 0, node: 'ZtjNgo8BCclu', index: 1 },
    },
    JON8a1g7im8F: {
      id: 'JON8a1g7im8F',
      from: { type: 1, node: 'H0jYihAkzQXn', index: 0 },
      to: { type: 0, node: 'LLpykHYpXqh1', index: 0 },
    },
    EAdVzTGfBnCP: {
      id: 'EAdVzTGfBnCP',
      from: { type: 1, node: 'ZtjNgo8BCclu', index: 0 },
      to: { type: 0, node: 'V1NpR3qQYXs5', index: 0 },
    },
    rcOxsJo8e9XX: {
      id: 'rcOxsJo8e9XX',
      from: { type: 1, node: 'N1JW2JOi5ol3', index: 0 },
      to: { type: 0, node: 'FR33j83dcLFX', index: 0 },
    },
    beNigLIvlSG7: {
      id: 'beNigLIvlSG7',
      from: { type: 1, node: 'FR33j83dcLFX', index: 0 },
      to: { type: 0, node: 'H46Gr3mCHWrd', index: 0 },
    },
    tBRpDmOeeYd5: {
      id: 'tBRpDmOeeYd5',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'wKxDK97kD3Jj', index: 0 },
    },
    tv7OdMxFpr6c: {
      id: 'tv7OdMxFpr6c',
      from: { type: 1, node: 'wKxDK97kD3Jj', index: 0 },
      to: { type: 0, node: 'R9fQNNBNEB7H', index: 0 },
    },
    n7aiJ7zCxZPs: {
      id: 'n7aiJ7zCxZPs',
      from: { type: 1, node: 'R9fQNNBNEB7H', index: 0 },
      to: { type: 0, node: 'RJ4SzHG87fQN', index: 1 },
    },
    vLQIxL7XOuJH: {
      id: 'vLQIxL7XOuJH',
      from: { type: 1, node: 'wKxDK97kD3Jj', index: 0 },
      to: { type: 0, node: 'I73ovj1iCP8q', index: 0 },
    },
    MgXpouVG0eKr: {
      id: 'MgXpouVG0eKr',
      from: { type: 1, node: 'HMKjVapLhUTL', index: 0 },
      to: { type: 0, node: 'fBcRvHVcXlZa', index: 0 },
    },
    hV8mT9kx8TIp: {
      id: 'hV8mT9kx8TIp',
      from: { type: 1, node: 'wKxDK97kD3Jj', index: 0 },
      to: { type: 0, node: 'drmgGPLnnwua', index: 0 },
    },
    B4K9Z7XOOI7U: {
      id: 'B4K9Z7XOOI7U',
      from: { type: 1, node: 'drmgGPLnnwua', index: 0 },
      to: { type: 0, node: 'DKxCJ47HlhDN', index: 1 },
    },
    tj3xzdkDf9R4: {
      id: 'tj3xzdkDf9R4',
      from: { type: 1, node: 'fBcRvHVcXlZa', index: 0 },
      to: { type: 0, node: 'DKxCJ47HlhDN', index: 0 },
    },
    ooNAVFataA1R: {
      id: 'ooNAVFataA1R',
      from: { type: 1, node: 'wKxDK97kD3Jj', index: 0 },
      to: { type: 0, node: '5395MtgDUh22', index: 0 },
    },
    BDvS6tSACL8R: {
      id: 'BDvS6tSACL8R',
      from: { type: 1, node: 'bWqdY1CT8hTN', index: 0 },
      to: { type: 0, node: 'C8q29UOSlbr8', index: 0 },
    },
    fxLl1SrDKjWH: {
      id: 'fxLl1SrDKjWH',
      from: { type: 1, node: 'TCKT2IPJi6k2', index: 0 },
      to: { type: 0, node: 'drmgGPLnnwua', index: 2 },
    },
    UqgTuk01QVVQ: {
      id: 'UqgTuk01QVVQ',
      from: { type: 1, node: 'wKxDK97kD3Jj', index: 0 },
      to: { type: 0, node: 'TCKT2IPJi6k2', index: 0 },
    },
    u3gZRMJhxN43: {
      id: 'u3gZRMJhxN43',
      from: { type: 1, node: 'wKxDK97kD3Jj', index: 0 },
      to: { type: 0, node: 'k2DQzyEx4j6T', index: 0 },
    },
    gLdcmvaEa3L2: {
      id: 'gLdcmvaEa3L2',
      from: { type: 1, node: '4pF1Xj5mUjBO', index: 0 },
      to: { type: 0, node: 'RWgYtdyddfxw', index: 0 },
    },
    m8PfClyQqZLc: {
      id: 'm8PfClyQqZLc',
      from: { type: 1, node: 'RWgYtdyddfxw', index: 0 },
      to: { type: 0, node: 'bWqdY1CT8hTN', index: 0 },
    },
    '5x2VyGyDjSbY': {
      id: '5x2VyGyDjSbY',
      from: { type: 1, node: 'k2DQzyEx4j6T', index: 0 },
      to: { type: 0, node: 'RWgYtdyddfxw', index: 1 },
    },
    zv9AI0ODjdXp: {
      id: 'zv9AI0ODjdXp',
      from: { type: 1, node: 'wKxDK97kD3Jj', index: 0 },
      to: { type: 0, node: 'ghnTsjapd5rs', index: 0 },
    },
    OEnQ1GJXi6QQ: {
      id: 'OEnQ1GJXi6QQ',
      from: { type: 1, node: 'ghnTsjapd5rs', index: 0 },
      to: { type: 0, node: 'k2DQzyEx4j6T', index: 0 },
    },
    xCHRSgFQnoMn: {
      id: 'xCHRSgFQnoMn',
      from: { type: 1, node: 'ghnTsjapd5rs', index: 0 },
      to: { type: 0, node: '5395MtgDUh22', index: 0 },
    },
    xJmSGqibjeF8: {
      id: 'xJmSGqibjeF8',
      from: { type: 1, node: 'ghnTsjapd5rs', index: 0 },
      to: { type: 0, node: 'TCKT2IPJi6k2', index: 0 },
    },
    PaDPANHYl1Uk: {
      id: 'PaDPANHYl1Uk',
      from: { type: 1, node: 'C8q29UOSlbr8', index: 0 },
      to: { type: 0, node: 'p3rWWomwWtQi', index: 0 },
    },
    QkWdhbv6r3rM: {
      id: 'QkWdhbv6r3rM',
      from: { type: 1, node: '23OidW1ayI86', index: 0 },
      to: { type: 0, node: 'B96N9JT5jCol', index: 0 },
    },
    hOklS5XwBaPV: {
      id: 'hOklS5XwBaPV',
      from: { type: 1, node: 'Qb1hD3whtAX1', index: 0 },
      to: { type: 0, node: 'XNQdXf4xgtxA', index: 0 },
    },
    WwLxDbiwqxP6: {
      id: 'WwLxDbiwqxP6',
      from: { type: 1, node: 'H0jYihAkzQXn', index: 0 },
      to: { type: 0, node: 'Qb1hD3whtAX1', index: 0 },
    },
    Bbob2K5rDnke: {
      id: 'Bbob2K5rDnke',
      from: { type: 1, node: 'H0jYihAkzQXn', index: 0 },
      to: { type: 0, node: 'wOZ3iivjoOn7', index: 0 },
    },
    mz1hap34TLxR: {
      id: 'mz1hap34TLxR',
      from: { type: 1, node: 'wOZ3iivjoOn7', index: 0 },
      to: { type: 0, node: 'AAz0agNfiCTY', index: 0 },
    },
    '7Nnjuls7yKmX': {
      id: '7Nnjuls7yKmX',
      from: { type: 1, node: 'ZtjNgo8BCclu', index: 0 },
      to: { type: 0, node: '5xdexpdlEUCH', index: 0 },
    },
    gbzAP8K8aiyA: {
      id: 'gbzAP8K8aiyA',
      from: { type: 1, node: 'ZtjNgo8BCclu', index: 0 },
      to: { type: 0, node: '5xdexpdlEUCH', index: 1 },
    },
    Crgeywn8IQGL: {
      id: 'Crgeywn8IQGL',
      from: { type: 1, node: '5xdexpdlEUCH', index: 0 },
      to: { type: 0, node: 'FZ4Frgp6EdOT', index: 0 },
    },
    ghaspKwV6dLd: {
      id: 'ghaspKwV6dLd',
      from: { type: 1, node: 'H46Gr3mCHWrd', index: 0 },
      to: { type: 0, node: 'EsOVeDZrSxHE', index: 0 },
    },
    R7pD8Pfoh5lo: {
      id: 'R7pD8Pfoh5lo',
      from: { type: 1, node: 'CSNoT8X2aR6i', index: 0 },
      to: { type: 0, node: 'sRlmkFaoNhGR', index: 0 },
    },
    IyJnylSo8Od5: {
      id: 'IyJnylSo8Od5',
      from: { type: 1, node: 'sRlmkFaoNhGR', index: 0 },
      to: { type: 0, node: 'H7eQEOWeV6HL', index: 0 },
    },
    lXuHKFvlpa94: {
      id: 'lXuHKFvlpa94',
      from: { type: 1, node: 'AAz0agNfiCTY', index: 0 },
      to: { type: 0, node: 'k6GEzDrJKVpO', index: 0 },
    },
    AMLF52gEWgnZ: {
      id: 'AMLF52gEWgnZ',
      from: { type: 1, node: 'k6GEzDrJKVpO', index: 0 },
      to: { type: 0, node: 'ZtjNgo8BCclu', index: 0 },
    },
    iJ5JuF7cz69s: {
      id: 'iJ5JuF7cz69s',
      from: { type: 1, node: 'eMF4Bv7bakuR', index: 0 },
      to: { type: 0, node: 'FR33j83dcLFX', index: 0 },
    },
    iOIghxrCo0M5: {
      id: 'iOIghxrCo0M5',
      from: { type: 1, node: 'XNQdXf4xgtxA', index: 0 },
      to: { type: 0, node: 'mjCZ7In37Xa3', index: 0 },
    },
    b0zp57KxHFAw: {
      id: 'b0zp57KxHFAw',
      from: { type: 1, node: 'mjCZ7In37Xa3', index: 0 },
      to: { type: 0, node: 'ZtjNgo8BCclu', index: 0 },
    },
    YwtXVNqPwoWD: {
      id: 'YwtXVNqPwoWD',
      from: { type: 1, node: '8Y8WRj8h9G1O', index: 0 },
      to: { type: 0, node: 'pq3VBFivKLNu', index: 0 },
    },
    nFQPh3daAWnU: {
      id: 'nFQPh3daAWnU',
      from: { type: 1, node: 'pq3VBFivKLNu', index: 0 },
      to: { type: 0, node: 'yhsAJpGSGgH5', index: 1 },
    },
    nUley3ZbyVjM: {
      id: 'nUley3ZbyVjM',
      from: { type: 1, node: 'JBp2VjgGinuU', index: 0 },
      to: { type: 0, node: 'yhsAJpGSGgH5', index: 0 },
    },
    lSEOM5NqEchG: {
      id: 'lSEOM5NqEchG',
      from: { type: 1, node: 'Aqh9dSX2F1dE', index: 0 },
      to: { type: 0, node: '0wv3LbOYfx2r', index: 0 },
    },
    vADveHdTLQFU: {
      id: 'vADveHdTLQFU',
      from: { type: 1, node: 'EsOVeDZrSxHE', index: 0 },
      to: { type: 0, node: 'INcru5tDlJYR', index: 0 },
    },
    lzcoXM1O0jr6: {
      id: 'lzcoXM1O0jr6',
      from: { type: 1, node: '66HRMlChBbT1', index: 0 },
      to: { type: 0, node: 'INcru5tDlJYR', index: 0 },
    },
    GpoEwukgbFjO: {
      id: 'GpoEwukgbFjO',
      from: { type: 1, node: 'nkB2gAX8vNlY', index: 0 },
      to: { type: 0, node: 'INcru5tDlJYR', index: 0 },
    },
    FaRrAxBvJB0V: {
      id: 'FaRrAxBvJB0V',
      from: { type: 1, node: 'Y8ldcF0pWe98', index: 0 },
      to: { type: 0, node: 'INcru5tDlJYR', index: 0 },
    },
    Ifi6DqChCtAl: {
      id: 'Ifi6DqChCtAl',
      from: { type: 1, node: 'pKLMx9fGoV63', index: 0 },
      to: { type: 0, node: 'INcru5tDlJYR', index: 0 },
    },
    '5Qoy7ZdcKwH9': {
      id: '5Qoy7ZdcKwH9',
      from: { type: 1, node: 'p3rWWomwWtQi', index: 0 },
      to: { type: 0, node: 'INcru5tDlJYR', index: 0 },
    },
    cIWxZZizXtbW: {
      id: 'cIWxZZizXtbW',
      from: { type: 1, node: '53IffwXWFmT8', index: 0 },
      to: { type: 0, node: 'INcru5tDlJYR', index: 0 },
    },
    fXLwssyAkyFv: {
      id: 'fXLwssyAkyFv',
      from: { type: 1, node: '0wv3LbOYfx2r', index: 0 },
      to: { type: 0, node: 'INcru5tDlJYR', index: 0 },
    },
    GgjFO4DFNlFE: {
      id: 'GgjFO4DFNlFE',
      from: { type: 1, node: 'zvuEc5iutrnF', index: 0 },
      to: { type: 0, node: 'fT5uC0tXaJ5A', index: 0 },
    },
    uISbDHglnX8a: {
      id: 'uISbDHglnX8a',
      from: { type: 1, node: 'iM7yOShr9ZCw', index: 0 },
      to: { type: 0, node: 'fT5uC0tXaJ5A', index: 1 },
    },
    AOyQrD9lDMgk: {
      id: 'AOyQrD9lDMgk',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'mqYk7izHF7WN', index: 0 },
    },
    '99gKH7JfHeGo': {
      id: '99gKH7JfHeGo',
      from: { type: 1, node: 'wKxDK97kD3Jj', index: 0 },
      to: { type: 0, node: 'iM7yOShr9ZCw', index: 0 },
    },
    iXXgZsJ3qtrP: {
      id: 'iXXgZsJ3qtrP',
      from: { type: 1, node: 'yhsAJpGSGgH5', index: 0 },
      to: { type: 0, node: 'm4oHDrr8hlIT', index: 0 },
    },
    PkfQm0bNhqBq: {
      id: 'PkfQm0bNhqBq',
      from: { type: 1, node: 'm4oHDrr8hlIT', index: 0 },
      to: { type: 0, node: 'Df2KuJ6fLT79', index: 0 },
    },
    QTI6o6xzjxlX: {
      id: 'QTI6o6xzjxlX',
      from: { type: 1, node: 'Df2KuJ6fLT79', index: 0 },
      to: { type: 0, node: 'lkrgkTOvvhT9', index: 0 },
    },
    O9jpliC3YV4i: {
      id: 'O9jpliC3YV4i',
      from: { type: 1, node: 'lkrgkTOvvhT9', index: 0 },
      to: { type: 0, node: 'bpMZcX4x0mhM', index: 0 },
    },
    VxYPV6SzeJKs: {
      id: 'VxYPV6SzeJKs',
      from: { type: 1, node: 'bpMZcX4x0mhM', index: 0 },
      to: { type: 0, node: 'Aqh9dSX2F1dE', index: 0 },
    },
    f5ljmbhlqEfc: {
      id: 'f5ljmbhlqEfc',
      from: { type: 1, node: 'tcfc5Ivp4XV1', index: 0 },
      to: { type: 0, node: 'lkrgkTOvvhT9', index: 1 },
    },
    '7wBDDrsrhGZ9': {
      id: '7wBDDrsrhGZ9',
      from: { type: 1, node: 'nWuB1SGwLaUB', index: 0 },
      to: { type: 0, node: 'itgdSvP1SHnn', index: 0 },
    },
    Qpe4S4hpnfK9: {
      id: 'Qpe4S4hpnfK9',
      from: { type: 1, node: 'itgdSvP1SHnn', index: 0 },
      to: { type: 0, node: 'INcru5tDlJYR', index: 0 },
    },
    s5fDEssxOdEI: {
      id: 's5fDEssxOdEI',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'nCZ94FqAKehN', index: 0 },
    },
    Q0SHxXRXSkBg: {
      id: 'Q0SHxXRXSkBg',
      from: { type: 1, node: 'nCZ94FqAKehN', index: 0 },
      to: { type: 0, node: 'zFF3K2L2fqEr', index: 0 },
    },
    LPipnCLYj86K: {
      id: 'LPipnCLYj86K',
      from: { type: 1, node: '0fXNjsyoDPK3', index: 0 },
      to: { type: 0, node: 'WqroUPkUDn0E', index: 0 },
    },
    QIYNRavJncTy: {
      id: 'QIYNRavJncTy',
      from: { type: 1, node: 'WqroUPkUDn0E', index: 0 },
      to: { type: 0, node: 'NhcXke6qnh6D', index: 1 },
    },
    aaNXrGdAerWK: {
      id: 'aaNXrGdAerWK',
      from: { type: 1, node: 'NhcXke6qnh6D', index: 0 },
      to: { type: 0, node: 'UWlKrOcmvVXO', index: 0 },
    },
    FwzsXeUHtdVj: {
      id: 'FwzsXeUHtdVj',
      from: { type: 1, node: 'UWlKrOcmvVXO', index: 0 },
      to: { type: 0, node: 'nWuB1SGwLaUB', index: 0 },
    },
    JfUzt6IwamKb: {
      id: 'JfUzt6IwamKb',
      from: { type: 1, node: 'zFF3K2L2fqEr', index: 0 },
      to: { type: 0, node: 'jdRJWEnronKf', index: 0 },
    },
    '5yN7dHexPzcE': {
      id: '5yN7dHexPzcE',
      from: { type: 1, node: 'jdRJWEnronKf', index: 0 },
      to: { type: 0, node: 'NhcXke6qnh6D', index: 0 },
    },
    DCMr0GrYN66Y: {
      id: 'DCMr0GrYN66Y',
      from: { type: 1, node: 'tcfc5Ivp4XV1', index: 0 },
      to: { type: 0, node: 'jdRJWEnronKf', index: 1 },
    },
    zjPG4IypwCyh: {
      id: 'zjPG4IypwCyh',
      from: { type: 1, node: 'mqYk7izHF7WN', index: 0 },
      to: { type: 0, node: 'tcfc5Ivp4XV1', index: 0 },
    },
    r6KfY7YH88br: {
      id: 'r6KfY7YH88br',
      from: { type: 1, node: 'iINmH5evXULx', index: 0 },
      to: { type: 0, node: 'Aqh9dSX2F1dE', index: 1 },
    },
    lSSh6j0teWLR: {
      id: 'lSSh6j0teWLR',
      from: { type: 1, node: 'HvdxavuB4LZv', index: 0 },
      to: { type: 0, node: 'zFF3K2L2fqEr', index: 1 },
    },
    D9Fc4NZnoNfj: {
      id: 'D9Fc4NZnoNfj',
      from: { type: 1, node: '1Z3vnZtvuLad', index: 0 },
      to: { type: 0, node: 'X92WUnRbWU3R', index: 1 },
    },
    VLjkMLcJkf21: {
      id: 'VLjkMLcJkf21',
      from: { type: 1, node: 'GiY1N5NltkrZ', index: 0 },
      to: { type: 0, node: '1baCRqg3oxtz', index: 1 },
    },
    tqtDWrZRgbbv: {
      id: 'tqtDWrZRgbbv',
      from: { type: 1, node: '5395MtgDUh22', index: 0 },
      to: { type: 0, node: 'RJ4SzHG87fQN', index: 1 },
    },
    qM4saQobnHkl: {
      id: 'qM4saQobnHkl',
      from: { type: 1, node: 'zFF3K2L2fqEr', index: 0 },
      to: { type: 0, node: 'rMuGAMzb30XF', index: 0 },
    },
    GUqKVuGN2GLp: {
      id: 'GUqKVuGN2GLp',
      from: { type: 1, node: 'rMuGAMzb30XF', index: 0 },
      to: { type: 0, node: '14oKthuxYsBu', index: 0 },
    },
    f8EBW2N2q0R7: {
      id: 'f8EBW2N2q0R7',
      from: { type: 1, node: '14oKthuxYsBu', index: 0 },
      to: { type: 0, node: 'DRd78zKP3uTg', index: 0 },
    },
    '1Rkewn3X9z6F': {
      id: '1Rkewn3X9z6F',
      from: { type: 1, node: 'qEP4895CpmlE', index: 0 },
      to: { type: 0, node: '14oKthuxYsBu', index: 1 },
    },
    '6PqtdMPZo6H2': {
      id: '6PqtdMPZo6H2',
      from: { type: 1, node: 'Ddc8EOj4wigF', index: 0 },
      to: { type: 0, node: 'INcru5tDlJYR', index: 0 },
    },
    vdEiKwK5i2qy: {
      id: 'vdEiKwK5i2qy',
      from: { type: 1, node: 'mIx80nAKKbNf', index: 0 },
      to: { type: 0, node: 'Ddc8EOj4wigF', index: 0 },
    },
    lZ6wnvuRKDsQ: {
      id: 'lZ6wnvuRKDsQ',
      from: { type: 1, node: '0kUkD36PjCa9', index: 0 },
      to: { type: 0, node: 'qEP4895CpmlE', index: 0 },
    },
    x1zCLLw6xEim: {
      id: 'x1zCLLw6xEim',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: '0kUkD36PjCa9', index: 0 },
    },
    irrRkSqmrThN: {
      id: 'irrRkSqmrThN',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: '0fXNjsyoDPK3', index: 0 },
    },
    HJHJSEuEg1iK: {
      id: 'HJHJSEuEg1iK',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: '8Y8WRj8h9G1O', index: 0 },
    },
    V0LPcWoAh0vz: {
      id: 'V0LPcWoAh0vz',
      from: { type: 1, node: 'FeRCVX0Frp7j', index: 0 },
      to: { type: 0, node: '5W7jLwgwIkvC', index: 0 },
    },
    '5k3kdeko31L5': {
      id: '5k3kdeko31L5',
      from: { type: 1, node: 'Q9lSJCnKkhVw', index: 0 },
      to: { type: 0, node: 'XZ4aMLtRgb3S', index: 0 },
    },
    dGQG4ehRCMU5: {
      id: 'dGQG4ehRCMU5',
      from: { type: 1, node: 'JnRqDvagyW7p', index: 0 },
      to: { type: 0, node: 'JfGI7qB3Pt1C', index: 0 },
    },
    hXmPAS5zpEEe: {
      id: 'hXmPAS5zpEEe',
      from: { type: 1, node: 'j17sUIM19m6z', index: 0 },
      to: { type: 0, node: '3xUWR0KOdPTM', index: 0 },
    },
    r3YUifyPRyQW: {
      id: 'r3YUifyPRyQW',
      from: { type: 1, node: 'ygLx4JFDnFeN', index: 0 },
      to: { type: 0, node: '3xUWR0KOdPTM', index: 0 },
    },
    D5n1FEjL8rw3: {
      id: 'D5n1FEjL8rw3',
      from: { type: 1, node: 'jVtdzPQn8tRn', index: 0 },
      to: { type: 0, node: 'jiMee9SKripV', index: 0 },
    },
    Jz73ckP8YPDQ: {
      id: 'Jz73ckP8YPDQ',
      from: { type: 1, node: 'E4MemSm2PdKF', index: 0 },
      to: { type: 0, node: '5fSOiZX7mzit', index: 0 },
    },
    C2W3ncF5m3Nw: {
      id: 'C2W3ncF5m3Nw',
      from: { type: 1, node: 'E4MemSm2PdKF', index: 0 },
      to: { type: 0, node: 'jiMee9SKripV', index: 0 },
    },
    Kq0oXUABHrpa: {
      id: 'Kq0oXUABHrpa',
      from: { type: 1, node: 'B96N9JT5jCol', index: 0 },
      to: { type: 0, node: '66HRMlChBbT1', index: 0 },
    },
    kUbzNbJqhDxI: {
      id: 'kUbzNbJqhDxI',
      from: { type: 1, node: 'KJFYyJl3uj7I', index: 0 },
      to: { type: 0, node: 'WZ9YFvYSsEm8', index: 0 },
    },
    uKejetTXxcwa: {
      id: 'uKejetTXxcwa',
      from: { type: 1, node: 'BHoxWl2yq5Dg', index: 0 },
      to: { type: 0, node: 'pKLMx9fGoV63', index: 0 },
    },
    eFHnvDTaMIuv: {
      id: 'eFHnvDTaMIuv',
      from: { type: 1, node: 'fR2oYvZc6hUX', index: 0 },
      to: { type: 0, node: 'XcsBDsAxnNKC', index: 1 },
    },
    OPTSdkN2ascD: {
      id: 'OPTSdkN2ascD',
      from: { type: 1, node: 'ZVcQJRbGCQw3', index: 0 },
      to: { type: 0, node: 'BHoxWl2yq5Dg', index: 0 },
    },
    UzWKpFA4CSBC: {
      id: 'UzWKpFA4CSBC',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'fR2oYvZc6hUX', index: 0 },
    },
    a7fYqFyXnsgJ: {
      id: 'a7fYqFyXnsgJ',
      from: { type: 1, node: '7ArmcOQPLc9h', index: 0 },
      to: { type: 0, node: 'TAe77jcawygy', index: 1 },
    },
    RCnb3YDz9iQ5: {
      id: 'RCnb3YDz9iQ5',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: '7ArmcOQPLc9h', index: 0 },
    },
    n0TSodGYXOp4: {
      id: 'n0TSodGYXOp4',
      from: { type: 1, node: 'DRd78zKP3uTg', index: 0 },
      to: { type: 0, node: 'mIx80nAKKbNf', index: 0 },
    },
    Uav2lCbxKBXD: {
      id: 'Uav2lCbxKBXD',
      from: { type: 1, node: 'DKxCJ47HlhDN', index: 0 },
      to: { type: 0, node: '4pF1Xj5mUjBO', index: 0 },
    },
    UFLjxhObczj3: {
      id: 'UFLjxhObczj3',
      from: { type: 1, node: 'DKxCJ47HlhDN', index: 0 },
      to: { type: 0, node: '4pF1Xj5mUjBO', index: 1 },
    },
    kUopO5jEZZD9: {
      id: 'kUopO5jEZZD9',
      from: { type: 1, node: 'RJ4SzHG87fQN', index: 0 },
      to: { type: 0, node: 'HMKjVapLhUTL', index: 0 },
    },
    FnMQdVz8A9te: {
      id: 'FnMQdVz8A9te',
      from: { type: 1, node: 'I73ovj1iCP8q', index: 0 },
      to: { type: 0, node: 'HMKjVapLhUTL', index: 1 },
    },
    '8xB9oTxIij2A': {
      id: '8xB9oTxIij2A',
      from: { type: 1, node: 'INcru5tDlJYR', index: 0 },
      to: { type: 0, node: 'oeYy75wASWYe', index: 0 },
    },
    nsw6Xe9LKjtm: {
      id: 'nsw6Xe9LKjtm',
      from: { type: 1, node: 'fT5uC0tXaJ5A', index: 0 },
      to: { type: 0, node: 'C8q29UOSlbr8', index: 0 },
    },
    '5sMmsqLPxsRE': {
      id: '5sMmsqLPxsRE',
      from: { type: 1, node: 'zvuEc5iutrnF', index: 0 },
      to: { type: 0, node: 'Hd1uTyZJu3nT', index: 0 },
    },
    '2HbMNhIVxUc8': {
      id: '2HbMNhIVxUc8',
      from: { type: 1, node: 'bWqdY1CT8hTN', index: 0 },
      to: { type: 0, node: 'Hd1uTyZJu3nT', index: 0 },
    },
    aeOJkne8WRro: {
      id: 'aeOJkne8WRro',
      from: { type: 1, node: 'Hd1uTyZJu3nT', index: 0 },
      to: { type: 0, node: 'zvuEc5iutrnF', index: 0 },
    },
    '8OG0qeHXj7cR': {
      id: '8OG0qeHXj7cR',
      from: { type: 1, node: 'wG9AgV2ZNGtI', index: 0 },
      to: { type: 0, node: '1Z3vnZtvuLad', index: 1 },
    },
    E1UBSDN0wOAl: {
      id: 'E1UBSDN0wOAl',
      from: { type: 1, node: 'wG9AgV2ZNGtI', index: 0 },
      to: { type: 0, node: 'GiY1N5NltkrZ', index: 1 },
    },
    vIBTCQ90qfdH: {
      id: 'vIBTCQ90qfdH',
      from: { type: 1, node: 'YDKALgbSHv1n', index: 0 },
      to: { type: 0, node: 'wG9AgV2ZNGtI', index: 0 },
    },
  },
};

export const baseGraph = graphFromStorage(baseGraphForStorage);
