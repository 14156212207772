import { Graph } from '../types';
import { createRenderer } from './createRenderer';
import { createCityScene } from './createCityScene';

export class ImageRenderer {
  renderer = createRenderer();
  queue: [Graph, number, (s: string) => void][] = [];

  render(graph: Graph, size: number = 558): Promise<string> {
    return new Promise(resolve => {
      this.queue.push([graph, size, resolve]);
      if (this.queue.length === 1) setTimeout(() => this._render(), 0);
    });
  }

  _render(): void {
    const frame = this.queue.shift();
    if (frame) {
      const [graph, size, resolve] = frame;
      const s = createCityScene(graph);
      this.renderer.setSize(size, size);
      this.renderer.render(s.scene, s.camera);
      s.dispose();
      this.renderer.domElement.toBlob(blob => {
        resolve(URL.createObjectURL(blob));
      });
      if (this.queue.length > 0) setTimeout(() => this._render(), 0);
    }
  }

  dispose(): void {
    this.renderer.dispose();
  }
}
