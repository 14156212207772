import { Group, BoxGeometry, Mesh, MeshStandardMaterial, Color } from 'three';
import { Entity } from './types';

const rand = (min: number, max: number): number =>
  min + (max - min) * Math.random();

export const buildTowers = (
  color: Color,
  dist: number,
  depth: number,
): Entity => {
  const minHeight = 6;
  const maxHeight = 90;
  const distance = 20;

  const group = new Group();
  group.position.set(0, -distance - maxHeight, dist + depth);

  const material = new MeshStandardMaterial({
    color,
    dithering: true,
  });

  const xSpacing = 6;
  const zSpacing = 4.5;
  for (let x = -depth / 8; x < depth / 8; x += xSpacing) {
    for (let z = -depth; z < depth; z += zSpacing) {
      const y =
        minHeight +
        Math.random() *
          (maxHeight - minHeight) *
          (1 + (Math.abs(x) / depth) * 2);
      const geometry = new BoxGeometry(3, y, 3);
      const mesh = new Mesh(geometry, material);
      mesh.receiveShadow = true;
      mesh.castShadow = true;
      mesh.position.set(x + rand(-0.3, 0.3), y / 2, z + rand(-0.3, 0.3));
      group.add(mesh);
    }
  }

  return {
    group,
    children: {},
    dispose() {},
    animations: [],
  };
};
