import styled from 'styled-components';

export type GridLayoutProps = {
  space?: number;
  minSize?: number;
};

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ minSize = 15 }: GridLayoutProps) => minSize}em, 1fr)
  );
  gap: ${({ space = 1.5 }: GridLayoutProps) => space}em;
`;
