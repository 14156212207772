import { hsluvToHex } from 'hsluv-ts';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AudioGraph } from '../../audioGraph';
import { RhythmNode, SequenceNode } from '../../audioNodes';
import {
  AudioNodeType,
  RhythmNodeConfig,
  SequenceNodeConfig,
} from '../../types';

const cellSize = 4;
const rowSize = 32;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${cellSize / 2}px;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const Circle = styled.circle`
  fill: ${({ on }: { on: boolean }) => (on ? '#000' : '#206')};
`;

type PatternProps = {
  audioGraph: AudioGraph;
  nodeID: string;
  playing: boolean;
  baseHue: number;
};

const Pattern: FC<PatternProps> = ({
  audioGraph,
  nodeID,
  playing,
  baseHue,
}) => {
  const [position, setPosition] = useState(0);

  const c1 = hsluvToHex([baseHue, 80, 4]);
  const c2 = hsluvToHex([baseHue, 100, 12]);

  useEffect(() => {
    const node = audioGraph.audioNodes[nodeID] as RhythmNode | SequenceNode;
    const listener = () => setPosition(node.position);
    node.addEventListener('trigger', listener);
    return () => node.removeEventListener('trigger', listener);
  }, [audioGraph, nodeID]);

  const node = audioGraph.graph.nodes[nodeID].audioNode as
    | RhythmNodeConfig
    | SequenceNodeConfig;
  const values = node.params.sequence;

  return (
    <svg
      viewBox={`0 0 ${values.length % rowSize} ${Math.ceil(
        values.length / rowSize,
      )}`}
      height={Math.ceil(values.length / rowSize) * cellSize}
      width={Math.min(rowSize, values.length) * cellSize}
      style={{ background: c1 }}
    >
      {playing && (
        <rect
          x={position % rowSize}
          y={Math.floor(position / rowSize)}
          width={1}
          height={1}
          fill={c2}
        />
      )}
    </svg>
  );
};

export type PositionsProps = {
  audioGraph: AudioGraph;
  playing: boolean;
  baseHue: number;
};

export const Positions: FC<PositionsProps> = ({
  audioGraph,
  playing,
  baseHue,
}) => {
  const nodes = Object.values(audioGraph.graph.nodes).filter(
    n =>
      (n.audioNode.type === AudioNodeType.RhythmNode ||
        n.audioNode.type === AudioNodeType.SequenceNode) &&
      n.audioNode.params.sequence.length < 64,
  );

  return (
    <Col>
      {nodes.map(n => (
        <Pattern
          key={n.id}
          nodeID={n.id}
          audioGraph={audioGraph}
          playing={playing}
          baseHue={baseHue}
        />
      ))}
    </Col>
  );
};
