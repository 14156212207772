export class PitchShifterNode extends AudioWorkletNode {
  readonly detune: AudioParam;

  constructor(context: BaseAudioContext, { detune = 0 }: { detune?: number }) {
    super(context, 'PitchShifterProcessor', {
      numberOfInputs: 1,
      numberOfOutputs: 1,
      outputChannelCount: [2],
      channelCount: 2,
      channelCountMode: 'explicit',
      channelInterpretation: 'discrete',
      parameterData: { detune },
    });

    // @ts-ignore
    this.detune = this.parameters.get('detune');
  }
}
